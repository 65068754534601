import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import VipCheckbox, { StatusType } from './VipCheckbox';
import { Control, UseFormSetValue } from 'react-hook-form';
import { IHookFormOrder } from '../../domain/entities/IHokFormOrder';
import VipInfo from './VipInfo';
import DefaultController from '../../../../common/presentation/DefaultController';
import { VipRoomBookingsDto } from '../../../../api/models/VipRoomBookings';
import moment from 'moment';
import { container } from '../../../../di/container';
import { OrderService } from '../../domain/services/OrderService';

interface VipStatus {
  num: number;
  status: StatusType;
}

type TimeType = Record<number, TimeRange>;

const initialStatus: VipStatus[] = [
  { num: 1, status: 'unchecked' },
  { num: 2, status: 'unchecked' },
  { num: 3, status: 'unchecked' }
];

const initialTimeState: TimeType = {
  1: { timeFrom: null, timeTo: null },
  2: { timeFrom: null, timeTo: null },
  3: { timeFrom: null, timeTo: null }
};

interface TimeRange {
  timeFrom: string | null;
  timeTo: string | null;
}

interface VipGroupProps {
  setValue: UseFormSetValue<IHookFormOrder>,
  control: Control<IHookFormOrder>,
  availableRooms: { time: string; rooms: number[] }[]
}

const VipGroup: React.FC<VipGroupProps> = ({ control, setValue, availableRooms }) => {
  const [statuses, setStatuses] = useState<VipStatus[]>(initialStatus);
  const [activeCheckboxes, setActiveCheckboxes] = useState<number[]>([]);
  const [times, setTimes] = useState<TimeType>(initialTimeState);


  const orderService = useMemo(() => container.get(OrderService), []);
  const initialTime = orderService.editableOrder?.vipRoomBookings.map(vip => {
    return ({
      room: vip.vipRoomId!,
      start: moment(vip.startTime).hour(),
      end: moment(vip.endTime).hour()
    });
  });



  const [currentDate, setCurrentDate] = useState<Date | null>(null);

  useEffect(() => {
    // setStatuses(initialStatus);

    // const roomsNumber = [1, 2, 3];
    // roomsNumber.forEach(r => {
    //   if (getAvailableTimesForRoom(r)?.length == 0) {
    //     setStatuses(prev => (
    //       prev.map(st => (
    //         st.num === r ? { num: st.num, status: 'disabled' } : { num: st.num, status: 'unchecked' }
    //       ))
    //     ))
    //   }
    // })

    if (availableRooms) {
      setCurrentDate(new Date(availableRooms[0].time));
    } else {
      console.error('No available rooms provided');
    }
  }, [availableRooms]);

  const prepareVipRoomBookings = () => {
    if (!currentDate) return [];

    const vipRoomBookings = activeCheckboxes.map(num => {
      const baseDate = new Date(currentDate);
      let fromDate = null, toDate = null;

      if (times[num] && times[num].timeFrom) {
        const fromTimeDate = new Date(baseDate);
        const fromHours = +times[num].timeFrom;

        if (!isNaN(fromHours)) {
          fromTimeDate.setHours(fromHours + 4, 0, 0, 0);
          fromDate = fromTimeDate.toISOString();
        } else {
          console.error('Invalid timeFrom value for room:', num);
        }
      }

      if (times[num] && times[num].timeTo) {
        const toTimeDate = new Date(baseDate);
        const toHours = +times[num].timeTo;

        if (!isNaN(toHours)) {
          toTimeDate.setHours(toHours + 4, 0, 0, 0);

          if (toHours < 4) {
            toTimeDate.setDate(toTimeDate.getDate() - 1);
          }

          toDate = toTimeDate.toISOString();
        } else {
          console.error('Invalid timeTo value for room:', num);
        }
      }

      return {
        startTime: fromDate ? fromDate : null,
        endTime: toDate ? toDate : null,
        vipRoomId: num
      };
    });

    return vipRoomBookings;
  };

  useEffect(() => {
    const vipRoomBookings = prepareVipRoomBookings();
    // console.log(vipRoomBookings);
    setValue('vipRoomBookings', vipRoomBookings);

    // if(activeCheckboxes.length === 0) vipRoomBookings = [];
  }, [activeCheckboxes, times]);

  const handleStatusChange = (num: number, checked: boolean) => {
    setStatuses(statuses.map(status =>
      status.num === num ? { ...status, status: checked ? 'checked' : 'unchecked' } : status
    ));
    setTimes({
      ...times,
      [num]: { timeFrom: null, timeTo: null }
    });

    if (!checked) {
      setActiveCheckboxes(activeCheckboxes.filter(n => n !== num));
    } else {
      setActiveCheckboxes([...activeCheckboxes, num]);
    }
  };


  const handleTimeChange = (num: number, timeFrom: string | null, timeTo: string | null) => {
    setTimes({
      ...times,
      [num]: { timeFrom, timeTo }
    });
  };

  const getAvailableTimesForRoom = (roomNum: number) => {
    return availableRooms?.filter(({ rooms }) => rooms.includes(roomNum)).map(({ time }) => time);
  };

  const getInitialTimeForRoom = (roomNum: number) => {
    const room = initialTime?.filter(t => t.room === roomNum)[0];
    return { start: room?.start ?? -1, end: room?.end ?? -1 };
  };
  useEffect(() => {
    if (control._formValues && control._formValues.vipRoomBookings[0]?.vipRoomId) {
      const vipRoomBookings = control._formValues.vipRoomBookings as VipRoomBookingsDto[];

      const updatedStatuses = initialStatus.map(status => ({
        ...status,
        status: vipRoomBookings.some(f => f.vipRoomId === status.num) ? 'checked' as StatusType : 'unchecked' as StatusType
      }));
      let updatedActiveCheckboxes = vipRoomBookings.map(({ vipRoomId }) => vipRoomId!);


      const updatedTimes = vipRoomBookings.reduce((acc, { vipRoomId, startTime, endTime }) => {
        acc[vipRoomId!] = {
          timeFrom: startTime ? new Date(startTime).getHours().toString() : null,
          timeTo: endTime ? new Date(endTime).getHours().toString() : null
        };
        return acc;
      }, { ...times } as Record<number, TimeRange>);

      setStatuses(updatedStatuses);
      setActiveCheckboxes(updatedActiveCheckboxes);
      updatedActiveCheckboxes = [];
      setTimes(updatedTimes);
    }

    return () => {
      setStatuses(initialStatus);
      setActiveCheckboxes([]);
      setTimes(initialTimeState);
    }
  }, [control._formValues]);


  return (
    <DefaultController
      name='vipRoomBookings'
      control={control}
      render={(field) => {
        // const vipRoomBookings = field.field.value;

        // let updatedStatuses = null;
        // let updatedActiveCheckboxes = null;
        // let updatedTimes = null;

        // if (vipRoomBookings && vipRoomBookings.length > 0/* && !hasUpdatedRef.current*/) {
        //   updatedStatuses = initialStatus.map(status => ({
        //     ...status,
        //     status: vipRoomBookings.some(f => f.vipRoomId === status.num) ? 'checked' as StatusType : 'unchecked' as StatusType
        //   }));
        //   updatedActiveCheckboxes = vipRoomBookings.map(({ vipRoomId }) => vipRoomId!);

        //   updatedTimes = vipRoomBookings.reduce((acc, { vipRoomId, startTime, endTime }) => {
        //     acc[vipRoomId!] = {
        //       timeFrom: startTime ? new Date(startTime).getHours().toString() : null,
        //       timeTo: endTime ? new Date(endTime).getHours().toString() : null
        //     };
        //     return acc;
        //   }, {} as Record<number, TimeRange>);
        // console.log(updatedStatuses);
        // console.log(updatedActiveCheckboxes);
        // console.log(updatedTimes);

        // setStatuses(updatedStatuses);
        // setActiveCheckboxes(updatedActiveCheckboxes);
        // setTimes(updatedTimes);
        // hasUpdatedRef.current = true;
        // }

        // const lastStatuses = updatedStatuses ? updatedStatuses : statuses;
        // const lastActiveCheckboxes = updatedActiveCheckboxes ? updatedActiveCheckboxes : activeCheckboxes;
        // const lastTimes = updatedTimes ? updatedTimes : times;

        return (
          <Col style={{
            padding: '10px 12px',
            backgroundColor: '#F3F5F9',
            borderRadius: '15px'
          }}>
            <span style={{ color: '#8A929E', fontWeight: '500', fontSize: '16px', display: 'block', marginBottom: '10px' }}>Вип комнаты</span>
            <Row gutter={[20, 0]} wrap={false} align={'middle'} style={{ marginBottom: '10px' }}>
              {statuses.map(({ num, status }) => (
                <Col key={num}>
                  <VipCheckbox num={num} status={status} onStatusChange={handleStatusChange} />
                </Col>
              ))}
            </Row>
            {activeCheckboxes.map(num => (
              <VipInfo
                key={`vipInfo${num}`}
                availableTimes={getAvailableTimesForRoom(num)}
                num={num}
                timeFrom={times[num]?.timeFrom}
                timeTo={times[num]?.timeTo}
                onTimeChange={handleTimeChange}
                initialTime={getInitialTimeForRoom(num)}
                control={control}
                index={activeCheckboxes.findIndex(ch => ch === num)}
              />
            ))}
          </Col>
        );
      }}
    />
  );
};

export default VipGroup;
