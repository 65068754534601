import moment from "moment";
import { TaskDto, TaskRequest } from "../../../api/models/TaskDto";
import { Task, TaskProperties } from "../domain/entities/Task";


export class TaskAdapter {
    static fromDto = (dto: TaskDto): Task => new Task({ ...dto, isDone: dto.status == 'Done', time: moment(dto.date) });
    static toRequest = (task: TaskProperties): TaskRequest => ({
        title: task.title,
        comment: task.comment,
        date: task.time.format(moment.defaultFormat)
    })

    static toDto = (task: TaskProperties): TaskDto => ({
        title: task.title,
        id: task.id,
        comment: task.comment,
        date: task.time.format(moment.defaultFormat),
        status: task.isDone ? 'Done' : 'Undone'
    })
}
