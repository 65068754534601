import { injectable } from "inversify";
import { ESourceStatus, IOrder } from "../entities/IOrder";
import { BaseApiCollection } from "../../../../@types/baseApiCollection";
import { CancelablePromise, FullOrder, FullOrderRequest, Order } from "../../../../api";
import { action, computed, makeAutoObservable } from "mobx";
import { ALL_SELECT } from "../../../../@types/baseCollection";
import { OrderRemoteDataSource } from "../../../../api/services/OrderService";
import { handleRequest } from "../../../../common/utility-types/helpers/handleRequest";
import { OrderAdapter } from "../../adapters/OrderAdapter";
import { Without } from "../../../../@types/without";
import moment from "moment";
import { EOrderStatus } from "../entities/EOrderStatus";
import { orderToDto } from "../../adapters/dtoAdapter";
import { Either, IEither } from "../../../../@types/either";
import { Failure } from "../../../../@types/failure";
import { IOrderResponse } from "../entities/IOrderResponse";

export type IOrderSocket = Without<IOrder, Function>;

@injectable()
export class OrdersRepository {

    private _ordersCollection: BaseApiCollection<IOrderSocket, FullOrder> = new BaseApiCollection();

    private _ordersPromise: Promise<IOrderSocket[]> | CancelablePromise<IOrderSocket[]> | null = null;
    private _orderAdapter: OrderAdapter = new OrderAdapter();
    // private _ordersPeriod: Order[] = [];
    private _ordersPeriod: IOrderResponse[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    loadClientOrders = async () => {
        await this._ordersCollection.load(() => OrderRemoteDataSource.getClientsOrders(), this._orderAdapter.orderFromDto);
    };

    save = async (order: IOrder): Promise<IEither<Failure, IOrder>> => {
        console.log(order);

        return (await handleRequest(() => OrderRemoteDataSource.saveOrder(order.id, orderToDto(order)))).match({
            onLeft: (l) => Either.left<Failure, IOrder>(l),
            onRight: (r) => {
                const order = this._orderAdapter.syncOrderFromDto(r);
                this.setOrders([...this.orders, order]);
                return Either.right(order);
            }
        });
    };

    getSortedList(this: OrdersRepository): IOrderSocket[] {
        return this._ordersCollection.where(ALL_SELECT).filter(a => a.bookings[0].createdAt).sort((a, b) => moment(a.bookings[0].createdAt).diff(b.bookings[0].createdAt));
    }

    getOrderById = (orderId: string) => {
        return this._ordersCollection.getById(orderId);
    };

    getOrdersByDate = (date: string) => {
        return this._ordersCollection.where(v => v.bookings.find(b => b.bookingDate?.format(moment.defaultFormat) == date) != null);
    };

    @action
    setOrders = (orders: Without<IOrder, Function>[]) => {
        this._ordersCollection.setMany(orders);
    };

    @computed
    get ordersByClient() {
        return this._ordersCollection.where(order => order.source == ESourceStatus.CLIENT && order.status != EOrderStatus.CONFIRMED);
    }

    @computed
    get orders() {
        return Array.from(this._ordersCollection.storage.values());
    }

    get ordersPeriod() {
        return this._ordersPeriod;
    }

    // getById(this: OrdersRepository, id: string): IOrderSocket | null {
    //     return this._ordersCollection.getById(id);
    // }
    // where(this: OrdersRepository, predicate: (v: IOrderSocket) => boolean): IOrderSocket[] {
    //     return this._ordersCollection.where(predicate);
    // }
    // setMany(this: OrdersRepository, values: IOrderSocket[]): void {
    //     this._ordersCollection.setMany(values);
    // }
    // addMany(this: OrdersRepository, values: IOrderSocket[]): void {
    //     this._ordersCollection.addMany(values);
    // }
    // deleteMany(this: OrdersRepository, values: Pick<IOrderSocket, "id">[]): void {
    //     this._ordersCollection.deleteMany(values);
    // }

    async getOrdersByPeriod(from: string, to: string) {
        await OrderRemoteDataSource.getApiV3OrdersGetOrders(from, to).then(res => {
            // res.forEach(orderRes => {
            //     if (!this._ordersPeriod.find(order => order.id === orderRes.id)) {
            //         this._ordersPeriod = [...this._ordersPeriod, orderRes];
            //     }
            // });

            let updatedOrders = [...this._ordersPeriod];

            res.forEach(orderRes => {
                const existingOrderIndex = updatedOrders.findIndex(order => order.id === orderRes.id);

                if (existingOrderIndex !== -1) {
                    updatedOrders[existingOrderIndex] = orderRes;
                } else {
                    updatedOrders.push(orderRes);
                }
            });

            this._ordersPeriod = updatedOrders;
        });
    }
}