import { injectable } from "inversify";
import { BaseApiCollection } from "../../../@types/baseApiCollection";
import { Either, IEither } from "../../../@types/either";
import { Failure } from "../../../@types/failure";
import { Task, TaskProperties } from "../domain/entities/Task";
import { ITaskRepository } from "./ITaskRepository";
import moment from "moment";
import { handleRequest } from "../../../common/utility-types/helpers/handleRequest";
import { TaskService } from "../../../api/services/TaskService";
import { TaskDto } from "../../../api/models/TaskDto";
import { action, computed, makeObservable } from "mobx";
import { ALL_SELECT } from "../../../@types/baseCollection";
import { TaskAdapter } from "../adapters/TaskAdapter";
import { DATE_FORMAT } from "../../../common/constants";

@injectable()
export class TaskRepository implements ITaskRepository {

    private _taskCollection: BaseApiCollection<Task, TaskDto> = new BaseApiCollection();

    constructor() {
        makeObservable(this);
    }

    @computed
    get tasks(): Task[] {
        return this._taskCollection.where(ALL_SELECT);
    }

    @action
    getTaskByDate = async (date: moment.Moment): Promise<IEither<Failure, Task[]>> => {
        const dateTasks = this._taskCollection.where(t => t.time.isSame(date, 'date'));
        if (dateTasks.length != 0) return Either.right<Failure, Task[]>(dateTasks);
        this._taskCollection.load(
            async () => await TaskService.getTasksByDate(date.format(DATE_FORMAT)),
            async (dto) => TaskAdapter.fromDto(dto)
        )
        if (this._taskCollection.error) return Either.left<Failure, Task[]>({ message: this._taskCollection.error });
        return Either.right<Failure, Task[]>(this._taskCollection.where(t => t.time.isSame(date, 'date')));
    }


    @action
    create = async (task: Omit<TaskProperties, "id" | "isDone">): Promise<IEither<Failure, Task>> => {
        return (await handleRequest(
            () => TaskService
                .createTask({
                    ...task,
                    date: task.time.format(moment.defaultFormat)
                })))
            .map(taskDto => {
                const task = TaskAdapter.fromDto(taskDto);
                this._taskCollection.addMany([task]);
                return task;
            });
    }

    @action
    update = async (task: Omit<Partial<TaskProperties>, "id">, taskId: string): Promise<IEither<Failure, Task>> => {
        const uTask = this._taskCollection.where(t => t.id == taskId).at(0);
        if (!uTask) return Either.left<Failure, Task>({ message: 'Не удается найти такую задачу' });
        const nTask = new Task({...uTask, ...task})
        this._taskCollection.setMany([nTask]);
        return (await handleRequest(() => TaskService
            .editTask(TaskAdapter.toDto(nTask))))
            .map(TaskAdapter.fromDto);
    }

    @action
    delete(taskId: string): boolean {
        throw new Error("Method not implemented.");
    }

}