import { DeviceCount } from '../models/Device';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { AxiosResponse } from 'axios';
import { $instanceApi } from '../MainApi';

export class DeviceService {

    /**
     * @returns DeviceCountResponse Success
     * @throws ApiError
     */
    public static getApiV3DeviceCountGetCount(): CancelablePromise<DeviceCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/device-count/get-count',
        });
    }

    /**
     * @param count 
     * @returns DeviceCountResponse Success
     * @throws ApiError
     */
    public static getApiV3DeviceCountSetCount(
        count: number,
    ): CancelablePromise<DeviceCount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/device-count/set-count/{count}',
            path: {
                'count': count,
            },
        });
    }
}


// export class DeviceService {

//     /**
//      * Получение текущего количества устройств
//      * @returns Объект с количеством устройств
//      */
//     static async getApiV3DeviceCountGetCount(): Promise<AxiosResponse<DeviceCount>> {
//         return await $instanceApi.get<DeviceCount>(
//             '/api/v3/device-count/get-count'
//         );
//     }

//     /**
//      * Установка нового значения для количества устройств
//      * @param count Новое значение для количества устройств
//      * @returns Объект с обновленным количеством устройств
//      */
//     static async getApiV3DeviceCountSetCount(count: number): Promise<AxiosResponse<DeviceCount>> {
//         return await $instanceApi.post<DeviceCount>(
//             `/api/v3/device-count/set-count/${count}`
//         );
//     }
// }
