import { action, makeAutoObservable, makeObservable, observable, onBecomeObserved, onBecomeUnobserved } from "mobx"
import moment from "moment";


export class Timer {
    private intervalHandler: ReturnType<typeof setInterval> | null = null;
    @observable
    currentTime: moment.Moment;
    private startAt: moment.Moment;
    private interval: number;

    /**
     * @param startAt when timer start ticking, example: 14:00, 15: 00
     * @param interval interval of ticking in ms
     */
    constructor(startAt: moment.Moment, interval: number) {
        const currTime = moment();
        this.currentTime = currTime;
        this.startAt = startAt;
        this.interval = interval;
        makeObservable(this);
        onBecomeObserved(this, 'currentTime', this.startTicking);
        onBecomeUnobserved(this, 'currentTime', this.stopTicking);
    }

    @action
    private tick = () => {
        this.currentTime = moment();
        // console.log('TICK - ', this.currentTime);
        
    }

    /**
     * @returns diff between start at and now in ms
     * @description calculate diff between start and now, if start time smaller then now, it
     * will calc diff by adding interval value to time till it be greater then now
     * @example start = 11.11 08:00, now - 11.11. 12:11, return 49 min
     */
    private getDiffMs = () => {
        let startAt = this.startAt.clone();
        
        let diff = startAt.diff(moment(), 'milliseconds');
        if (diff < 0) {
            while (diff < 0) {
                startAt.add({ milliseconds: this.interval });
                diff = startAt.diff(moment(), 'milliseconds');
            }
        }
        // console.log('END GET DIFF', diff / 60000);
        
        return diff;
    }

    private startTicking = () => {
        const diff = this.getDiffMs();
        // console.log('DIIF - ', diff / 60000);
        
        if (diff < 0) {
            this.tick();
            this.intervalHandler = setInterval(() => this.tick(), this.interval);
        }
        else {
            setTimeout(() => { 
                this.tick();
                this.intervalHandler = setInterval(() => this.tick(), this.interval);
            }, diff)
        }
        // console.log('INTERVAl - ', this.interval / 60000);
        
    }

    private stopTicking = () => {
        // console.log('STOP TICKING');
        clearInterval(this.intervalHandler ?? undefined);
        this.intervalHandler = null
    }
}