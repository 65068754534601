import { error } from 'console';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { ICity, ILoginData, ILoginResponce, IUser } from "../models/Login";
import axios, { AxiosError, AxiosResponse } from "axios";

const globalUrl = process.env.REACT_APP_API_GLOBAL_URL;


// export const $api = axios.create({
//     // withCredentials: true,
//     baseURL: globalUrl
// });

// $api.interceptors.request.use((config) => {
//     config.headers.Authorization = `Bearer ${localStorage.getItem('refToken')}`;
//     return config;
// });

// $api.interceptors.response.use((config) => {
//     return config;
// }, async (error) => {
//     const originalRequest = error.config;
//     const refreshToken = localStorage.getItem('refToken');
//     if (error.response.status == 401 && error.config && !error.config._isRetry) {
//         originalRequest._isRetry = true;
//         try {
//             const response = await axios.post<ILoginResponce>(
//                 `${globalUrl}/admin/auth/refresh`,
//                 `"${refreshToken}"`,
//                 // {
//                 //     refreshToken
//                 // },
//                 {
//                     // withCredentials: true,
//                     headers: {
//                         'Access-Control-Allow-Origin': '*',
//                         'Content-Type': 'application/json',
//                     }
//                 }
//             );

//             localStorage.setItem('refToken', response.data.refreshToken);
//             return $api.request(originalRequest);

//         } catch (error) {
//             console.log('не авторизован');
//         }

//     }
//     throw error;
// });

// export class AuthService {
//     static async login(data: ILoginData): Promise<AxiosResponse<ILoginResponce>> {

//         // @ts-ignore
//         if (data.remember === '1') data.remember = true;
//         else data.remember = false;

//         return await $api
//             .post<ILoginResponce>(
//                 `${globalUrl}/admin/auth/login`, {
//                 ...data,
//                 source: "adminpanel"
//             });
//     }

//     static async logout(token: string = `${OpenAPI.TOKEN}`) {
//         return await $api
//             .post<ILoginResponce>(
//                 `${globalUrl}/admin/auth/logout`,
//             {},
//             {
//                 headers: {
//                     Token: token
//                 }
//             });
//     }

//     static async getInstances(userId: number) {
//         return await $api
//             .post<ILoginResponce>(
//                 `${globalUrl}/instances`, //avalible cities
//                 { id: userId },
//                 {
//                     headers: {
//                         token: `${OpenAPI.TOKEN}`
//                     }
//                 });
//     }

// }



// Базовая настройка Axios
export const $api = axios.create({
    baseURL: globalUrl,
});

// Функция для обновления токена
export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refToken');
    try {
        const response = await axios.post<ILoginResponce>(
            `${globalUrl}/admin/auth/refresh`,
            JSON.stringify({ refreshToken }), // используем правильный формат данных
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        localStorage.setItem('refToken', response.data.refreshToken);
        return response.data.refreshToken;
    } catch (error) {
        console.error('Ошибка обновления токена', error);
        throw error;
    }
};

// Перехватчик для запросов (установка заголовков с токеном)
$api.interceptors.request.use((config) => {
    const token = localStorage.getItem('refToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Перехватчик для ответов
$api.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;

    // Если ошибка 401 или 403, и это не повторная попытка
    if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._isRetry) {
        originalRequest._isRetry = true;

        try {
            // Обновляем токен
            const newToken = await refreshAccessToken();

            // Устанавливаем новый токен в заголовок
            originalRequest.headers.Authorization = `Bearer ${newToken}`;

            // Повторно отправляем оригинальный запрос
            return $api.request(originalRequest);
        } catch (refreshError) {
            console.log('Ошибка авторизации, пользователь не авторизован');
            // Здесь можно перенаправить пользователя на страницу логина или выполнить другие действия
            return Promise.reject(refreshError);
        }
    }

    // Если не 401 или 403, возвращаем ошибку дальше
    return Promise.reject(error);
});


export class AuthService {
    static async login(data: ILoginData): Promise<AxiosResponse<ILoginResponce>> {
        // Преобразуем remember в boolean
        // @ts-ignore
        if (data.remember === '1') data.remember = true;
        else data.remember = false;

        return await $api.post<ILoginResponce>(
            `${globalUrl}/admin/auth/login`, {
            ...data,
            source: "adminpanel"
        });
    }

    static async logout(token: string = `${OpenAPI.TOKEN}`) {
        return await $api.post<ILoginResponce>(
            `${globalUrl}/admin/auth/logout`,
            {},
            {
                headers: {
                    Token: token
                }
            }
        );
    }

    static async getInstances(userId: number) {
        return await $api.post<ILoginResponce>(
            `${globalUrl}/instances`,
            { id: userId },
            {
                headers: {
                    token: `${OpenAPI.TOKEN}`
                }
            }
        );
    }
}
