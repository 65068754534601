import { OpenAPIConfig } from "..";

export const GamesOpenApi: OpenAPIConfig = {
    BASE: process.env.REACT_APP_GAMES_URL,
    VERSION: '1.0',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'include',
    TOKEN: undefined,
    USERNAME: undefined,
    PASSWORD: undefined,
    HEADERS: undefined,
    ENCODE_PATH: undefined,
};