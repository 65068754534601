import { ReactDOMAttributes } from "@use-gesture/react/dist/declarations/src/types";
import { TBodyProps, TCellData, TRow, TableProps } from "../types";
import { useGesture } from "@use-gesture/react";
import { _EVENTS_START } from "./TEventsWrapper";
import React, { useCallback, useRef, useState, useMemo, memo } from "react";
import { TCell } from "./TCell";

//This component just render tbody
export const _TBody = <T extends {}>(props: TBodyProps<T>) => {

    const { rows, cellBuilder, columns, bind, transposition, headerBuilder, isHeadFixed, headerStyleClass: headerStyles, bodyStyleClass: bodyStyles, subheader } = props;

    const rowsMemo = useMemo(() => {
        const matrix = rows.map(row => [...row.data]);
        if (transposition) {
            return matrix[0]
                .map((col, i) => matrix
                    .map(row => row[i]));
        }
        return matrix;
    }, [transposition, rows, columns])

    return (
        <tbody {...bind()} style={{ touchAction: 'none' }} className={bodyStyles}>
            {rowsMemo.map((row, i) => (
                <tr key={i}>
                    {
                        transposition && <>
                            <td style={{ position: `${isHeadFixed ? 'sticky' : 'initial'}`, left: 0, width: '120px' }} className={headerStyles}>
                                {headerBuilder(columns[i])}
                            </td>
                            {subheader && <td className={headerStyles}>{subheader(columns[i])}</td>}
                        </>

                    }
                    {row.map(c => (
                        <TCell key={c.id} builder={cellBuilder} tCellData={c} />
                    ))}
                </tr>
            ))}
        </tbody>
    );
}
