import { GameDto } from "../../../api/models/Game";
import { Game } from "../domain/entities/Game";

export const fromDto = (game: GameDto): Game => {
    return ({
        id: game.id,
        gameTypeId: game.gameTypeId,
        guestCountMax: game.guestMax ?? 1,
        guestCountMin: game.guestMin ?? 1,
        title: game.title,
        price: game.price
    })
}