import { inject, injectable } from "inversify";
import { ALL_SELECT, BaseCollection } from "../../../@types/baseCollection";
import { Game } from "../domain/entities/Game";
import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import 'reflect-metadata';
import { container } from "../../../di/container";
import { ICollection } from "../../../@types/iCollection";
import { Either, IEither } from "../../../@types/either";
import { Failure } from "../../../@types/failure";
import { handleRequest } from "../../../common/utility-types/helpers/handleRequest";
import { CancelablePromise, GameService } from "../../../api";
import { fromDto } from "../adapters/gameFromDto";
import { RequestStatus } from "../../../@types/requestStatus";
import { BaseApiCollection } from "../../../@types/baseApiCollection";
import { GameDto } from "../../../api/models/Game";
import { filialLocalDataSource } from "./db";


@injectable()
export class GameDataSourse implements ICollection<Game>{

    // gamesCashcollection = useLiveQuery(() => filialLocalDataSource.games.toArray(), []);
    private _baseCollection: BaseApiCollection<Game, GameDto> = new BaseApiCollection();
    private _gamesPromise: Promise<Game[]> | CancelablePromise<Game[]> | null = null;

    constructor() {
        makeObservable(this);
    }

    @computed
    get isLoaded() {
        return this._baseCollection.isLoaded;
    }

    @computed
    get isLoading() {
        return this._baseCollection.isLoading;
    }

    @computed
    get error() {
        return this._baseCollection.error;
    }

    @computed
    get gamesPromise() {
        return this._gamesPromise;
    }

    @action
    load = async (gameServerFilialId: number): Promise<Game[]> => {
        // Получить здесь нужный сервис 
        const savedGames = await filialLocalDataSource.getGames();
        
        // if (savedGames.length != 0) {
        //     this._baseCollection.setMany(savedGames);

        // } else {
        // await this._baseCollection.load(() => GameService.getGames(gameServerFilialId), fromDto);
        const getGamesPromise = this._gamesPromise ?? this._baseCollection.load(
            () => GameService.getGames(gameServerFilialId),
            async (v) => fromDto(v)
        );
        if (!this._gamesPromise) this._gamesPromise = getGamesPromise;
        await getGamesPromise;

        await filialLocalDataSource.addGames(this._baseCollection.where(ALL_SELECT));
        // }

        return this._baseCollection.where(ALL_SELECT);
    };

    getById(this: GameDataSourse, id: string): Game | null {
        return this._baseCollection.getById(id);
    }

    where(this: GameDataSourse, predicate: (v: Game) => boolean): Game[] {
        return this._baseCollection.where(predicate);
    }

    setMany(this: GameDataSourse, values: Game[]): void {
        this._baseCollection.setMany(values);
    }

    addMany(this: GameDataSourse, values: Game[]): void {
        this._baseCollection.addMany(values);
    }

    deleteMany(this: GameDataSourse, values: Pick<Game, "id">[]): void {
        this._baseCollection.deleteMany(values);
    }

    getByGameType(this: GameDataSourse, gameTypeId: number) {
        return this.where(game => game.gameTypeId == gameTypeId);
    }
}