import { _TBody } from './TBody';
import { TBodyEventsWrapper } from './TEventsWrapper';
import { _THead } from './THead';
import { TWrapper } from './TWrapper';
import '../styles/style.css';
import { TableProps } from '../types';
import Cursor from '../../cursor/Cursor';

///принимает матрицу
type _EnhastedTableProps<T> = TableProps<T>
///Таблица получает, и рендерит все
const _EnhastedTable = <T extends {}>({
    cellBuilder,
    headerBuilder,
    columns,
    rows,
    onCellClick,
    onCursorMoving,
    onCellDrag,
    onStartDrag,
    onPanTable,
    subheader,
    transposition = false,
    width = 'expand',
    height = 'expand',
    isFixed = false,
    isHeadFixed = false,
    cellDragTimeDelay = 500,
    headerStyleClass: headerStyles,
    bodyStyleClass: bodyStyles,
    cursor = { cursorNode: Cursor, offsetMouseX: 5, offsetMouseY: 10 },
    onDragging
}: _EnhastedTableProps<T>) => {
    return (
        <TWrapper
            width={width}
            height={height}
            cellDragTimeDelay={cellDragTimeDelay}
            isFixed={isFixed}
            cursor={cursor}
        >
            <table
                // style={{
                //     tableLayout: isFixed ? 'fixed' : 'auto',
                // }}
                className={isFixed ? 'fixed_table' : 'table_auto'}
            >
                <_THead columns={columns} headerBuilder={headerBuilder} isHeadFixed={isHeadFixed} transposition={transposition} headerStyleClass={headerStyles} subheader={subheader} />
                <TBodyEventsWrapper
                    rows={rows}
                    cellBuilder={cellBuilder}
                    subheader={subheader}
                    columns={columns}
                    child={_TBody}
                    onCellClick={onCellClick}
                    onCellDrag={onCellDrag}
                    onPanTable={onPanTable}
                    onStartDrag={onStartDrag}
                    onCursorMoving={onCursorMoving}
                    transposition={transposition}
                    headerBuilder={headerBuilder}
                    isHeadFixed={isHeadFixed}
                    headerStyleClass={headerStyles}
                    bodyStyleClass={bodyStyles}
                    onDragging={onDragging}
                />
            </table>
        </TWrapper>
    );
}

export default _EnhastedTable;