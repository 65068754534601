import { Input } from "antd"
import DefaultController from "../../../../common/presentation/DefaultController"
import { ConnectOrder } from "../providers/OrderProvider"

export const TitleController: React.FC = () => {
    return <ConnectOrder >
        {({ control }) => <DefaultController
            control={control}
            name='title'
            render={({ field }) => <Input
                {...field}
                placeholder="Имя заказа"
                value={field.value ?? ''}
                className='default-input'
                bordered = {false}
            />}
        />}
    </ConnectOrder>
}