import { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { TaskDto, TaskRequest } from "../models/TaskDto";
import { request as __request } from '../core/request';
import { $instanceApi } from "../MainApi";

export class TaskService {

    /**
     * @param date в формате DD-MM-YYYY
     * @description Возвращает задачи по дате
     */
    static async getTasksByDate(date: string, token?: string): Promise<Array<TaskDto>> {
        return await __request(OpenAPI, {
            method: 'GET',
            url: `/api/v3/worktasks/${date}`,
            headers: {
                'token': token,
            },
        });
    }

    static async createTask(task: TaskRequest): Promise<TaskDto> {
        return await __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/worktasks/create',
            body: task
        });
    }

    static async editTask(task: TaskDto): Promise<TaskDto> {
        return await __request(OpenAPI, {
            method: 'PUT',
            url: `/api/v3/worktasks/${task.id}/edit`,
            body: task
        });
    }

    static deletetask(id: string) {

    }
}

// export class TaskService {

//     /**
//      * Возвращает задачи по дате
//      * @param date В формате DD-MM-YYYY
//      * @returns Массив задач
//      */
//     static async getTasksByDate(date: string): Promise<Array<TaskDto>> {
//         const response = await $instanceApi.get<Array<TaskDto>>(`/api/v3/worktasks/${date}`);
//         return response.data; // Возвращаем только данные
//     }

//     /**
//      * Создать задачу
//      * @param task Объект задачи
//      * @returns Созданная задача
//      */
//     static async createTask(task: TaskRequest): Promise<TaskDto> {
//         const response = await $instanceApi.post<TaskDto>('/api/v3/worktasks/create', task);
//         return response.data; // Возвращаем только данные
//     }

//     /**
//      * Изменить задачу
//      * @param task Объект задачи
//      * @returns Обновленная задача
//      */
//     static async editTask(task: TaskDto): Promise<TaskDto> {
//         const response = await $instanceApi.put<TaskDto>(`/api/v3/worktasks/${task.id}/edit`, task);
//         return response.data; // Возвращаем только данные
//     }

//     /**
//      * Удалить задачу
//      * @param id Идентификатор задачи
//      */
//     static async deleteTask(id: string): Promise<void> {
//         await $instanceApi.delete(`/api/v3/worktasks/${id}/delete`);
//     }
// }
