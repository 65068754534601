import { Col, Row, Switch } from 'antd'
import React from 'react'

import './vipStyles.css'

export type StatusType = 'disabled' | 'checked' | 'unchecked';

interface VipCheckboxProps {
    num: number;
    status: StatusType;
    onStatusChange: (num: number, checked: boolean) => void;
}

const VipCheckbox: React.FC<VipCheckboxProps> = ({ num, status, onStatusChange }) => {
    const isChecked = status === 'checked';
    const isDisabled = status === 'disabled';

    return (
        <Row gutter={[10, 0]} wrap={false}>
            <Col style={{marginTop: '5px'}}>
                <span style={{color: '#444656', fontWeight: '500', fontSize: '16px'}}>Випка {num}</span>
            </Col>
            <Col>
                <Switch 
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={(checked) => onStatusChange(num, checked)}
                /> 
            </Col>
        </Row>
    )
}

export default VipCheckbox;