import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { ITimeline } from "../../domain/entities/ITimeline";
import { Timeline } from "../../domain/entities/Timeline";
import { ITimelineParams } from "../../domain/entities/TimelineParams";
import { DashboardOrderAdapter } from "../../domain/utils/DashboardOrderAdapter";
import { ITimelineOrderAdapter } from "../../domain/utils/ITimelineOrderAdapter";
import { Without } from "../../../../@types/without";
import { ITimelineSettings } from "../../domain/entities/ITimelineSettings";
import { TCellData } from "../../../../common/presentation/enhasted-table/types";
import { IBookingCell } from "../../domain/entities/IBookingCell";
import moment from "moment";
import { TColumnType } from "../../../../common/presentation/enhasted-table";
import { TimelineInfo } from "../../../../api/models/OrderForDayResponse";
import { Order } from "../../../booking/domain/entities/Order";
import { Booking } from "../../../booking/domain/entities/Booking";
import MathHelper from "../../../../common/utility-types/helpers/MathHelper";
import { Modal } from "antd";
import { container } from "../../../../di/container";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import { injectable } from "inversify";
import { IBooking } from "../../../booking/domain/entities/IBooking";
import { TimelineColumn } from "../../domain/entities/TimelineColumn";

@injectable()
export class DashboardViewModel {

    @observable
    private _timeline: ITimeline | null = null;
    @observable
    isLoading: boolean = false;
    @observable
    // private _selectedBookingsId: string[] = [];
    private _selectedBookings: Booking[] = [];

    private _prevDraggingCell: IBookingCell | null = null;
    private _orders: Without<IOrder, Function>[] = [];

    constructor(
        private _ordersTimelineAdapter = new DashboardOrderAdapter(),
        private _ordersRepository = container.get(OrdersRepository)
    ) {
        makeObservable(this);
    }

    // @computed
    // get selectedBookings(): string[] {
    //     return this._selectedBookingsId;
    // }
    @computed
    get selectedBookings(): Booking[] {
        return this._selectedBookings;
    }

    // @action
    // setSelectedAllBooking(selectedIdList: string[]) {
    //     if (this._selectedBookingsId.some(elem => selectedIdList.includes(elem)) && ! this._selectedBookingsId.join(',').includes(selectedIdList.join(','))) {

    //         this._selectedBookingsId = [...this._selectedBookingsId, ...selectedIdList].filter((item, i, ar) => ar.indexOf(item) === i);
    //     }
    //     if (this._selectedBookingsId.join(',').includes(selectedIdList.join(','))) {

    //         selectedIdList.forEach(selectedId => {
    //             this._selectedBookingsId = this._selectedBookingsId.filter(id => id !== selectedId);
    //         });

    //     } else {
    //         this._selectedBookingsId = [...this._selectedBookingsId, ...selectedIdList];
    //     }
    // }


    //dashboardVM.selectedBookings.findIndex(book => book.id === booking.id) > -1 

    @action
    setSelectedAllBooking(selectedIdList: IBooking[]) {
        const bookIdArray = this._selectedBookings.map(elem => elem.id);
        const selectedIdListBookings = selectedIdList.map(elem => elem.id);

        const isIncluded = selectedIdListBookings.every(elem => bookIdArray.includes(elem));

        if (bookIdArray.some(elem => selectedIdListBookings.includes(elem)) && !isIncluded) {
            this._selectedBookings = [...this._selectedBookings, ...selectedIdList].filter((item, i, ar) => ar.indexOf(item) === i);
        }
        // else if (bookIdArray.join(',').includes(selectedIdListBookings.join(','))) {
        else if (isIncluded) {

            selectedIdList.forEach(selectedId => {
                this._selectedBookings = this._selectedBookings.filter(book => book.id !== selectedId.id);
            });

        } else {
            this._selectedBookings = [...this._selectedBookings, ...selectedIdList];
        }
    }

    // @action
    // setSelectedBookingId(selectedId: string) {
    //     if (this._selectedBookingsId.find(id => id === selectedId)) {
    //         this._selectedBookingsId = this._selectedBookingsId.filter(id => id !== selectedId);
    //     } else {
    //         this._selectedBookingsId = [...this._selectedBookingsId, selectedId];
    //     }
    // }
    @action
    setSelectedBookingId(selected: IBooking) {
        if (this._selectedBookings.find(booking => booking.id === selected.id)) {
            this._selectedBookings = this._selectedBookings.filter(booking => booking.id !== selected.id);
        } else {
            this._selectedBookings = [...this._selectedBookings, selected];
        }
    }

    @action
    setClearSelectedBookings() {
        this._selectedBookings = [];
    }

    @action
    adaptToTimeline = (orders: Without<IOrder, Function>[], params: TimelineInfo[]) => {
        this._orders = orders;
        this._timeline = this._ordersTimelineAdapter.fromOrders(orders, params);
    };

    @action
    updateIsLoading = (v: boolean) => {
        this.isLoading = v;
    };

    @action
    updateGlassesCount = (newCount: number) => {
        this._timeline?.updateGlassesCount(newCount);
    };
    @action
    updateRowsCount = (newCount: number) => {
        this._timeline?.updateRowsCount(newCount);
    };

    @computed
    get timeline() {
        return this.isLoading
            ? Timeline.loading()
            : this._timeline ?? Timeline.loading();
    }

    @computed
    get tRows() {
        return this.timeline?.rows.map(row => ({
            rKey: row.rowId.toString(),
            data: row.data.map<TCellData<IBookingCell | IBookingCell[] | null>>(cell => ({
                id: cell.id,
                data: cell
            }))
        })) ?? [];
    }

    @computed
    get tCols() {
        return this.timeline?.columns.map<TColumnType>(col => {
            return ({
                cKey: col.time,
                label: moment(col.time).format('HH:mm'),
                glassesCount: isNaN(col.glassesCount) ? 0 : col.glassesCount
            })
        }) ?? []
    }

    @computed
    get bookedCellsCount() {
        return this.tRows
            .map(r => r.data.length)
            .reduce((prev, curr) => prev + curr, 0);
    }

    getOrderById(id: string) {
        return this._orders.find(o => o.id == id) ?? null;
    }

    @action
    onDragging = (startCellId: string, cellId: string, time: string, rowIndex: number) => {
        const cell = this.timeline.getCellById(cellId);
        if (this._prevDraggingCell?.id != cell?.id) {
            cell?.setIsDraggingTarget(true);
            this._prevDraggingCell?.setIsDraggingTarget(false);
            this._prevDraggingCell = cell;
        }
        // this.timeline.updateCol(this.timeline.getCellById)

        console.log("onDragging");

    };

    @action
    onDragCell = (
        from: IBookingCell,
        to: IBookingCell,
        handler: (orderGetter: (order: IOrder) => IOrder | null) => void
    ) => {
        console.log("onDragCell");
        if (to.bookingPossability == 'unavailable') return;
        if (!from.booking) return;
        if (!to.booking) return this._switchBookingsPosition(from.booking, moment(to.time), Number.parseInt(to.additionalId.toString()), handler);
        return Modal.confirm({
            title: `Вы хотите поменять брони местами`,
            cancelText: 'Нет',
            okText: 'Да',
            onCancel: () => { },
            onOk: () => {
                if (from.booking && to.booking) {
                    this._swapBookings(from.booking, to.booking, handler);
                }
            }
        });
    };

    @action
    multiDragCell = (
        from: IBookingCell[],
        to: IBookingCell[],
        handler: (orderGetter: (order: IOrder) => IOrder | null) => void
    ) => {
        if (from.some(f => f.booking === null)) return;
        if (to.some(t => t.booking === null)) {
            // return this._switchBookingsPosition(from.booking, moment(to.time), Number.parseInt(to.additionalId.toString()), handler);


            // if (booking.isEditing) {
            //     handler((order) => new Order({
            //         ...order,
            //         bookings: order.bookings.map(b => b.id == booking?.id
            //             ? new Booking({
            //                 ...b,
            //                 rowCellId: nRowIndex,
            //                 bookingDate: nDate
            //             })
            //             : b
            //         )
            //     }));
            // }
            // else {
            //     const fromOrder = this.getOrderById(booking.orderId);
            //     if (fromOrder) {
            //         this._ordersRepository.save(new Order({
            //             ...fromOrder, bookings: fromOrder.bookings.map(b => b.id == booking?.id
            //                 ? new Booking({
            //                     ...b,
            //                     rowCellId: nRowIndex,
            //                     bookingDate: nDate
            //                 })
            //                 : b)
            //         }));
            //     }
            // }
        }
    };

    private _swapBookings = (
        from: IBooking,
        to: IBooking,
        handler: (orderGetter: (order: IOrder) => IOrder | null) => void
    ) => {
        if (!to.bookingDate || !from.bookingDate) return;
        if (to.orderId != from.orderId) {
            this._switchBookingsPosition(from, to.bookingDate, to.rowCellId, handler);
            this._switchBookingsPosition(to, from.bookingDate, from.rowCellId, handler);
            return;
        }
        const order = this.getOrderById(to.orderId);
        if (!order) return;
        const uOrder = new Order({
            ...order,
            bookings: order.bookings.map(booking => {
                if (booking.id == to.id) return new Booking({
                    ...booking,
                    rowCellId: from.rowCellId,
                    bookingDate: from.bookingDate
                });
                if (booking.id == from.id) return new Booking({
                    ...booking,
                    rowCellId: to.rowCellId,
                    bookingDate: to.bookingDate
                });
                return booking;
            })
        });
        if (to.isEditing) handler(_ => uOrder);
        else this._ordersRepository.save(uOrder);
    };


    private _switchBookingsPosition = (
        booking: IBooking,
        nDate: moment.Moment,
        nRowIndex: number,
        handler: (orderGetter: (order: IOrder) => IOrder | null) => void,
    ) => {
        if (booking.isEditing) {
            handler((order) => new Order({
                ...order,
                bookings: order.bookings.map(b => b.id == booking?.id
                    ? new Booking({
                        ...b,
                        rowCellId: nRowIndex,
                        bookingDate: nDate
                    })
                    : b
                )
            }));
        }
        else {
            const fromOrder = this.getOrderById(booking.orderId);
            if (fromOrder) {
                this._ordersRepository.save(new Order({
                    ...fromOrder, bookings: fromOrder.bookings.map(b => b.id == booking?.id
                        ? new Booking({
                            ...b,
                            rowCellId: nRowIndex,
                            bookingDate: nDate
                        })
                        : b)
                }));
            }
        }
    };

    @action
    onCellClick = (cell: IBookingCell, handler: (orderGetter: (order: IOrder) => IOrder | null) => void) => {
        if (cell.bookingPossability == 'unavailable') return;
        handler((order) => {
            // if (cell.booking?.orderId == order?.id) return new Order({
            //     ...order,
            //     bookings: order.bookings.filter(v => v.id != cell.booking?.id),
            // });
            if (cell.booking == null) return new Order({
                ...order, bookings: [...order.bookings, Booking.initial({
                    time: moment(cell.time),
                    orderId: order.id,
                    id: MathHelper.getUUID(),
                    rowIndex: Number.parseInt(cell.additionalId.toString())
                })]
            });
            return order;
        });
    };
}