import React from 'react'
import styled from 'styled-components'
import cross from '../../../../assets/cross.svg'
import notif from '../../../../assets/notif.svg'
import NotificationBody from './NotificationBody'
import { IOrder } from '../../domain/entities/IOrder'
import { IOrderSocket } from '../../domain/repository/OrdersRepository'

interface Props {
  order?: IOrderSocket
}

const Notification: React.FC<Props> = ({order}) => {
  return (
    <StyledNotif>
      <StyledHeader>
        <img src={notif} alt="!!!" />
        <h3>Забронена игра на {order?.bookings.find(booking => booking.bookingDate)?.bookingDate?.format('DD.MM.YYYY, HH:mm')}</h3>
        {/* <img src={cross} alt="закрыть"/> */}
        <span> </span>
      </StyledHeader>
     
      <NotificationBody order={order}/>

    </StyledNotif>
  )
}

export default Notification

const StyledNotif = styled.div`
  padding: 14px 16px;

  border-radius: 15px;

  -webkit-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);

  margin-bottom: 24px;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3{
    color: #444656;
    font-size: 16px;
    font-family: Gilroy;
    margin: 0;
  }

  img:first-child{
    margin-right: 10px;
  }

  img:not(:first-child){
    margin-bottom: 5px;
    cursor: pointer;
  }
`
