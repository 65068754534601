import { Personal } from "../../../../api/models/Personal";
import { VipRoomBookingsDto } from "../../../../api/models/VipRoomBookings";
import { Client, PriceInfo } from "../types";
import { EOrderStatus } from "./EOrderStatus";
import { IBooking } from "./IBooking";
import { IPaymentInfo } from "./IPaymentInfo";

export enum ESourceStatus {
    CLIENT,
    ADMIN
}

export interface IOrder {
    id: string
    client: Client | null
    title: string | null
    status: EOrderStatus
    source: ESourceStatus
    bookings: IBooking[]
    comment: string | null,
    isSendMessage: boolean;
    paymentInfo: IPaymentInfo | null;
    priceInfo:  PriceInfo | null
    personalCode: number | null
    personal: Personal
    vipRoomBookings: VipRoomBookingsDto[]

    equals(other: IOrder): boolean;

    setId(id: string): void;
    setClient(client: Client | null): void;
    setStatus(status: EOrderStatus): void;
    setSource(source: ESourceStatus): void;
    setBookings(bookings: IBooking[]): void;
    setPriceInfo(priceInfo: PriceInfo | null): void;
    setPaymentInfo(loyaltyInfo: IPaymentInfo | null): void;
    setPersonalCode(personalCode: number | null): void;
    setPersonal(personal: Personal): void;
    setVipRoomBookings(vipRoomBookings: VipRoomBookingsDto[] | null): void; 
}