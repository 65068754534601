import moment from "moment";
import { Either } from "../../../../@types/either";
import { Failure } from "../../../../@types/failure";
import { UseCase } from "../../../../@types/useCase";
import { ITimeline, } from "../entities/ITimeline";
import { TimelineService } from "../../../../api";
import { Timeline } from "../entities/Timeline";
import { DATE_FORMAT } from "../../../../common/constants";
import { handleRequest } from "../../../../common/utility-types/helpers/handleRequest";
import { IFilialService } from "../../../fillial/domain/services/IFilialService";
import { container } from "../../../../di/container";
import { FilialService } from "../../../fillial/domain/services/FilialService";
import { action } from "mobx";
import { BookingDto } from "../../../../api/models/Booking";
import { Game } from "../../../fillial/domain/entities/Game";
import { ESourceStatus } from "../../../booking/domain/entities/IOrder";
import { OrderAdapter } from "../../../booking/adapters/OrderAdapter";
import { OrderForDayResponse } from "../../../../api/models/OrderForDayResponse";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";

export class LoadTimelineUseCase implements UseCase<moment.Moment, Promise<Either<Failure, OrderForDayResponse>>> {


    constructor(
        private _filialService: IFilialService = container.get(FilialService),
        private _ordersRepository = container.get(OrdersRepository),
        private _orderAdapter = new OrderAdapter()
    ) { }

    @action
    execute = async (date: moment.Moment) => {
        const games = await this._filialService.gamesPromise;
        const dateStr = date.format(DATE_FORMAT);
        return (await handleRequest(async () => TimelineService.getOrdersForDayWithParams(dateStr)))
            .match({
                onLeft: (l) => Either.left<Failure, OrderForDayResponse>(l),
                onRight: (r) => {
                    this._ordersRepository.setOrders(r.orders.map(order => this._orderAdapter.syncOrderFromDto(order)));
                    return Either.right<Failure, OrderForDayResponse>(r);
                } 
                //     Timeline.fromDto({
                //     timileneBlocks: (r.entries ?? []).map(block => ({
                //         time: block.time,
                //         info: block.info,
                //         bookings: (block.bookings ?? []).map<(BookingDto & { game: Game | null, source: ESourceStatus })>(booking => ({
                //             ...booking.booking,
                //             status: booking.status,
                //             source: this._orderAdapter.sourceFromString(booking.source),
                //             game: games.find(game => game.id == booking.booking?.gameId) ?? null
                //         }))
                //     }))
                // })
            });
    }
}