import { LoginModal } from '../features/authentication/ui/LoginModal';
import { Row } from 'antd';

import 'bootstrap/dist/css/bootstrap.css';
import '../features/authentication/ui/AdminLoginStyles.css';
import { useEffect, useMemo, useState } from 'react';
import { LoginViewModal } from '../features/authentication/ui/loginViewModal';
import Loader from '../common/presentation/Loader';

let isTokenLoginRequested = false;

export const AuthPage: React.FC = () => {
    const path = window.location.pathname;

    const auth = useMemo(() => new LoginViewModal(), []);


    return (
        <Row className='white-bg' align='middle' justify='center'>
            <LoginModal />
        </Row>
    );
};
