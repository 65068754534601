import { Dropdown, Table } from "antd"
import styled from "styled-components"

export const StyledDropdown = styled(Dropdown)`
  li span {
    font-family: 'Girloy' !important;
    font-size: 20px !important;
  }
`

export const StyledBookingTable = styled(Table)`
  /* margin: 20px; */

  .ant-table.ant-table-bordered > .ant-table-container{
    border: none;
  }
  
  table {
      /* border-radius: 30px !important; */
    /* border-spacing: 3px !important; */
    max-width: 100% !important;
    background-color: #F5F6F8;
    border: none !important;
    width: 100%;

    .ant-table-cell:nth-child(2){
      padding: 0;
    }

    .ant-table-cell-ellipsis{
      font-family: 'Gilroy' !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
   }

   th{
    box-shadow: none;
    font-family: Gilroy !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(138, 146, 158, 1);

    overflow-wrap: normal;


    &:first-child{
      border-radius: 15px 0 0 0 !important;
    }
    &:last-child{
      /* border-radius: 0 15px 0 0; */
      width: 60px;
      border-top-right-radius: 15px !important;
    }
   }

    thead {
      cursor: default;

      /* height: 50px !important; */

      background: rgba(220, 222, 230, 1);
      
      /* th:first-child{
        width: 47px !important;
      }
      th:last-child{
        width: 53px;
      } */
      outline: none;
      border-bottom: none;
    }    
}
`

export const StyledCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
`