import { Either, IEither } from "./either";


export class Right<L, R> implements IEither<L, R> {

    right: R ;

    constructor(r: R) {
        this.right = r;
    }
    map<C>(this: Right<L, R>, mapCallback: (r: R) => C): IEither<L, C> {
        return  Either.right<L, C>(mapCallback(this.right));
    }

    match<C>(this: Right<L, R>, args: { onLeft: (left: L) => C; onRight: (r: R) => C; }): C {
        return args.onRight(this.right);
    }
    getRight(this: Right<L, R>, orElse: (l: L) => R): R {
        return this.right;
    }
    getLeft(this: Right<L, R>, orElse: (r: R) => L): L {
        return orElse(this.right);
    }
    isLeft(this: Right<L, R>): boolean {
        return false;
    }
    isRight(this: Right<L, R>): boolean {
        return true;
    }
    
}