import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import moment from 'moment';
import { setup } from './di/container';
import RouterConfiguration from './common/router/RouterConfiguration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

moment.locale('ru', {
  week: {
    dow: 1,
  },
  months: 'Январь, Февраль, Март, Апрель, Май, Июнь, Июль, Август, Сентябрь, Октябрь, Ноябрь, Декабрь.'.split(', '),
  weekdays: 'Воскресенье, Понедельник, Вторник, Среда, Четверг, Пятница, Суббота'.split(', ')
});

setup();

root.render(
  <React.StrictMode>
      <RouterConfiguration />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
