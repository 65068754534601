import { IReactionDisposer, action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { container } from "../../../../di/container";
import { TaskRepository } from "../../data/TaskRepository";
import { RequestStatus } from "../../../../@types/requestStatus";
import { Task, TaskProperties } from "../../domain/entities/Task";

export class TaskDateViewModel {
    date: moment.Moment;

    private _tasksRepository = container.get(TaskRepository)

    @observable
    loadingStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    creatingStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    updatingStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    error?: string;

    private _disposers: IReactionDisposer[] = [];

    constructor(
        date: moment.Moment
    ) {
        this.date = date;
        makeObservable(this);
        this._load();
    }

    @computed
    get tasks(): Task[] {
        return this._tasksRepository.tasks.filter(t => t.time.isSame(this.date, 'date'));
    }

    @action
    private _load = async () => {
        this.loadingStatus = RequestStatus.LOADING;
        (await this._tasksRepository.getTaskByDate(this.date)).match({
            onLeft: (l) => {
                runInAction(() => {
                    this.loadingStatus = RequestStatus.ERROR;
                    this.error = l.message;
                });
            },
            onRight: (_) => runInAction(() => this.loadingStatus = RequestStatus.SUCCESSFULL)
        });
    }

    setupOnCreateReaction = (actions: {
        onSuccessful: () => void,
        onError: (error: string) => void
    }) => {
        const { onError, onSuccessful } = actions;
        this._disposers.push(reaction((_) => this.creatingStatus, (status) => {
            if (status == RequestStatus.ERROR) return onError(this.error ?? '');
            if (status == RequestStatus.SUCCESSFULL) return onSuccessful();
        }));
    }

    setupOnUpdateReaction = (actions: {
        onSuccessful: () => void,
        onError: (error: string) => void
    }) => {
        const { onError, onSuccessful } = actions;
        this._disposers.push(reaction((_) => this.updatingStatus, (status) => {
            if (status == RequestStatus.ERROR) return onError(this.error ?? '');
            if (status == RequestStatus.SUCCESSFULL) return onSuccessful();
        }));
    }

    dispose = () => {
        for (const dosposer of this._disposers) dosposer();
    }

    @action
    createTask = async (task: Omit<TaskProperties, 'id' | 'isDone'>) => {
        this.creatingStatus = RequestStatus.LOADING;
        (await this._tasksRepository.create(task)).match({
            onLeft: (l) => runInAction(() => {
                this.creatingStatus = RequestStatus.ERROR;
                this.error = l.message;
            }),
            onRight: (_) => runInAction(() => this.creatingStatus = RequestStatus.SUCCESSFULL)
        })
    }

    @action
    updateTask = async (task: Partial<TaskProperties>, taskId: string) => {
        this.updatingStatus = RequestStatus.LOADING;
        (await this._tasksRepository.update(task, taskId)).match({
            onLeft: (l) => runInAction(() => {
                this.updatingStatus = RequestStatus.ERROR;
                this.error = l.message;
            }),
            onRight: (_) => runInAction(() => this.updatingStatus = RequestStatus.SUCCESSFULL)
        })
    }

}