import Dexie, { PromiseExtended, Table } from 'dexie';
import { Game } from '../domain/entities/Game';
import { GameType } from '../domain/entities/GameType';
import { Room } from '../domain/entities/Room';
import { action, computed } from 'mobx';
import { Either } from '../../../@types/either';
import { Failure } from '../../../@types/failure';
import { ApiError } from '../../../api';
import { ALL_SELECT } from '../../../@types/baseCollection';

export class FilialLocalDataSource extends Dexie {
    // 'friends' is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    games!: Table<Game>;
    gameTypes!: Table<GameType>;
    rooms!: Table<Room>

    constructor() {
        super('filialLocalDataSource');
        this.version(1).stores({
            games: 'id, title, gameTypeId, guestCountMax, guestCountMin',
            gameTypes: 'id, label',
            rooms: 'id, title, guestMax, description'
        });
    }

    getGames = () => {
        return this.games.toArray();
    }

    getGameTypes = () => {
        return this.gameTypes.toArray();
    }

    getRooms = () => {
        return this.rooms.toArray();
    }

    @action
    addGames = async (games: Game[]) => {
        try {
            await filialLocalDataSource
            this.games
                .bulkAdd(games
                    .map(v => ({ ...v })))
        }
        catch (e) {
            console.log('ERRROT - ', e);
            if (e instanceof ApiError) {
                return Either.left({ message: e.message });
            }
            return Either.left({ message: 'Error add in DB' });
        }
    }

    @action
    addGameTypes = async (gameTypes: GameType[]) => {
        try {
            await filialLocalDataSource
            this.gameTypes
                .bulkAdd(gameTypes
                    .map(v => ({ ...v })))
        }
        catch (e) {
            console.log('ERRROT - ', e);
            if (e instanceof ApiError) {
                return Either.left({ message: e.message });
            }
            return Either.left({ message: 'Error add in DB' });
        }
    }

    @action
    addRooms = async (rooms: Room[]) => {
        try {
            await filialLocalDataSource
            this.rooms
                .bulkAdd(rooms
                    .map(v => ({ ...v })))
        }
        catch (e) {
            console.log('ERRROT - ', e);
            if (e instanceof ApiError) {
                return Either.left({ message: e.message });
            }
            return Either.left({ message: 'Error add in DB' });
        }
    }
}

export const filialLocalDataSource = new FilialLocalDataSource();
