import { useDrag, useGesture, useMove } from "@use-gesture/react";
import { TCellData, TEventsWrapperProps, TableProps } from "../types";
import { MutableRefObject, ReactElement, useCallback, useDeferredValue, useEffect, useMemo, useRef } from "react";
import { Hash } from "crypto";
import { TDraggingContext, TDraggingContextType } from "../TWrapperContext";
import React from "react";
import { useSpring } from "react-spring";
import { TDraggingTarget } from "./TWrapper";
import usePrevious from "../utils/hooks/usePrevious";
import { TBodyCellId } from "../utils/constants";
import { Either, IEither } from "../utils/either";

export const _EVENTS_START = Date.now().toString(36) + Math.random().toString(36).substring(2);

type TCellDataProps<T extends (...args: any) => any> = Parameters<NonNullable<T>>[0];
type _TEventsPropsUpdated<T> = TEventsWrapperProps<T> & TDraggingContextType;

export const TBodyEventsWrapper = <T extends {}>(props: TEventsWrapperProps<T>) => {

    return (
        <TDraggingContext.Consumer>
            {(draggingInfo) => <_TBodyEventsWrapper {...props} isDragging={draggingInfo.isDragging} draggingTarget={draggingInfo.draggingTarget} />}
        </TDraggingContext.Consumer>
    );
};


const _TBodyEventsWrapper = <T extends {}>(props: _TEventsPropsUpdated<T>) => {
    const { columns, child, cellBuilder, rows, isDragging, draggingTarget, transposition, onStartDrag } = props;

    const _tCellDataMap = useMemo(() => {
        const map: Map<string, TCellDataProps<typeof cellBuilder>> = new Map();
        for (const row of rows) {
            for (const cell of row.data) { 
                map.set(cell.id, cell.data);
            }
        }
        return map;
    }, [columns, rows]);

    let startCellRef = useRef<EventTarget | null>(null);

    const _getElement = (eventTarget: EventTarget | null): IEither<{ error: string }, { id: string, [key: string]: any }> => {
        //@ts-ignore
        const isEventElement = (target) => target?.nodeName == 'TD' && target?.id.includes(TBodyCellId);

        let target = eventTarget;

        while (true) {
            if (isEventElement(target)) break;
            try {
                //@ts-ignore
                target = target.parentElement;
            }
            catch (e) {
                return Either.left({ error: 'Can not find an element with nessery value' });
            }
        }
        // @ts-ignore
        return Either.right({ id: target.id.split('/').splice(1, 2).join('/'), ...target });
        //TBodyCellId/2024-02-21T16:00:00+04:00/1
    };

    const gBind = useGesture({
        onClick: (state) => {
            if (isDragging) return;
            const { event } = state;

            const targetEither = _getElement(event.target);

            if (targetEither.isLeft()) return state;
            const target = targetEither.getRight((_) => ({ id: '' }));

            const cellData = _tCellDataMap.get(target.id);
            if (cellData != undefined) props.onCellClick?.call(this, { id: target?.id ?? '', data: cellData });

            return state;
        },
        onMove: (state) => {
            const { event, memo, offset: [offsetX, offsetY] } = state;
            const memoState = memo as typeof state & { isDragging?: boolean };
            const currTargetEither = _getElement(event.target);
            if (currTargetEither.isLeft()) return null;

            let currTarget = currTargetEither.getRight((_) => ({ id: '' }));

            if (memoState == null) {
                props.onCursorMoving?.call(this, null, { id: currTarget.id, data: _tCellDataMap.get(currTarget.id) ?? null });
            }
            else {
                const prevTargetEither = _getElement(memoState.event.target);

                if (prevTargetEither.isLeft()) return null;
                const prevTarget = prevTargetEither.getRight((_) => ({ id: '' }));

                if (prevTarget.id != currTarget.id)
                    props.onCursorMoving?.call(
                        this,
                        { id: prevTarget.id, data: _tCellDataMap.get(prevTarget.id) ?? null },
                        { id: currTarget.id, data: _tCellDataMap.get(currTarget.id) ?? null }
                    );
            }
            return ({
                ...state,
                isDragging: isDragging
            });
        },

        onDrag: (state) => {
            const { initial: [initilaX, initialY], movement, xy } = state
            if (draggingTarget == 'table') return;
            if (!startCellRef.current || state.first) {
                startCellRef.current = state.target;
                const startTargetEither = _getElement(startCellRef.current);
                const startTarget = startTargetEither.getRight((_) => ({ id: '' }));
                onStartDrag?.call(this, { id: startTarget.id, data: _tCellDataMap.get(startTarget.id) ?? null }, initilaX, initialY);
            }

            const startTargetEither = _getElement(startCellRef.current);
            // if (startTargetEither.isLeft()) return null;
            const startTarget = startTargetEither.getRight((_) => ({ id: '' }));
            const currTargetEither = _getElement(document.elementFromPoint(xy[0], xy[1]));
            if (currTargetEither.isLeft()) return null;
            const currTarget = currTargetEither.getRight(_ => ({ id: '' }));
            props.onDragging?.call(
                this,
                { id: startTarget.id, data: _tCellDataMap.get(startTarget.id) ?? null },
                { id: currTarget.id, data: _tCellDataMap.get(currTarget.id) ?? null },
                movement[0], // offsetX, 
                movement[1] // offsetY
            );
            if (state.last) {
                const startTargetEither = _getElement(startCellRef.current);
                console.log('REF START CELL - ', startCellRef.current);
                if (startTargetEither.isLeft()) return null;
                // console.log('DRAG END');
                let startTarget = startTargetEither.getRight((_) => ({ id: '' }));
                if (startTarget.id != currTarget.id) {
                    props.onCellDrag?.call(
                        this,
                        { id: startTarget.id, data: _tCellDataMap.get(startTarget.id) ?? null },
                        { id: currTarget.id, data: _tCellDataMap.get(currTarget.id) ?? null }
                    );
                }
                startCellRef.current = null;
            }

        },
    });



    // const bind = (...args: any[]) => ({...gBind(), ...mBind() });


    return props.child({ ...props, bind: gBind, rows: rows });
};