import { Input } from "antd"
import DefaultController from "../../../../common/presentation/DefaultController"
import { Control, UseFormSetValue } from "react-hook-form";
import { IHookFormOrder } from "../../domain/entities/IHokFormOrder";
import { useEffect } from "react";

import profile from '../../../../assets/profile.svg'

export const EmployeeNameInput: React.FC<{ value?: string, setValue: UseFormSetValue<IHookFormOrder>, control: Control<IHookFormOrder> }> = ({ value, setValue, control }) => {
    // console.log(value);

    useEffect(() => {
        if (value !== '1') setValue('employeeName', value);
        if (value === '0') setValue('employeeName', '');
    }, [value])


    return <DefaultController
        control={control}
        name={'employeeName'}
        rules={
            {
                required: false,
            }
        }
        render={({ field }) => (
            <>
                <Input
                    {...field}
                    value={field.value as string}
                    className="default-input"
                    placeholder="Сотрудник"
                    bordered={false}
                    prefix={<img src={profile} />}
                />
            </>
        )}
    />
}
