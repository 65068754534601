import { useMemo } from "react";
import { BookingCell } from "../../domain/entities/BookingCell";
import ColorPool from "../../../../common/utility-types/utils/ColorPool";
import { IBookingCell } from "../../domain/entities/IBookingCell";
import { observer } from "mobx-react";
import { Spin } from "antd";
import BookedCell from "./BookedCell";
import { CellContentType } from '../../domain/entities/IBookingCell';
import Loader from "../../../../common/presentation/Loader";
import { container } from "../../../../di/container";
import { DashboardViewModel } from "../view-models/dashboardViewModel";

import './cellBorder.css';

export const DEFAULT_CELL_CLASSNAME = 'table__cell';

type CellProps = IBookingCell & { cellStyle: CellContentType, orderTitle: string | null, borderClasses: string };

const Cell: React.FC<CellProps> = observer(({
    booking,
    isEditing,
    isLoading,
    bookingPossability: isPossibleToBooking,
    cellStyle,
    orderTitle,
    isDraggingTarget,
    vipRoomBooking,
    borderClasses
}) => {    
    const className = useMemo(() => {
        let className = `${DEFAULT_CELL_CLASSNAME} ${borderClasses}`;
        switch (isPossibleToBooking) {
            case 'unavailable':
                className = `${DEFAULT_CELL_CLASSNAME} unselectable--idle ${borderClasses}`;
                break;
            case 'selectable':
                className = `${DEFAULT_CELL_CLASSNAME} selectable`;
                break;
        }
        if(isDraggingTarget) return `${className} dragging`;
        return className;
    }, [isPossibleToBooking, isDraggingTarget]);

    const bookedCellStyle = useMemo((): React.CSSProperties | undefined => {
        if (booking == null) return undefined;

        // if (cellView == 'empty' || cellView == 'start-creating' || cellView == 'loading') return null;
        // return timelineConfiguration.borderColorBuilder(pivots[0]);
        const color = ColorPool.instance.getColor(Array.isArray(booking)
            ? booking[0].orderId ?? 0
            : booking.orderId ?? 0
        );
        return {
            border: `5px solid ${color}`,
        };
    }, [booking]);
    

    const contentBuilder = () => {
        if (isLoading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Loader color={'#4474e9'} size={40} />
        </div>;
        if (booking == null) return null;
        const bookings = Array.isArray(booking) ? booking : [booking];
        return bookings
            .map((booking, index) =>
                <BookedCell
                    key={booking.id ?? index}
                    orderTitle={orderTitle}
                    booking={booking}
                    color={ColorPool.instance.getColor(booking.gameTypeId ?? -1)}
                    isEditing={isEditing}
                    cellStyle={cellStyle}
                    vipRoomBooking={vipRoomBooking ? vipRoomBooking : []}
                />
            );

    };

    return (
        <div className={className} style={bookedCellStyle}>
            <div className='cell__container booked-item' >
                {contentBuilder()}
            </div>
        </div>
    );
});

export default Cell;