import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import DefaultController from '../../../../common/presentation/DefaultController';
import GUEST_ICON from '../../../../assets/person.svg';
import { IHookFormOrder } from '../../domain/entities/IHokFormOrder';
import { TextFormater } from '../../../../common/hook-form-tools/textFormater';
import { IBooking } from '../../domain/entities/IBooking';

interface GuestCountControllerProps {
    control: Control<IHookFormOrder, any>,
    bookingIndex: number | string,
    booking?: IBooking,
    remainingGlassesCount: number,
    setValue: UseFormSetValue<IHookFormOrder>,
    defaultValue?: number,
    allowIncrease?: boolean
}

const GuestCountController: React.FC<GuestCountControllerProps> = ({ control, bookingIndex, remainingGlassesCount, defaultValue, booking, setValue, allowIncrease }) => {

    const validateGuestCount = (value: number | string | undefined): boolean | string => {
        const mValue = (value ?? 0);
        const currentGuestCount = (defaultValue ? defaultValue : booking?.guestCount) ?? 0;

        // console.log('REMAINING GLASSES COUNT - ', remainingGlassesCount);
        // if (!allowIncrease && +mValue > currentGuestCount) {
        //     return 'Увеличение количества гостей запрещено';
        // }

        const usesGlasses = remainingGlassesCount - (typeof mValue == 'string' ? Number.parseInt(mValue) : mValue);
        if (usesGlasses >= 0) return true;
        return `Количество затраченных очков превышает существующее на ${Math.abs(+usesGlasses)}`;
    };

    useEffect(() => {
        if (!booking?.guestCount && defaultValue) setValue(`bookings.${bookingIndex}.guestCount`, defaultValue);
    }, []);

    // useEffect(() => {
    //     console.log('-------REMAINING GLASSES COUNT - ', remainingGlassesCount);

    // }, [remainingGlassesCount])

    const onChangeGuestCount =
        (count:  (...event: any[]) => void) => {
        // (onChangeCallback: (value: number) => void) => (value: number) => {
            // onChangeCallback(value, option);

            console.log(count());
            
            // setValue(`bookings.${bookingIndex}.guestCount`, count);
        };

    return <DefaultController
        name={`bookings.${bookingIndex}.guestCount`}
        control={control}
        // warn={[
        //     {
        //         validator: ValidatorHelper.buildNumberAmbitValidator(room?.guest_max ?? 1, 1),
        //         message: `Рекомендованное кол-во человек от ${1} до ${room?.guest_max ?? 1}`
        //     }
        // ]}
        rules={{
            required: { value: true, message: 'Это поля обязательно' },
            pattern: { value: new RegExp(/^[0-9]+$/), message: 'Значение не является числом' },
            validate: validateGuestCount
        }}
        render={({ field }) => (
            <Input
                {...field}
                value={(field.value)?.toString()}
                // value={currentValue.toString()}
                // onChange={() => onChangeGuestCount(field.onChange)}
                bordered={false}
                prefix={<img src={GUEST_ICON} />}
                className='default-input' />
        )}
    />;
};

export default GuestCountController;