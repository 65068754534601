import { IReactionDisposer, action, computed, makeAutoObservable, observable, onBecomeUnobserved, reaction } from "mobx";
import { IFilialService } from "./IFilialService";
import { ICity } from "../../../../api/models/Login";
import { Game } from "../entities/Game";
import { GameType } from "../entities/GameType";
import { Room } from "../entities/Room";
import { GameTypesDataSourse } from "../../data/GameTypesDataSourse";
import { container } from "../../../../di/container";
import { GameDataSourse } from "../../data/GamesDataSourse";
import { RoomsDataSourse } from "../../data/RoomsDataSourse";
import { injectable } from "inversify";
import { ALL_SELECT } from "../../../../@types/baseCollection";
import { OpenAPI } from "../../../../api";

@injectable()
export class FilialService implements IFilialService {
    @observable
    selectedCity: ICity | null = null;

    private _disposers: IReactionDisposer[] = [];

    constructor(
        private gameDataSourse: GameDataSourse = container.get(GameDataSourse),
        private gameTypeDataSourse: GameTypesDataSourse = container.get(GameTypesDataSourse),
        private roomsDataSourse: RoomsDataSourse = container.get(RoomsDataSourse)
    ) {
        makeAutoObservable(this);
        this._changeFilialReaction();
        onBecomeUnobserved(this, 'selectedCity', () => this._disposers.forEach(d => d()));
    }
    
    static initial() {
        return new FilialService();
    }

    @computed
    get getSelectedCity() {
        return this.selectedCity;
    }

    @computed
    get games(): Game[] {
        return this.gameDataSourse.where(ALL_SELECT);
    }

    @computed
    get gameTypes(): GameType[] {
        return this.gameTypeDataSourse.where(ALL_SELECT);
    }

    @computed
    get rooms(): Room[] {
        return this.roomsDataSourse.where(ALL_SELECT);
    }

    get gamesPromise()  {
        return this.gameDataSourse.gamesPromise ?? this.gameDataSourse.load(this.selectedCity?.gameServerUserId ?? -1);
    }
    

    @action
    setCity(this: FilialService, city: ICity | null): void {
        OpenAPI.BASE = `https://${city?.code.toLowerCase()}.vrbook.creatrix-digital.ru`;
        this.selectedCity = city;
    }

    @action
    private _changeFilialReaction(this: FilialService): void {
        const disposer = reaction(() => this.selectedCity, (curr) => {
            if (curr == null) {
                this.gameDataSourse.deleteMany(this.gameDataSourse.where(ALL_SELECT));
                this.gameTypeDataSourse.deleteMany(this.gameTypeDataSourse.where(ALL_SELECT));
                this.roomsDataSourse.deleteMany(this.roomsDataSourse.where(ALL_SELECT));
            }
            else {
                this.gameDataSourse.load(this.selectedCity?.gameServerUserId ?? -1);
                this.gameTypeDataSourse.load();
                this.roomsDataSourse.load();
            }
        });
        this._disposers.push(disposer);
    }

}

// export const filial = Filial.initial();