import { Button, Popover, PopoverProps, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { IOrder } from '../domain/entities/IOrder';
import { LoaderGipno } from '../../../common/presentation/gipno-ui-kit/loader/loader-components';
import { OrderDetailViewModel } from './view-models/orderDetailViewModel';
import { Observer, observer } from 'mobx-react-lite';
import { ConnectOrder } from './providers/OrderProvider';
import { container } from '../../../di/container';
import { TimelinePageViewModel } from '../../timeline/ui/view-models/timelinePageViewModel';
import { OrdersRepository } from '../domain/repository/OrdersRepository';

import client from '../../../assets/client.svg';
import admin from '../../../assets/admin.svg';
import done from '../../../assets/done.svg';
import cancel from '../../../assets/cancel.svg';
import message from '../../../assets/message.svg';

type BookingPopupProps = Omit<PopoverProps
    & React.RefAttributes<unknown>
    & { orderId: string, color: string, isPopoverVisible: boolean, setPopoverVisible: (v: boolean) => void }
    , 'content'>;

interface RowInfrormationProps {
    iconSrc: string,
    title: string,
    additionalInfo?: string
}

const BookingPopover: React.FC<BookingPopupProps> = observer((props) => {

    const orderViewModel = new OrderDetailViewModel(props.orderId);

    const onVisible = () => {
        orderViewModel.loadOrderDetail();
    };

    const onOpenChange = (v: boolean) => {
        if (v) onVisible();
        props.setPopoverVisible(v);
    };

    useEffect(() => {
        if (props.isPopoverVisible) {
            onVisible();
        }
    }, [props.isPopoverVisible]);

    const orderRepo = container.get(OrdersRepository);

    const orderByRepo = orderRepo.getOrderById(props.orderId) as IOrder;

    return (
        <Popover
            style={{ padding: '300px' }}
            onOpenChange={onOpenChange}
            overlayInnerStyle={{ border: `1px solid ${props.color}` }}
            overlayClassName='smallerPopover'
            showArrow={false}
            trigger="click"
            open={props.isPopoverVisible}
            placement='rightBottom'
            content={<Observer>
                {() =>
                    !orderViewModel.isLoaded ? (
                        <LoaderGipno type="circle" width={100} value={0} height={100} innerText={null} />
                    ) : (
                        <BookingPopoverContent order={orderViewModel.order} close={props.setPopoverVisible} />
                    )
                }
                {/* {
                    () => !orderViewModel.order?.id
                        ? <BookingPopoverContent order={orderViewModel.order} close={props.setPopoverVisible} />
                        : <LoaderGipno type='circle' width={100} value={0} height={100} innerText={null} />
                } */}
            </Observer>
            }>
            {props.children}
        </Popover >
    );
});

const BookingPopoverContent: React.FC<{ order: IOrder | null, close: (v: boolean) => void }> = ({ order, close }) => {

    // const timelinePresenter = useSelector(state => state.timelineStore);
    const TimelinePageVM = container.get(TimelinePageViewModel);

    console.log('INCOMING order - ', order);

    const buildOnClick = (setOrder: (order: IOrder | null) => void) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setOrder(order);

        TimelinePageVM.setIsOpenSider(true);
        // change(OrderMapper.toOrderForm(order));

        // timelinePresenter.setIsOpenSider(true);

        // const headers = document.querySelectorAll('.cell__header');
        // const table = document.getElementsByTagName('table')[0];

        // for (const div of headers) {
        //     console.log(div.children[0]?.textContent?.includes(order?.id.substring(0, 4)!));

        //     if (div.children[0]?.textContent?.includes(order?.id.substring(0, 4)!)) {
        //         console.log('--------------------------------------');
        //         console.log(div.children[0]);
        //         div.scrollIntoView({ behavior: 'smooth', block: 'center' });

        //         centerInParent(table, div);

        //         break;
        //     }
        // }
    };

    function centerInParent(parent: HTMLTableElement, child: Element) {
        let parentRect = parent.getBoundingClientRect();
        let childRect = child.getBoundingClientRect();

        parent.scrollLeft += (childRect.left - parentRect.left) - (parent.clientWidth / 2);
        parent.scrollTop += (childRect.top - parentRect.top) - (parent.clientHeight / 2);
    }


    const timeInterval = useMemo(
        () => order?.bookings
            .toSorted((prev, next) => prev.bookingDate?.diff(next.bookingDate) ?? -1)
            .map((b, _) => b.bookingDate?.format('HH:mm') ?? 'Не указано')
            .join(', '),
        [order?.bookings]
    );


    const orderGuestCount = useMemo(
        () => {
            const guestsTimeMap = new Map<string, number>();
            if (!order) return 'Неизвестно';
            for (const b of order.bookings) {
                if (b.bookingDate && b.guestCount) {
                    const timeKey = b.bookingDate.format('DD.MM.YYYY HH:mm');
                    guestsTimeMap.set(timeKey, (guestsTimeMap.get(timeKey) ?? 0) + b.guestCount);
                }
            }
            return Array.from(new Set(guestsTimeMap.values())).join(', ');
        },
        [order?.bookings]
    );

    const games = useMemo(
        () => Array
            .from(new Set(order?.bookings.map(b => b.game))
                .values())
            .map(game => game ? game?.title : 'Не указано')
            .join(', '),
        [order?.bookings]
    );

    console.log(order);

    const formatPhoneNumber = (input: string) => {
        const cleaned = ('' + input).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
        }

        return input;
    };


    // const rooms = useMemo<string>(() => RoomMapper.roomsToPopoverView(globalRooms, order.bookings), [order.id, order.bookings]);

    return (
        <>
            <section>
                <h2 className='popover__title'>{order?.title}</h2>
                <div style={{ position: 'absolute', top: '12px', right: '25px', cursor: 'pointer', color: '#999', fontSize: '18px' }} onClick={() => close(false)}>
                    &#10006;
                </div>
            </section>
            <section className='popover__section'>
                <div className="popover__icon">
                    <img src={client} alt="client" />
                </div>
                <div className="popover__info">
                    <div className='popover__info_title' style={{ marginBottom: '3px' }}>Клиент</div>
                    <div className='popover__info_text'>{order?.client?.name}</div>
                    <div className='popover__info_text'>{order?.client?.phone}</div>
                </div>
            </section>
            <section className='popover__section'>
                <div className="popover__icon">
                    <img src={admin} alt="admin" />
                </div>
                <div className="popover__info">
                    <div className='popover__info_title'>Админ</div>
                    <div className='popover__info_text'>{order?.personal.name}</div>
                </div>
            </section>
            <section className='popover__section'>
                <div className={`popover__icon ${order?.isSendMessage ? 'send' : 'cancel'}`}>
                    <img src={order?.isSendMessage ? done : cancel} alt="sms" />
                </div>
                <div className="popover__info">
                    <div className='popover__info_title'>СМС</div>
                    <div className='popover__info_text'>{order?.isSendMessage ? 'Смс отправлено' : 'Смс не отправлено'}</div>
                </div>
            </section>
            {order?.comment &&
                <section className='popover__section'>
                    <div className="popover__icon">
                        <img src={message} alt="message" />
                    </div>
                    <div className="popover__info">
                        <div className='popover__info_title'>Комментарий</div>
                        <div className='popover__info_text'>{order?.comment ?? 'Не указано'}</div>
                    </div>
                </section>
            }
            <Row align={'stretch'}>
                <ConnectOrder>
                    {({ setOrder }) => <Button className='default-btn-grey' style={{ width: '100%' }} onClick={buildOnClick(setOrder)}>Редактировать</Button>}
                </ConnectOrder>
            </Row>
        </>
    );
};

export default BookingPopover;

