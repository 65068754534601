import styled from "styled-components";
import { ILoungeZone } from "../domain/ILoungeZone"
import { LoungeZoneView } from "./LoungeZoneView";

type LoungeZoneListProps = {
    loungeZones: ILoungeZone[],
    isTransposed: boolean,
    isFixed: boolean
}

export const LoungeZoneList: React.FC<LoungeZoneListProps> = ({ loungeZones, isTransposed, isFixed }) => {

    return (
        <StyledWrapper isTransposed={isTransposed} isFixed={isFixed}>
            {loungeZones.map(zone => <div className="vipCells" style={{ alignContent: 'center' }} key={zone.id} ><LoungeZoneView loungeZone={zone} /></div>)}
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div<{ isTransposed: boolean, isFixed: boolean }>`
    display: flex;
    width: ${({ isTransposed, isFixed }) => isTransposed && !isFixed ? '200px !important' : '100%'};
    /* width: 100%;
    ${({ isTransposed, isFixed }) => isTransposed && !isFixed && `
        width: 200px !important;
    ` || (isTransposed && isFixed && `width: 160px !important`) || `width: 100%`}    
    height: 100%; */

    div {
        width: 80%;
        height: 100% !important;
    }

    .vipCells {
        box-shadow: -1px 0px 0px 0px rgb(221, 226, 233);

        display: flex;
        justify-content: center;
        align-items: center;

        .lounge-view {
            ${({ isFixed, isTransposed }) => (isFixed && isTransposed && `
                margin: 0px !important;
                padding: 2px !important;
                gap: 3px !important;
            `)
        || (isFixed && `
                margin: 3px !important;
                padding: 2px !important;
                gap: 1px !important;
            `)}
            /* margin: ${({ isFixed }) => isFixed ? '3px !important' : '7px'};
            padding: ${({ isFixed }) => isFixed ? '2px !important' : '7px'};
            gap: ${({ isFixed }) => isFixed ? '1px !important' : '4px'}; */

            svg {
                ${({ isFixed }) => isFixed && `
                    width: 16px !important;
                `}
                /* width: ${({ isFixed }) => isFixed ? '16px !important' : '20px'}; */
            }
        }
    }

    .vipCells:nth-child(1n) {
        outline: none;
    }
`