import { Container } from "inversify";
import { BaseCollection } from "../@types/baseCollection";
import { GameDataSourse } from "../features/fillial/data/GamesDataSourse";
import { GameTypesDataSourse } from "../features/fillial/data/GameTypesDataSourse";
import { FilialService } from "../features/fillial/domain/services/FilialService";
import { RoomsDataSourse } from "../features/fillial/data/RoomsDataSourse";
import { TimelineSettingsRepository } from "../features/timeline/domain/entities/TimelineSettingsRepository";
import { OrderRepository } from "../features/booking/data/OrderRepository";
import { OrderService } from "../features/booking/domain/services/OrderService";
import OrderSocketService from "../common/services/OrderSocketService";
import { Credential } from "../features/authentication/domain/entities/Credential";
import { OrdersRepository } from "../features/booking/domain/repository/OrdersRepository";
import { DashboardViewModel } from "../features/timeline/ui/view-models/dashboardViewModel";
import { TimelinePageViewModel } from "../features/timeline/ui/view-models/timelinePageViewModel";
import { TaskRepository } from "../features/tasks/data/TaskRepository";
import { TimelineColumn } from "../features/timeline/domain/entities/TimelineColumn";
import { Timeline } from "../features/timeline/domain/entities/Timeline";

export const container = new Container();

export const setup = () => {
    container.bind(BaseCollection).toSelf();
    container.bind(Credential).toSelf().inSingletonScope();
    // -----Филиал--------
    container.bind(FilialService).toSelf().inSingletonScope();
    container.bind(GameTypesDataSourse).toSelf().inSingletonScope();
    container.bind(RoomsDataSourse).toSelf().inSingletonScope();
    container.bind(GameDataSourse).toSelf().inSingletonScope();
    // -----Бронирование ------
    container.bind(OrderRepository).toSelf();
    container.bind(OrderService).toSelf().inSingletonScope();
    container.bind(OrderSocketService).toSelf().inSingletonScope();
    container.bind(OrdersRepository).toSelf().inSingletonScope();

    container.bind(DashboardViewModel).toSelf().inSingletonScope();

    // -----Сетка --------
    container.bind(TimelineSettingsRepository).toSelf().inSingletonScope();

    // ----Задачи------
    container.bind(TaskRepository).toSelf().inSingletonScope();
    //VM
    container.bind(TimelinePageViewModel).toSelf().inSingletonScope();


    container.bind(Timeline).toSelf().inSingletonScope();
};


