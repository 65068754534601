import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface DefaultSpinProps {
    size?: number
}

const DEFAULT_SPIN_SIZE = 24;

const DefaultSpin: React.FC<DefaultSpinProps> = (props) => {

    const {size} = props;

    const antIcon = <LoadingOutlined style={{ fontSize: size ?? DEFAULT_SPIN_SIZE }} spin />;

    return (
        <Spin indicator={antIcon}/>
    )
}

export default DefaultSpin;

