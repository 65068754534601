import { action, computed, makeObservable, observable } from "mobx";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { TimelineInfo } from "../../../../api/models/OrderForDayResponse";
import { EOrderStatus } from "../../../booking/domain/entities/EOrderStatus";
import { filterNullable } from "../../../../common/utility-types/helpers/filterNullable";
import { Without } from "../../../../@types/without";
import { Game } from "../../../fillial/domain/entities/Game";
import { Order } from "../../../booking/domain/entities/Order";
import { container } from "../../../../di/container";
import { OrderService } from "../../../booking/domain/services/OrderService";
import { IBooking } from "../../../booking/domain/entities/IBooking";
import { IOrderResponse } from "../../../booking/domain/entities/IOrderResponse";
import { BookingDto } from "../../../../api/models/Booking";
import moment from "moment";
import { GameDataSourse } from "../../../fillial/data/GamesDataSourse";

export type TabledOrder = {
    id: string,
    time: string,
    // adminCode?: number,
    adminName: string,
    games: Game[] | null,
    clientName: string,
    phone: string,
    isMessaged: boolean,
    guestCount: string,
    comment: string,
    status: EOrderStatus,
    price?: number
};

export type TabledBooking = {
    id: string,
    time: string,
    game: Game | null,
    guestCount: number,
    orderId: string
};

export class TableViewModel {

    @observable
    private _orders: Without<IOrder, Function>[] = [];
    private _orderService = container.get(OrderService);
    private _gameDataSourse = container.get(GameDataSourse);

    constructor() {
        makeObservable(this);
    }

    @computed
    get editingOrder() {
        return this._orders.find(o => o.bookings.some(b => b.isEditing)) ?? null;
    }

    @computed
    get tOrders(): TabledOrder[] {


        const getGuestsByTime = (order: Without<IOrder, Function>) => {
            const guestsTimeMap = new Map<string, number>();
            for (const b of order.bookings) {
                if (b.bookingDate && b.guestCount) {
                    const timeKey = b.bookingDate.format('DD.MM.YYYY HH:mm');
                    guestsTimeMap.set(timeKey, (guestsTimeMap.get(timeKey) ?? 0) + b.guestCount);
                }
            }
            return Array.from(guestsTimeMap.values());
        };

        const newOrder = this._orders.map(order => ({
            id: order.id,
            time: order.bookings.toSorted((prev, curr) => prev.bookingDate?.diff(curr.bookingDate) ?? 0).at(0)?.bookingDate?.format('HH:mm') ?? '',
            clientName: order.client?.name ?? '',
            phone: order.client?.phone ?? '',
            status: order.status,
            isMessaged: order.isSendMessage,
            games: Array.from(new Set(filterNullable(order.bookings.map(b => b.game ?? null))).values()),
            // guestCount: Array.from(new Set(getGuestsByTime(order))).join(', '),  
            guestCount: filterNullable<Without<IBooking, Function>>(order.bookings).reduce((acc, b) => {
                return acc += (b.guestCount ?? 0);
            }, 0).toString(),
            // price: order.priceInfo?.price,
            price: eval(order.paymentInfo?.priceFormula ?? `${order.priceInfo?.price}`),
            comment: order.comment ?? '',
            adminName: order.personal?.name ?? ''
        }));
        console.log(newOrder);


        return newOrder;
    }

    @computed
    get tBookings(): TabledBooking[][] {
        return this._orders.map(order => order.bookings.map<TabledBooking>(b => ({
            id: b.id,
            game: b.game,
            guestCount: b.guestCount ?? 0,
            time: b.bookingDate?.format('HH:mm') ?? 'Не указано',
            orderId: order.id
        })));
    }

    buildOnEdit = (tOrder: TabledOrder, handleEdit: (orderStorage: (order: IOrder) => IOrder | null) => void) => () => {
        const fOrder = this._orders.find(o => o.id == tOrder.id);

        this._orderService.setEditableOrder(fOrder as IOrder);

        handleEdit((_) => fOrder
            ? new Order(fOrder)
            : null
        );
    };

    @action
    updateOrders(this: TableViewModel, orders: Without<IOrder, Function>[]) {
        this._orders = orders;
    }

}