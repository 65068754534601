import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface UseTimerArgs {
    onExpire: () => void,
    secondsToExpire: number,
}

const useTimer = (args: UseTimerArgs) => {

    const { onExpire, secondsToExpire } = args;

    const secondsToExpireMemo = useMemo(() => secondsToExpire, [secondsToExpire]);
    const [timeExpired, setTimeExpired] = useState(0);

    let timeExpiredRef = useRef<number>(timeExpired);

    useEffect(
        () => {
            const intervalId = setInterval(() => {
                if (timeExpiredRef.current == secondsToExpire) {
                    clearInterval(intervalId);
                    onExpire();
                }
                else {
                    timeExpiredRef.current++;
                    setTimeExpired(timeExpiredRef.current);
                }
            }, 1000);
            return () =>  clearInterval(intervalId);
        }, []
    );

    return secondsToExpireMemo - timeExpired;
}

export default useTimer;