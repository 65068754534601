import { Left } from "./left";
import { Right } from "./right";

type IEitherMatchArgs<Left, Right, C> = {
    onLeft: (left: Left) => C,
    onRight: (r: Right) => C
}

export interface IEither<Left, Right> {
    match<C>(args: IEitherMatchArgs<Left, Right, C>): C;
    map<C>(mapCallback: (r: Right) => C): IEither<Left, C>
    getRight(orElse: (l: Left) => Right): Right;
    getLeft(orElse: (r: Right) => Left): Left;
    isLeft(): boolean,
    isRight(): boolean
}

export class Either<Left, Right> implements IEither<Left, Right> {
    map<C>(mapCallback: (r: Right) => C): IEither<Left, C> {
        return this.map(mapCallback);
    }
 
    static left<TLeft, TRight>(l: TLeft): IEither<TLeft, TRight> {
        return new Left<TLeft, TRight>(l);
    }

    static right<TLeft, TRight>(r: TRight): IEither<TLeft, TRight> {
        return new Right<TLeft, TRight>(r);
    }

    match = <C>(args: IEitherMatchArgs<Left, Right, C>): C => this.match(args);
    getRight = (orElse: (l: Left) => Right): Right => this.getRight(orElse);
    getLeft = (orElse: (r: Right) => Left): Left => this.getLeft(orElse);
    isLeft = (): boolean =>  this.isLeft();
    isRight = (): boolean =>  this.isRight();
}