import { Button, Form, FormListFieldData, Input, InputNumber, Row, Select } from "antd";
import REMOVE_ICON from '../../../../assets/remove.svg';
import GUEST_ICON from '../../../../assets/person.svg';
import TIME_ICON from '../../../../assets/clock.svg';

import { useCallback, useEffect, useMemo } from "react";
// import RoomMapper from "../../../../common/mappers/RoomMapper";
import DefaultController from "../../../../common/presentation/DefaultController";
import { Control, FieldArray, FieldArrayPath, FieldError, FieldErrors, FieldValues, FormState, RegisterOptions, UseFormRegisterReturn, useFieldArray, useFormContext, useWatch } from "react-hook-form";
// import { emptyRoomsSelector } from "../redux/selectors";
import GameController from "./GameController";
import GuestCountController from "./GuestCountController";
import { IHookFormBooking, IHookFormOrder } from "../../domain/entities/IHokFormOrder";
import moment from "moment";
import { Observer } from "mobx-react-lite";
import { container } from "../../../../di/container";
import { ALL_SELECT } from "../../../../@types/baseCollection";
import ColorPool from "../../../../common/utility-types/utils/ColorPool";
import { TimelineConnect } from "../../../timeline/ui/providers/TimelineConnect";


import { FilialViewModal } from "../../../fillial/ui/view-models/FilialViewModel";
import { SmartGuestCountController } from "./SmartGuestCountController";
import { LabeledValue } from "antd/lib/select";
import { ConnectOrder } from "../providers/OrderProvider";
import { SmartGameController } from "./SmartGameController";
import { SmartGameTypesController } from "./SmartGameTypeController";

interface BookingFormProps {
    orderId: number | string,
    index: number,
    remove: () => void,
    bookings: IHookFormBooking[],
    color: string,
}



const BookingForm: React.FC<BookingFormProps> = ({ orderId, index, bookings, color, remove }) => {
    // const allRooms = rooms.getFilialData.roomCollection;


    // const roomsOptions = useMemo(() => RoomMapper.roomToValues(allRooms), [allRooms])

    const getColor = useCallback((gameTypeId: number | undefined) => ColorPool.instance.getColor(gameTypeId?.toString() ?? '-1'), []);

    // useEffect(() => {
    //     trigger(`bookings.${index}.${GAME_PATH}`);
    // }, [booking[ROOM_PATH]])

    const convertAvailableTimeToOptions = useCallback(
        (availableTimes: moment.Moment[]) => availableTimes.map<LabeledValue>(t => ({
            value: t.format(moment.defaultFormat),
            label: t.format('HH:mm')
        })),
        []
    );

    const buildOnChangeTime = (updateBookingTimeCallback: (value: string) => void) => (value: LabeledValue | LabeledValue[], option: LabeledValue | LabeledValue[]) => {
        if (Array.isArray(value)) return;
        updateBookingTimeCallback(value.value.toString());
    };

    const buildValidateBookingDate = (availableTimes: moment.Moment[]) => (time: string) => {
        return availableTimes.find(aTime => aTime.isSame(moment(time), 'hour')) != null || 'Нельзя бронировать на прошлое';
    };

    return (
        <div className="booking-form">
            <ConnectOrder>
                {({ order }) =>
                    <div style={{ backgroundColor: getColor(order?.bookings.at(index)?.gameTypeId ?? -1) }} className="booking-form__header">
                        <Row justify='space-between' align='middle' style={{ height: '100%' }}>
                            <div>Заказ {orderId.toString().substring(0, 4)}</div>
                            <div style={{ cursor: 'pointer' }} onClick={remove}>
                                <img src={REMOVE_ICON} />
                            </div>
                        </Row>
                    </div>
                }
            </ConnectOrder>

            <div className="booking-form__wrapper">
                <SmartGameTypesController bookingId={bookings.at(index)?.id ?? -1} />
                <SmartGameController bookingId={(bookings.at(index)?.id ?? -1).toString()} />
                <SmartGuestCountController bookingId={bookings.at(index)?.id ?? -1} />
                <TimelineConnect >
                    {({ availableTimes }) => <ConnectOrder>
                        {({ control, setValue }) => <DefaultController
                            name={`bookings.${index}.bookingDate`}
                            control={control}
                            rules={
                                { validate: buildValidateBookingDate(availableTimes), }
                            }
                            render={({ field }) => {
                                console.log();
                                return (
                                    <Select
                                        className='default-select'
                                        {...field}
                                        labelInValue
                                        disabled
                                        value={{ label: moment(field.value).format('HH:mm'), value: field.value }}
                                        options={convertAvailableTimeToOptions(availableTimes)}
                                        onChange={buildOnChangeTime((v) => setValue(`bookings.${index}.bookingDate`, v))}
                                        style={{ height: '40px', width: '100%' }}
                                        placeholder='Время'
                                        popupClassName={'first-plan-object'}
                                    />
                                    // <Input
                                    //     {...field}
                                    //     value={moment(field.value).format('HH:mm')}
                                    //     disabled
                                    //     bordered={false}
                                    //     prefix={<img src={TIME_ICON} />}
                                    //     className='default-input' />
                                )
                            }}
                        />}
                    </ConnectOrder>}
                </TimelineConnect>

                {/* )}
                </TimelineConnect> */}
            </div>
        </div>
    );
};

export default BookingForm;