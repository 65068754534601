import { Stream } from "stream";
import { TransformStreamDefaultController } from "stream/web";
import { FullOrder } from "../../api";
import { IOrderSocketService } from "./IOrderSocketService";
import { OrderAdapter } from "../../features/booking/adapters/OrderAdapter";
import { ESourceStatus, IOrder } from "../../features/booking/domain/entities/IOrder";
import { injectable } from "inversify";
import { container } from "../../di/container";
import { FilialService } from "../../features/fillial/domain/services/FilialService";
import { OrdersRepository } from "../../features/booking/domain/repository/OrdersRepository";

@injectable()
export default class OrderSocketService implements IOrderSocketService {
    private _orderAdapter = new OrderAdapter();
    private _onAdminCallback?: (order: IOrder) => void;
    private _onClientCallback?: (order: IOrder) => void;
    private _socket?: WebSocket;

    constructor(private filial: FilialService = container.get(FilialService),
    private ordersRepositort : OrdersRepository = container.get(OrdersRepository)) {
    };

    onAdminOrder(this: OrderSocketService, onAdminCallback: (order: IOrder) => void): void {
        this._onAdminCallback = onAdminCallback;
    }

    onClientOrder(this: OrderSocketService, onClientCallback: (order: IOrder) => void): void {
        this._onClientCallback = onClientCallback;
    }

    private async _onMessage(this: OrderSocketService, fullOrder: FullOrder) {
        // let order : FullOrder = JSON.parse(`${fullOrder}`);
        let order: IOrder = (await this._orderAdapter.orderFromSocket(JSON.parse(`${fullOrder}`)));

        if (order.source === ESourceStatus.CLIENT) {
            this.ordersRepositort.addMany([order]);
            this._onClientCallback?.call(this, order);
        }
        else if (order.source === ESourceStatus.ADMIN) {
            this._onAdminCallback?.call(this, order)
        }
    }

    openSocket = () => {
        const socket = new WebSocket(`wss://${this.filial.getSelectedCity?.code}.vrbook.creatrix-digital.ru/timeline-ws`);

        if (this._socket) {
            this._socket.onmessage = null;
            // this._socket.close();
        }
        this._socket = socket;

        this._socket.onmessage = (e: MessageEvent<FullOrder>) => {

            this._onMessage(e.data);
        }

        return socket;
    }

    closeSocket() {
        this._socket?.close();
    }
}