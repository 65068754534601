import { TColumn, THeadProps } from "../types";

// TODO закидывать компонент, аргументами которого порядок рендера столбцов
export const _THead: React.FC<NonNullable<THeadProps>> = ({
    columns,
    headerBuilder,
    isHeadFixed,
    subheader,
    transposition,
    headerStyleClass: headerStyles
}) => {
    return (
        <>
            {
                !transposition &&
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th style={{
                                position: isHeadFixed ? 'sticky' : 'initial',
                                top: 0
                            }}
                                key={column.cKey}
                                className={headerStyles}
                            >
                                {headerBuilder(column)}
                            </th>
                        ))}
                    </tr>
                    {subheader &&
                        <tr>
                            {columns.map(col =>
                                <th key={col.cKey}>
                                    {subheader(col)}
                                </th>)}
                        </tr>

                    }
                </thead>
            }
        </>

    );
};