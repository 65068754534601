/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingParam } from '../models/BookingParam';
import type { BookingRequest } from '../models/BookingRequest';
import type { ClientRequest } from '../models/ClientRequest';
import type { FullOrderRequest } from '../models/FullOrderRequest';
import type { Order } from '../models/Order';
import type { PaymentInfoRequest } from '../models/PaymentInfoRequest';
import type { ProblemDetails } from '../models/ProblemDetails';
import type { VacantTimes } from '../models/VacantTimes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { $instanceApi } from '../MainApi';
import { AxiosResponse } from 'axios';

export class BookingService {

    /**
     * @param gameTypeId 
     * @param roomId 
     * @returns BookingParam Success
     * @throws ApiError
     */
    public static getApiV3BookingParams(
gameTypeId?: number,
roomId?: number,
): CancelablePromise<BookingParam> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/booking/params',
            query: {
                'gameTypeId': gameTypeId,
                'roomId': roomId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Order Success
     * @returns ProblemDetails Error
     * @throws ApiError
     */
    public static postApiV3OrderCreate(
requestBody?: FullOrderRequest,
): CancelablePromise<Order | ProblemDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/order/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param id 
     * @param personalCode 
     * @param requestBody 
     * @returns Order Success
     * @returns ProblemDetails Error
     * @throws ApiError
     */
    public static putApiV3OrdersClient(
id: string,
personalCode?: string,
requestBody?: ClientRequest,
): CancelablePromise<Order | ProblemDetails> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/orders/{id}/client',
            path: {
                'id': id,
            },
            headers: {
                'employeeCode': personalCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @param personalCode 
     * @param requestBody 
     * @returns Order Success
     * @returns ProblemDetails Error
     * @throws ApiError
     */
    public static putApiV3OrdersBookings(
id: string,
personalCode?: string,
requestBody?: Array<BookingRequest>,
): CancelablePromise<Order | ProblemDetails> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/orders/{id}/bookings',
            path: {
                'id': id,
            },
            headers: {
                'employeeCode': personalCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param id 
     * @param personalCode 
     * @param requestBody 
     * @returns Order Success
     * @returns ProblemDetails Error
     * @throws ApiError
     */
    public static putApiV3OrdersPaymentInfo(
id: string,
personalCode?: string,
requestBody?: PaymentInfoRequest,
): CancelablePromise<Order | ProblemDetails> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v3/orders/{id}/payment-info',
            path: {
                'id': id,
            },
            headers: {
                'employeeCode': personalCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param time 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV3BookingRearrange(
time?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/booking/rearrange',
            query: {
                'time': time,
            },
        });
    }

    /**
     * @param gameId 
     * @param guestCount 
     * @returns VacantTimes Success
     * @throws ApiError
     */
    public static getApiV3BookingAvailable(
gameId?: number,
guestCount?: number,
): CancelablePromise<Array<VacantTimes>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/booking/available',
            query: {
                'gameId': gameId,
                'guestCount': guestCount,
            },
        });
    }

}



// export class BookingService {

//     static async getApiV3BookingParams(gameTypeId?: number, roomId?: number): Promise<AxiosResponse<BookingParam>> {
//         return await $instanceApi.get<BookingParam>('/api/v3/booking/params', {
//             params: {
//                 gameTypeId,
//                 roomId
//             }
//         });
//     }

//     static async postApiV3OrderCreate(requestBody?: FullOrderRequest): Promise<AxiosResponse<Order | ProblemDetails>> {
//         return await $instanceApi.post<Order | ProblemDetails>(
//             '/api/v3/order/create',
//             requestBody,
//             {
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//             }
//         );
//     }

//     static async putApiV3OrdersClient(id: string, personalCode?: string, requestBody?: ClientRequest): Promise<AxiosResponse<Order | ProblemDetails>> {
//         return await $instanceApi.put<Order | ProblemDetails>(
//             `/api/v3/orders/${id}/client`,
//             requestBody,
//             {
//                 headers: {
//                     'employeeCode': personalCode,
//                     'Content-Type': 'application/json'
//                 }
//             }
//         );
//     }


//     static async putApiV3OrdersBookings(id: string, personalCode?: string, requestBody?: Array<BookingRequest>): Promise<AxiosResponse<Order | ProblemDetails>> {
//         return await $instanceApi.put<Order | ProblemDetails>(
//             `/api/v3/orders/${id}/bookings`,
//             requestBody,
//             {
//                 headers: {
//                     'employeeCode': personalCode,
//                     'Content-Type': 'application/json'
//                 }
//             }
//         );
//     }

//     static async putApiV3OrdersPaymentInfo(id: string, personalCode?: string, requestBody?: PaymentInfoRequest): Promise<AxiosResponse<Order | ProblemDetails>> {
//         return await $instanceApi.put<Order | ProblemDetails>(
//             `/api/v3/orders/${id}/payment-info`,
//             requestBody,
//             {
//                 headers: {
//                     'employeeCode': personalCode,
//                     'Content-Type': 'application/json'
//                 }
//             }
//         );
//     }

//     static async getApiV3BookingRearrange(time?: string): Promise<AxiosResponse<any>> {
//         return await $instanceApi.get<any>(
//             '/api/v3/booking/rearrange',
//             {
//                 params: {
//                     time
//                 }
//             }
//         );
//     }

//     static async getApiV3BookingAvailable(gameId?: number, guestCount?: number): Promise<AxiosResponse<Array<VacantTimes>>> {
//         return await $instanceApi.get<Array<VacantTimes>>(
//             '/api/v3/booking/available',
//             {
//                 params: {
//                     gameId,
//                     guestCount
//                 }
//             }
//         );
//     }
// }