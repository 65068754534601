import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";

import api from "../../../api/MainApi";
import { request } from "../../../api/core/request";
import { ICity, ILoginData, ILoginResponce, IUser } from "../../../api/models/Login";

import { handleRequest } from "../../../common/utility-types/helpers/handleRequest";
import { IAuth } from "../domain/entities/IAuth";
import { RequestStatus } from "../../../@types/requestStatus";

import { container } from "../../../di/container";
import { Credential } from "../domain/entities/Credential";
import { AuthService } from "../../../api/services/MainService";
import { OpenAPI } from "../../../api";
import axios, { AxiosError } from "axios";

export class LoginViewModal {
    private _credential: Credential;

    @observable
    loginStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    errorText: string | null = null;

    @observable
    refreshStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    isLoading: boolean = false;
    // @observable
    // email: string;
    // @observable
    // password: string;
    // @observable
    // remember: boolean;


    constructor(credential: Credential = container.get(Credential)) {
        this._credential = credential;
        makeAutoObservable(this);
    }

    @computed
    get credential() {
        return this._credential;
    }

    setLoading(bool: boolean) {
        this.isLoading = bool;
    }

    @action
    async login(this: LoginViewModal, loginData: ILoginData) {
        this.loginStatus = RequestStatus.LOADING;

        // (await handleRequest(async () => mainService.login(loginData)))
        (await handleRequest(async () => AuthService.login(loginData)))
            .match({
                onLeft: (l) => {
                    runInAction(() => {
                        this.loginStatus = RequestStatus.ERROR;
                        this.errorText = l.message;
                    });
                },
                onRight: (r) => {
                    runInAction(() => {
                        this._credential.setLogin(r.data);
                        this.loginStatus = RequestStatus.SUCCESSFULL;


                        // localStorage.setItem('token', r.data.token);
                        // OpenAPI.TOKEN = r.data.token;
                        OpenAPI.TOKEN = r.data.token;

                        if (loginData.remember) {
                            localStorage.setItem('refToken', r.data.refreshToken);
                        }

                        // localStorage.setItem('user', JSON.stringify(r.data.personal));

                        // this._credential.setLogin(r.data);
                    });
                }
            });
    }

    @action
    async logout(this: LoginViewModal) {
        this.loginStatus = RequestStatus.LOADING;

        (await handleRequest(async () => AuthService.logout()))
            // (await handleRequest(async () => mainService.logout()))
            .match({
                onLeft: (l) => {
                    runInAction(() => {
                        this.loginStatus = RequestStatus.ERROR;
                        this.errorText = l.message;
                    });
                },
                onRight: (r) => {
                    runInAction(() => {
                        this.credential.setLogout();
                        this.loginStatus = RequestStatus.SUCCESSFULL;

                        localStorage.removeItem('refToken');
                        localStorage.removeItem('filial');
                        localStorage.removeItem('settings');
                    });
                }
            });
    }


    // @action
    // async refresh(this: LoginViewModal) {
    //     this.loginStatus = RequestStatus.LOADING;

    //     const accessToken = localStorage.getItem('token') ?? '';
    //     const refreshToken = localStorage.getItem('refToken') ?? '';

    //     (await handleRequest(async () => mainService.refresh(accessToken, refreshToken)))
    //         .match({
    //             onLeft: (l) => {
    //                 runInAction(() => {
    //                     this.loginStatus = RequestStatus.ERROR;
    //                     this.errorText = l.message;
    //                 });
    //             },
    //             onRight: (r) => {
    //                 runInAction(() => {
    //                     // this.credential.setLogout();
    //                     this.loginStatus = RequestStatus.SUCCESSFULL;
    //                     console.log(r);

    //                     localStorage.setItem('token', r.data.token);
    //                     // localStorage.setItem('refToken', r.data.refreshToken);
    //                 });
    //             }
    //         });
    // }

    @action
    async checkAuth() {

        // const accessToken = localStorage.getItem('token') ?? '';
        const refreshToken = localStorage.getItem('refToken') ?? '';

        const globalUrl = process.env.REACT_APP_API_GLOBAL_URL;

        this.refreshStatus = RequestStatus.LOADING;
        this.setLoading(true);
        try {
            const response = await axios.post<ILoginResponce>(
                `${globalUrl}/admin/auth/refresh`,
                `"${refreshToken}"`,
                // {
                //     refreshToken
                // },
                {
                    // withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                }
            );

            this._credential.setLogin(response.data);
            this.refreshStatus = RequestStatus.SUCCESSFULL;

            // console.log(response.data);
            OpenAPI.TOKEN = response.data.token;

            // localStorage.setItem('token', response.data.token);
            localStorage.setItem('refToken', response.data.refreshToken);
            // localStorage.setItem('user', JSON.stringify(response.data.personal));
        } catch (error: any) {
            console.log(error);

            if (error?.response?.status == 400) {
                localStorage.removeItem('refToken');
                localStorage.removeItem('filial');
                localStorage.removeItem('settings');
            }
        } finally {
            this.setLoading(false);
        }

        // (await handleRequest(async () => mainService.refresh(accessToken, refreshToken)))
        //     .match({
        //         onLeft: (l) => {
        //             runInAction(() => {
        //                 this.loginStatus = RequestStatus.ERROR;
        //                 this.errorText = l.message;
        //             });
        //         },
        //         onRight: (r) => {
        //             runInAction(() => {
        //                 // this.credential.setLogout();
        //                 this.loginStatus = RequestStatus.SUCCESSFULL;
        //                 console.log(r);

        //                 localStorage.setItem('token', r.data.token);
        //                 localStorage.setItem('refToken', r.data.refreshToken);
        //             });
        //         }
        //     });
    }
}