import { inject, injectable } from "inversify";
import { ALL_SELECT, BaseCollection } from "../../../@types/baseCollection";
import { GameType } from "../domain/entities/GameType";
import { container } from "../../../di/container";
import { ICollection } from "../../../@types/iCollection";
import { action, computed, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { BaseApiCollection } from "../../../@types/baseApiCollection";
import { GameService } from "../../../api";
import { GameTypeDto } from "../../../api/models/GameType";
import { filialLocalDataSource } from "./db";

@injectable()
export class GameTypesDataSourse implements ICollection<GameType> {
    private _baseCollection: BaseApiCollection<GameType, GameTypeDto> = new BaseApiCollection();

    constructor() {
        makeObservable(this);
    }

    @computed
    get isLoaded() {
        return this._baseCollection.isLoaded;
    }

    @computed
    get isLoading() {
        return this._baseCollection.isLoading;
    }

    @computed
    get error() {
        return this._baseCollection.error;
    }

    @action
    load = async () => {
        if ((await filialLocalDataSource.getGameTypes()).length != 0) {
            const gameTypes = await filialLocalDataSource.getGameTypes();
            runInAction(() => {
                this._baseCollection.loadLocally(gameTypes);
            });
        }
        else {
            await this._baseCollection.load(() => GameService.getGameTypes(), async (dto) => ({ id: dto.id, label: dto.title }))
            await filialLocalDataSource.addGameTypes(this._baseCollection.where(ALL_SELECT));
        }
    }

    getById = (id: number): GameType | null => this._baseCollection.getById(id);

    where = (predicate: (v: GameType) => boolean): GameType[] => this._baseCollection.where(predicate);

    setMany = (values: GameType[]): void => this._baseCollection.setMany(values);
    addMany = (values: GameType[]): void => this._baseCollection.addMany(values);
    deleteMany = (values: Pick<GameType, "id">[]): void => this._baseCollection.deleteMany(values);

}