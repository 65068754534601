import { ErrorMessage } from "@hookform/error-message";
import { Col, Row } from "antd";
import { AxiosError } from "axios";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import './AdminLoginStyles.css';
import { LoginViewModal } from "./loginViewModal";
import { useNavigate } from "react-router";
import { OpenAPI } from "../../../api";
import { container } from "../../../di/container";
import { ADMIN_TIMELINE_PATH } from "../../../common/router/RouterConfiguration";
import { RequestStatus } from "../../../@types/requestStatus";
import { observer } from "mobx-react";
import Loader from "../../../common/presentation/Loader";
import { ILoginResponce, IUser } from "../../../api/models/Login";
import { Credential } from "../domain/entities/Credential";
import { FilialViewModal } from "../../fillial/ui/view-models/FilialViewModel";
import useDebounce from "../../../common/hooks/useDebounce";
import { FilialService } from "../../fillial/domain/services/FilialService";

interface ILogInFormFields {
    email: string,
    password: string,
    remember?: boolean,
}

interface IError {
    message: string;
}

export const LoginModal: React.FC = observer(() => {
    const {
        register,
        handleSubmit,
        formState: {
            errors
        },
    } = useForm<ILogInFormFields>();

    const auth = useMemo(() => new LoginViewModal(), []);

    const [error, setError] = useState('');

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<ILogInFormFields> = useCallback(({ email, password, remember }, event) => {
        event?.preventDefault();
        auth.login({
            email: email,
            password: password,
            remember: remember ?? false
        }).then(() => {
            OpenAPI.TOKEN = `${auth.credential.authToken}`;

            navigate(ADMIN_TIMELINE_PATH);


            // localStorage.setItem('user', JSON.stringify(auth.credential.user));
        });

    }, []);

    const credential = container.get(Credential);

    const filialStor = localStorage.getItem('filial');
    const filialVM = useMemo(() => new FilialViewModal(), []);;
    const filialService = container.get(FilialService);

    const [isLoading, setIsLoading] = useState(false);
    const { data, isEmpty, debounceFetcher } = useDebounce<ILoginResponce>({
        //@ts-ignore
        fetchCallback: () => auth.checkAuth().then(() => {
            navigate(ADMIN_TIMELINE_PATH);
        }), timeout: 1500
    });

    useEffect(() => {
        // setIsLoading(true);

        if (localStorage.getItem('refToken')) {
            debounceFetcher();

            // auth.checkAuth().then(() => {

            //     navigate(ADMIN_TIMELINE_PATH);
            // });
            // .finally(()=>{
            //     setIsLoading(false);
            // });
        }

        if (filialStor) {
            // filialVM.filialData.setCity(JSON.parse(filialStor));
            filialService.setCity(JSON.parse(filialStor));
        }

    }, []);

    const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;


    return (

        <>
            {
                isLoading ?
                    <Loader size={250} color="#4b51ea" />
                    :

                    <Col sm={24} md={20} lg={18} xl={16} className='modalBg'>
                        <h2 className="loginTitle">Авторизация</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row className="err-block" >
                                <Col span={24}>
                                    {auth.errorText && <p className='error-message'>{auth.errorText}</p>}
                                </Col>
                                <Col span={24}>
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <p className='error-message'>{message}</p>}
                                    />
                                </Col>
                                <Col span={24}>
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) => <p className='error-message'>{message}</p>}
                                    />
                                </Col>
                            </Row>

                            <Row justify="space-evenly">
                                <Col xs={23} sm={23} md={11} className="form-group form-group-default" id="emailGroup">
                                    <input
                                        type='email'
                                        placeholder="Логин"
                                        className="form-control-login"
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message: 'Введите E-Mail'
                                            },
                                            pattern: {
                                                value: emailRegex,
                                                message: 'Введите E-Mail'
                                            }
                                        })}
                                    />
                                </Col>

                                <Col xs={23} sm={23} md={11} className="form-group form-group-default" id="passwordGroup">
                                    <input
                                        type="password"
                                        placeholder="Пароль"
                                        className="form-control-login"
                                        {...register("password", {
                                            required: {
                                                value: true,
                                                message: 'Введите пароль'
                                            },
                                        })}
                                    />
                                </Col>

                                <Col span={23}>
                                    <label className="form-group remember-me-group" id="rememberMeGroup">
                                        <div className="controls">
                                            <input
                                                type="checkbox"
                                                {...register("remember")}
                                                value={1}
                                            />
                                            <span className="remember-me-text">Запомнить меня</span>
                                        </div>
                                    </label>
                                </Col>

                                <Col span={23}>
                                    <button
                                        type="submit"
                                        className="btn-block login-button signin"
                                        style={{ padding: 0 }}
                                    // value={'Вход'}
                                    >
                                        {
                                            auth.loginStatus === RequestStatus.LOADING ?
                                                <Loader size={32} />
                                                : 'Вход'
                                        }
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
            }
        </>
    );
});

