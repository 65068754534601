import { Control, UseFormResetField, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { IHookFormOrder } from "../../domain/entities/IHokFormOrder";
import { GameTypesDataSourse } from "../../../fillial/data/GameTypesDataSourse";
import { IBooking } from "../../domain/entities/IBooking";
import React, { useEffect, useMemo } from "react";
import { GameType } from "../../../fillial/domain/entities/GameType";
import { Select } from "antd";
import { ALL_SELECT } from "../../../../@types/baseCollection";
import DefaultController from "../../../../common/presentation/DefaultController";
import { GameValue } from "./GameController";
import { LabeledValue } from "antd/lib/select";
import { observer } from "mobx-react-lite";

type GameTypeProps = {
    control: Control<IHookFormOrder, any>,
    index: number | string,
    booking?: IBooking,
    defaultValue?: number,
    gameTypes: GameType[],
    isLoaded: boolean,
    setValue: UseFormSetValue<IHookFormOrder>,
    resetField: UseFormResetField<IHookFormOrder>,
    trigger: UseFormTrigger<IHookFormOrder>
};

type GameTypeValue = LabeledValue & { gameType: GameType };

const noGame: GameType = {
    id: -1,
    label: 'Нет игры'
};

export const GameTypeController: React.FC<GameTypeProps> = ({
    control,
    gameTypes,
    isLoaded,
    index,
    setValue,
    resetField,
    trigger
}) => {


    //Получить все доступные комнаты 
    const onChangeGameTypeBuilder = (onChangeCallback: (value: number, option: GameTypeValue | GameTypeValue[]) => void) => (value: number, option: GameTypeValue | GameTypeValue[]) => {
        // onChangeCallback(value, option);
        const selectedOption = option as GameTypeValue;
        console.log('change');
        
        setValue(`bookings.${index}.game`, null);
        // resetField(`bookings.${index}.game`);
        setValue(`bookings.${index}.gameTypeId`, selectedOption.gameType.id);
        setValue(`bookings.${index}.game`, null);
    };

    const gameTypeOptions = useMemo<GameTypeValue[]>(() => isLoaded
        ? [noGame, ...gameTypes].map<GameTypeValue>(v => ({ key: v.id.toString(), value: v.id.toString(), label: v.label, gameType: v }))
        : [],
        [isLoaded]);

    const getLabeledValueById = (id?: number) => {
        return gameTypeOptions.find(gameTypeOption => gameTypeOption.gameType.id == id);
    };

    return <DefaultController
        name={`bookings.${index}.gameTypeId`}
        control={control}
        rules={
            {
                required: { value: true, message: 'Это поля обязательно' },
            }
        }
        render={({ field }) => (
            <Select
                // {...field}
                className='default-select'
                disabled={!isLoaded}
                labelInValue
                // @ts-ignore
                value={getLabeledValueById(field.value)}
                loading={!isLoaded}
                onChange={onChangeGameTypeBuilder(field.onChange)}
                options={gameTypeOptions}
                style={{ height: '40px', width: '100%' }}
                placeholder='Тип игры'
                popupClassName={'first-plan-object'}
            />
        )}
    />;
};
