import { Button, Col, Form, Input, message, Radio, Row, Space } from "antd";
import React, { useCallback, useMemo, useState } from "react";

import PersonalDataGroup from "./components/PersonalDataGroup";
import BookingGroup from "./components/BookingGroup";
// import { toggleOpen } from "../../booking/redux/slice";
import { FieldErrorsImpl, useController, useForm, useFormContext, useWatch } from "react-hook-form";
import DefaultController from "../../../common/presentation/DefaultController";
import { RequestStatus } from "../../../@types/requestStatus";
import { Without } from "../../../@types/without";
import { ESourceStatus, IOrder } from "../domain/entities/IOrder";
import DefaultSpin from "../../../common/presentation/DefaultSpin";
import { ConnectOrder } from "./providers/OrderProvider";
import { toHookFormBooking } from "../adapters/hookFormAdapter";
import PriceGroup from "./components/PriceGroup";
import { EOrderStatus } from "../domain/entities/EOrderStatus";
import { EmployeeCodeInput } from "./components/EmployeeCodeInput";
import { CommentController } from "./components/CommentController";
import CertificatesList from "../../loaylty-system/ui/CertificateList";
import { SmartPriceCalculator } from "./components/SmartPriceCalculator";
import { TitleController } from "./components/TitleController";
import Loader from "../../../common/presentation/Loader";
import { OrderRemoteDataSource } from "../../../api/services/OrderService";

import arrow from '../../../assets/arrow-right.svg';
import { EmployeeNameInput } from "./components/EmployeeNameInput";
import VipGroup from "./components/VipGroup";
import { TimelineBlock } from "../../../api";

type OrderCreationFormProps = {
  order: Without<IOrder, Function> | null,
  isSaving: boolean,
  onSave: () => void,
  onCancel: () => void,
  infos?: Omit<TimelineBlock, "bookings">[] | null,
};

const OrderCreationForm: React.FC<OrderCreationFormProps> = ({ order, isSaving, onSave, onCancel, infos }) => {

  let availableRooms = infos?.map(info => {
    return {
      // time: new Date(info.time!).getHours(),
      time: info.time!,
      rooms: info.info?.availableRooms!.map(room => {
        return room.id;
      }) ?? []
    };
  });

  const orderId = useMemo(() => order?.id ?? -1, [order]);

  const orderIdTitle = useMemo(
    () => (~orderId ? `Заказ №${orderId}` : "Пустой заказ"),
    [orderId]
  );


  const [employeeName, setEmployeeName] = useState<string | null>(null);

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!value) {
      setEmployeeName('0');
      return;
    }

    try {
      const response = await OrderRemoteDataSource.getPersonalByCode(parseInt(value));
      if (response.id) {
        setEmployeeName(response.name ?? null);
      }
    } catch (error) {
      message.error("Сотрудник не найден");
      setEmployeeName('0');
    }
  };

  return (
    <Form
      layout='vertical'
      className="sider-content-wrapper"
      preserve={false}
      onFinish={onSave}
    >
      <Space style={{ width: '100%' }} direction='vertical' size={[0, 20]}>
        <Row justify='space-between' align='middle'>
          <div className="order-id">{orderIdTitle}</div>
          <div style={{ display: 'flex', alignItems: 'flex-top', gap: '4px', width: '100%' }}>
            <div style={{ display: 'flex', gap: '8px', width: '105%' }}>
              <div style={{ width: '100%' }}>
                <EmployeeCodeInput handleBlur={handleBlur} />
              </div>
              <img style={{ height: '40px', borderRadius: "15px"/*, backgroundColor: "#F3F5F9"*/, padding: '9px 12px' }} src={arrow} alt="" />
            </div>
            <ConnectOrder >
              {(orderApi) => <EmployeeNameInput {...orderApi} value={employeeName ?? '1'} />}
            </ConnectOrder >
            {/* <EmployeeNameInput value={employeeName ?? '1'} /> */}
          </div>
        </Row>
        <ConnectOrder>
          {({ control, order }) =>
            // order?.source != ESourceStatus.ADMIN || order.status != EOrderStatus.CREATED
            // ? 
            <DefaultController
              label={<div className="creation-form-label">Статус заказа:</div>}
              labelGap={10}
              name={'status'}
              control={control}
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  style={{ width: '100%' }}
                >
                  <Row gutter={[20, 10]}>
                    <Col span={8}>
                      <Radio.Button value={EOrderStatus.CREATED}>Создано</Radio.Button>
                    </Col>
                    <Col span={8}>
                      <Radio.Button value={EOrderStatus.CONFIRMED}>Подтверждено</Radio.Button>
                    </Col>
                    {/* <Col span={8}>
                      <Radio.Button value={EOrderStatus.PROCESSING}>
                        {order?.source == ESourceStatus.ADMIN ? 'Создается' : 'Не подтверждено'}
                      </Radio.Button>
                    </Col> */}
                    <Col span={8}>
                      <Radio.Button value={EOrderStatus.REJECTED}>Отменено</Radio.Button>
                    </Col>
                    <Col span={8}>
                      <Radio.Button value={EOrderStatus.PROCESSING}>Предварительно</Radio.Button>
                    </Col>
                  </Row>
                </Radio.Group>
              )}
            />
            // : null
          }
        </ConnectOrder>

        <TitleController />

        {/* <DefaultController
          control={control}
          label={<div className="creation-form-label">Статус оплаты:</div>}
          labelGap={10}
          name={PAYMENT_STATUS_PATH}
          render={({ field }) => (
            <Radio.Group {...field} style={{ width: '100%' }}>
              <Row gutter={[20, 20]}>
                <Col span={8}>
                  <Radio.Button value={1}>Оплачено</Radio.Button>
                </Col>
                <Col span={8}>
                  <Radio.Button value={0}>Не оплачено</Radio.Button>
                </Col>
              </Row>
            </Radio.Group>
          )}
        /> */}

        <ConnectOrder >
          {(orderApi) => <PersonalDataGroup {...orderApi} client={orderApi.order?.client ?? null} />}
        </ConnectOrder >

        <ConnectOrder >
          {({ setValue, control }) => <BookingGroup
            control={control}
            setValue={setValue}
            bookings={(order?.bookings ?? []).map(b => toHookFormBooking(b))}
            orderId={orderId}
          />}
        </ConnectOrder>

        <ConnectOrder >
          {(orderApi) => <VipGroup {...orderApi} availableRooms={availableRooms!} />}
        </ConnectOrder>

        <CommentController />
        {/* <LoyaltyGroup
          dispatch={dispatch}
          order={order}
          formApi={formApi}
          clientId={order.client?.id ?? null}
          price={paymentInfo?.price}
          token={token}
        /> */}
        {/* <DiscountGroup formApi={formApi}/> */}
        {/* <CertificatesList
          formApi={formApi}
          order={order}
          token={token}
        /> */}


        <SmartPriceCalculator />

        <ConnectOrder>
          {({ order }) => <PriceGroup
            paymentInfo={order?.priceInfo ?? null}
          />}
        </ConnectOrder>



        <Space size={[20, 20]}>
          <Button disabled={isSaving} className="default-btn" onClick={onCancel}>
            Отменить
          </Button>
          <Button disabled={isSaving} className="default-btn" htmlType="submit">
            {isSaving
              // ? <DefaultSpin />
              ? <Loader size={32} color={'#49a7fe'} />
              : 'Сохранить'
            }
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export default OrderCreationForm;
