import { makeAutoObservable, observable } from "mobx";
import { TimelineSettings } from "./TimelineSettings";
import { injectable } from "inversify";

@injectable()
export class TimelineSettingsRepository {
    @observable
    timelineSettings: TimelineSettings = TimelineSettings.initial();  

    constructor(){
        makeAutoObservable(this);
    }
}