import { Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { Certificate, PrecalculateResponse } from '../../../../entities/PaymentInfo';

import { PriceInfo } from '../../domain/types';
import { observe } from 'mobx';
import { observer } from 'mobx-react-lite';
// import { PriceInfo } from '../../../../api';

interface PriceGroupProps {
    paymentInfo: PriceInfo | null
    
}

interface IntermediateValueRowProps {
    title: string,
    value?: number
}

// type CertificateValueRowProps = Omit<IntermediateValueRowProps, 'value' | 'title'> & { certificates: Certificate[] };

const PriceGroup: React.FC<PriceGroupProps> = observer(({paymentInfo}) => {
    // const { order, formApi, paymentInfo, status, precalculateOrder, clearPaymentInfo } = props;

    return (
        <div className='price-container'>
            {/* {status === RequestStatus.LOADING
                ? <div className='price-message'>
                    <DefaultSpin size={40} />
                </div>
                : null
            } */}
            <div className='price-wrapper'>
                <div className='price-intermediate-group'>
                    <IntermediateValueRow
                        title='Стоимость заказа:'
                        value={paymentInfo?.price}
                    />
                    {/* <IntermediateValueRow
                        title='Промокод:'
                        value={paymentInfo.}
                    /> */}
                    {/* <IntermediateValueRow
                        title='Бонусы:'
                        value={paymentInfo?.bonus_discount}
                    />
                    <IntermediateValueRow
                        title='Скидка:'
                        value={paymentInfo?.skidka}
                    />
                    <CertificateValueRow
                        certificates={paymentInfo?.certificates ?? []}
                    /> */}

                </div>
                <Row justify='space-between' align='top'>
                    <div className='price-value-title'>Итого:</div>
                    <div className='price-value'>{
                        paymentInfo?.total != undefined
                            ? `${paymentInfo?.total}₽`
                            : 'Не можем подсчитать'
                    }</div>
                </Row>
            </div>
        </div>

    )
})


// const CertificateValueRow: React.FC<CertificateValueRowProps> = ({ certificates }) => {

//     const certificateAmoutAll = useMemo(
//         () => certificates && certificates.length == 0
//             ? undefined
//             : certificates.reduce((accum, cert) => accum + +cert.amount, 0),
//         [certificates]
//     );

//     return (
//         <>
//             <IntermediateValueRow title='Сертификаты:' value={certificateAmoutAll} />
//             <div style={{ padding: '0 20px' }}>
//                 {certificates.map(certificate => (
//                     <Row key={certificate.id} justify='space-between' align='top'>
//                         <div className='price-intermediate-value-title named'>{certificate.code}</div>
//                         <div className='price-intermediate-value named'>{`${certificate.amount}₽`}</div>
//                     </Row>
//                 ))}
//             </div>
//         </>

//     )
// }

const IntermediateValueRow: React.FC<IntermediateValueRowProps> = ({ title, value }) => {
    return (
        value
            ? <Row justify='space-between' align='top'>
                <div className='price-intermediate-value-title'>{title}</div>
                <div className='price-intermediate-value'>{`${value}₽`}</div>
            </Row>
            : null
    )
}




export default PriceGroup;
