import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
    color?: string;
    size: number;
}

const Loader: React.FC<Props> = ({ color, size }) => {
    return (
        <StyledWrapper>
            <StyledLoader color={color} size={size}>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </StyledLoader>
        </StyledWrapper>
    );
};

export default Loader;
//styled(Button) <{ name?: string }>

// ${(props) =>
//     props.name === 'copy' &&
//     css`
//           background-color: #9747FF;  
//           background-image: url(${icon});
//           background-position: center;
//           background-repeat: no-repeat;

//          &:hover {
//            background-image: url(${icon2});
//            background-position: center;
//            background-repeat: no-repeat;
//           }        
//     `}

const StyledWrapper = styled.div`
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledLoader = styled.div<{ color?: string, size: number }>`
  
	position: relative;

    ${(props) =>
        props.size &&
        css`
           width: ${props.size}px;
	        height: ${props.size}px;
     `}

    .dot {
        --dot: 5%;
        background: radial-gradient(circle at 50% calc(5% + 2px), var(--w) var(--dot), #fff0 calc(var(--dot) + 2px));
        position: absolute;
        width: 100%;
        height: 100%;
        animation: spin 1.5s cubic-bezier(0.4, 0, 0.2, 1) 0s infinite;
    }	

    ${(props) =>
        props.color &&
        css`
        --w: ${props.color};
        background: radial-gradient(circle at 50% calc(5% + 2px), var(--w) var(--dot), #fff0 calc(var(--dot) + 2px));
    `}

    @keyframes spin {
        0%, 20% { transform: rotate(0deg); filter: drop-shadow(0 0 0 #fff0); }
        60% { filter: drop-shadow(-0.25vmin 0 0 var(--s)) drop-shadow(-0.25vmin 0 0 var(--s)) drop-shadow(-0.25vmin 0 0 var(--s)); }
        100% { transform: rotate(360deg); filter: drop-shadow(0 0 0 #fff0); }
    }

    .dot:nth-child(2){
        --dot: 4.5%;
        animation-delay: 0.05s;
    }

    .dot:nth-child(3){
        --dot: 4%;
        animation-delay: 0.1s;
    }

    .dot:nth-child(4){
        --dot: 3.5%;
        animation-delay: 0.15s;
    }

    .dot:nth-child(5){
        --dot: 3%;
        animation-delay: 0.2s;
    }

    .dot:nth-child(6){
        --dot: 2.5%;
        animation-delay: 0.25s;
    }

    .dot:nth-child(7){
        --dot: 2%;
        animation-delay: 0.3s;
    }

    .dot:nth-child(8){
        --dot: 1.5%;
        animation-delay: 0.35s;
    }

    .dot:nth-child(9){
        --dot: 1%;
        animation-delay: 0.4s;
    }

    .dot:nth-child(10){
        --dot: 0.5%;
        animation-delay: 0.45s;
    }
`;