import { inject } from "inversify";
import { GameTypesDataSourse } from "../../../fillial/data/GameTypesDataSourse";
import { TimelineSettings } from "../../domain/entities/TimelineSettings";
import { container } from "../../../../di/container";
import { action, computed, makeObservable, observable } from "mobx";
import { ALL_SELECT } from "../../../../@types/baseCollection";
import { RequestStatus } from "../../../../@types/requestStatus";
import { Without } from "../../../../@types/without";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { NullLiteral } from "typescript";
import { TimelineSettingsRepository } from "../../domain/entities/TimelineSettingsRepository";
import { IBooking } from "../../../booking/domain/entities/IBooking";

type Packet = Omit<Without<IOrder, Function>, 'bookings'> & {bookings: Partial<Without<IBooking, Function>>}
export class TimelineSettingsViewModel {
    private _settings: TimelineSettings;
    private _gameTypesCollection: GameTypesDataSourse;


    @observable
    getGameTypesStatus: RequestStatus = RequestStatus.NEVER;


    constructor(
        settings: TimelineSettings,
        private timelineSettingsRepository = container.get(TimelineSettingsRepository),
        private gameTypesCollection = container.get(GameTypesDataSourse)
    ) {
        this._settings = settings;
        this._gameTypesCollection = gameTypesCollection;
        this.init();
        makeObservable(this);
    }

    @computed
    get settings(){
        return this._settings
    }

    @computed
    get gameTypes() {
        return this._gameTypesCollection.where(ALL_SELECT);
    }

    @action
    async init(this: TimelineSettingsViewModel) {
        this.getGameTypesStatus = RequestStatus.LOADING;
        await this._gameTypesCollection.load();
        this.getGameTypesStatus = RequestStatus.SUCCESSFULL;
    }
}