import { Either, IEither } from "./either";

export class Left<L, R> implements IEither<L, R> {
    left: L;

    constructor(l: L) {
        this.left = l;
    }
    map<C>(this: Left<L, R>, mapCallback: (r: R) => C): IEither<L, C> {
        return Either.left<L, C>(this.left);
    }
    match<C>(this: Left<L, R>, args: { onLeft: (left: L) => C; onRight: (r: R) => C; }): C  {
        return args.onLeft(this.left);
    }
    getRight(this: Left<L, R>, orElse: (l: L) => R): R {
        return orElse(this.left);
    }
    getLeft(this: Left<L, R>, orElse: (r: R) => L): L {
        return this.left
    }
    isLeft(this: Left<L, R>): boolean {
        return true;
    }
    isRight(this: Left<L, R>): boolean {
        return false;
    }
    
}