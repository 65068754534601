import { IReactionDisposer, ObservableMap, action, autorun, comparer, computed, get, makeAutoObservable, makeObservable, observable, reaction, runInAction, when } from "mobx";
import { Timeline } from "../../domain/entities/Timeline";
import moment from "moment";
import { RequestStatus } from "../../../../@types/requestStatus";
import { CellContentType, IBookingCell } from "../../domain/entities/IBookingCell";
import { TColumnType, TRowType } from "../../../../common/presentation/enhasted-table";
import { ITimeline } from "../../domain/entities/ITimeline";
import { BookingCell } from "../../domain/entities/BookingCell";
import { TCellData } from "../../../../common/presentation/enhasted-table/types";
import { TimelineSettings } from "../../domain/entities/TimelineSettings";
import { Booking } from "../../../booking/domain/entities/Booking";
import { ITimelineSettings } from "../../domain/entities/ITimelineSettings";
import { LoadTimelineUseCase } from "../../domain/use-cases/loadTimelineUseCase";
import { ESourceStatus, IOrder } from "../../../booking/domain/entities/IOrder";
import { Without } from "../../../../@types/without";
import { UseFormReturn } from "react-hook-form";
import { IHookFormOrder } from "../../../booking/domain/entities/IHokFormOrder";
import { toHookFormBooking, toHookFormOrder } from "../../../booking/adapters/hookFormAdapter";
import { saveOrderUseCase } from "../../../booking/domain/use-cases/saveOrderUseCase";
import { BASE_ORDER_ID, Order, defaultOrder } from "../../../booking/domain/entities/Order";
import { IOrderService } from "../../../booking/domain/services/IOrderService";
import { container } from "../../../../di/container";
import { OrderService } from "../../../booking/domain/services/OrderService";
import MathHelper from "../../../../common/utility-types/helpers/MathHelper";
import { TimelineOrderService } from "../../domain/services/TimelineOrderService";
import { Modal } from "antd";
import { DATE_FORMAT } from "../../../../common/constants";
import { IOrderSocketService } from "../../../../common/services/IOrderSocketService";
import OrderSocketService from "../../../../common/services/OrderSocketService";
import { IFilialService } from "../../../fillial/domain/services/IFilialService";
import { FilialService } from "../../../fillial/domain/services/FilialService";
import { ETimelineType } from "../../domain/entities/TimelineType";
import { OrderForDayResponse, TimelineInfo } from "../../../../api/models/OrderForDayResponse";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import { IBooking } from "../../../booking/domain/entities/IBooking";
import { EOrderStatus } from "../../../booking/domain/entities/EOrderStatus";
import { injectable } from "inversify";

import { MIN_TIMELINE_ROWS_COUNT } from "../../domain/utils/constatnts";

@injectable()
export class TimelinePageViewModel {
    @observable
    date: moment.Moment = moment();
    @observable
    selectedTimelineType: ETimelineType = ETimelineType.TABLE;
    @observable
    params: TimelineInfo[] | null = null;
    @observable
    timelineSettings: ITimelineSettings = new TimelineSettings('default', false, false, MIN_TIMELINE_ROWS_COUNT);
    @observable
    loadTimelineStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    isSiderOpen: boolean = false;
    @observable
    private _rearangeStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    private _orderSaveStatus: RequestStatus = RequestStatus.NEVER;
    private _disposers: IReactionDisposer[] = [];
    @observable
    errorText: string | null = null;
    // @observable
    // private _editableOrder: IOrder | null = null;

    loadTimelineUseCase: LoadTimelineUseCase = new LoadTimelineUseCase();

    constructor(
        private _orderService: IOrderService = container.get(OrderService),
        private _fillialService: IFilialService = container.get(FilialService),
        private _orderSocketService: IOrderSocketService = container.get(OrderSocketService),
        private _ordersRepository = container.get(OrdersRepository)
    ) {
        makeAutoObservable(this);
    }

    setEditableOrder = (order: IOrder) => {
        // this._editableOrder = order;
        // this._orderService.setEditableOrder(order);
        this._orderService.setEditableOrder(order);

        // console.log(this._orderService.editableOrder);
        // console.log(this._editableOrder);
    };

    // @computed
    // get editableOrder() {
    //     return  this._editableOrder;
    // }

    @computed
    get orders() {
        const orders = this._ordersRepository.orders.filter(o => o.bookings.find(b => b.bookingDate?.isSame(this.date, 'date')));
        const isEditOrder = orders.find(o => o.id == this.actualOrder?.id);
        if (!this.actualOrder) return orders;
        if (isEditOrder) return orders.map(o => o.id == this.actualOrder?.id ? this.actualOrder : o);
        return [...orders, this.actualOrder];
    }


    @computed
    get isSaving(): boolean {
        return this._orderSaveStatus == RequestStatus.LOADING;
    }

    @computed
    get orderStatus(): RequestStatus {
        return this._orderSaveStatus;
    }

    @computed
    get actualOrder() {
        return this._orderService.order;
    }

    init(this: TimelinePageViewModel) {
        this._orderSocketService.openSocket();
        // this._orderSocketService.onAdminOrder((order) => {
        //     console.log(`AdminOrder`, order);

        // })
    }

    closeSocket() {
        this._orderSocketService.closeSocket();
    }

    dispose(this: TimelinePageViewModel) {
        this._disposers.forEach(disposer => disposer());
        this._orderSocketService.closeSocket();
    }

    startListenClientOrderUpdate(this: TimelinePageViewModel, clientCallback: (order: IOrder) => void) {
        this._orderSocketService.onClientOrder((order) => {
            if (order.bookings.length === 0) return;
            clientCallback(order);
            if (order.bookings.find(booking => booking.bookingDate?.isSame(this.date, 'date'))) {
                this._ordersRepository.setOrders([...this._ordersRepository.orders, order]);
            }
        });
    }

    startListenAdminOrderUpdate(this: TimelinePageViewModel) {
        this._orderSocketService.onAdminOrder((order) => {
            if (order.bookings.length === 0) return;
            if (order.bookings.find(booking => booking.bookingDate?.isSame(this.date, 'date'))) {
                this._ordersRepository.setOrders([...this._ordersRepository.orders, order]);
            }
        });
    }

    @action
    loadBookingByDate = async (date: string) => {
        this.date = moment(date);
        this.loadTimelineStatus = RequestStatus.LOADING;
        // this._updateTimelineLayers(ETimelineLayer.MAIN, Timeline.loading());
        (await this.loadTimelineUseCase.execute(this.date)).match({
            onLeft: (l) => {
                runInAction(() => {
                    this.loadTimelineStatus = RequestStatus.ERROR;
                });
            },
            onRight: (r) => {
                runInAction(() => {
                    this.params = r.infos;
                    this.loadTimelineStatus = RequestStatus.SUCCESSFULL;
                });
                // this._updateTimelineLayers(ETimelineLayer.MAIN, r);
            }
        });
    };


    onSwitchDate = (date: moment.Moment, formApi: UseFormReturn<IHookFormOrder, any>) => {
        if (this.actualOrder && !this.actualOrder.bookings.find(booking => booking.bookingDate?.isSame(date, 'date')) && !date.isBefore(moment(), 'date')) Modal.confirm({
            title: `Вы хотите переместить бронь на ${date.format('DD:MM:YYYY')}`,
            cancelText: 'Нет',
            okText: 'Да',
            onCancel: () => {
                this.loadBookingByDate(date.format(DATE_FORMAT));
            },
            onOk: () => {
                console.log('DATE - ', date.date());

                formApi.setValue('bookings', this.actualOrder?.bookings
                    .map(booking => ({
                        ...booking,
                        bookingDate: booking.bookingDate?.set('date', date.date()) ?? moment()
                    }))
                    .map(toHookFormBooking) ?? []);
                this.loadBookingByDate(date.format(DATE_FORMAT));
            }
        });
        else {
            this.loadBookingByDate(date.format(DATE_FORMAT));
        }
    };

    setBookings = (bookings: IBooking[]) => {
        if (this.actualOrder == null) {
            this._orderService.create();
        }
        this._orderService.update({ bookings: bookings });
    };

    @action
    changeSelectedView = (v: ETimelineType) => {
        this.selectedTimelineType = v;

        // localStorage.setItem('settings', `${v}`);
        const settings = localStorage.getItem('settings');
        
        localStorage.setItem('settings', JSON.stringify({...JSON.parse(settings ?? ''), page: `${v}` }));
    };

    @action
    setIsOpenSider = (v: boolean) => {
        this.isSiderOpen = v;
    };

    @action
    onCancel = async (reset: () => void, isDelete: boolean = true) => {
        this.isSiderOpen = false;
        if (isDelete) await this._orderService.delete();
        reset();
        // this._editableOrder = null;
    };

    @action
    onUpdateOrder(this: TimelinePageViewModel, order: Partial<IOrder> | null) {
        this._orderService.update(order);
        // this._editableOrder = order as IOrder;
    }

    @action
    onSaveOrder = async (reset: () => void) => {
        const order = this.actualOrder;
        if (order == null) return;
        this._orderSaveStatus = RequestStatus.LOADING;
        (await this._orderService.save()).match({
            onLeft: (l) => {
                runInAction(() => {
                    this._orderSaveStatus = RequestStatus.ERROR;
                });
            },
            onRight: (r) => {
                this._orderSaveStatus = RequestStatus.SUCCESSFULL;
                // Обновление компонента Dashboard
                this._ordersRepository.setOrders([...this._ordersRepository.orders, r]);
                this.onCancel(reset, false);
            }
        });
    };

    buildEdit(formApi: UseFormReturn<IHookFormOrder, any>): (a: (order: IOrder) => Partial<IOrder> | null) => void {
        return (callback) => {
            // if (this.actualOrder == null) this._orderService.create();
            const uOrder = callback(this.actualOrder ?? defaultOrder);
            if (uOrder?.id != BASE_ORDER_ID) this._orderService.update(uOrder);
            else this._orderService.create();
            const uHookFormOrder = toHookFormOrder({ ...defaultOrder, ...uOrder });
            formApi.reset((prev) => ({ ...prev, ...uHookFormOrder }), { keepDefaultValues: true });
            // if (uOrder != null) formApi.setValue('bookings', (uOrder?.bookings ?? []).map(toHookFormBooking));
        };
    }

}
