import React, { useMemo } from "react";
import { ILoungeZone } from "../domain/ILoungeZone";
import './loungeView.css';
import {ReactComponent as LoungeIcon} from '../../../assets/icons/lounge_icon.svg';

type LoungeZoneViewProps = {
    loungeZone: ILoungeZone
}

export const LoungeZoneView: React.FC<LoungeZoneViewProps> = ({ loungeZone }) => {

    const color = useMemo(
        () => loungeZone.isOccupied
            ? '#E74646'
            : '#65BD79',
        [loungeZone.isOccupied]
    );

    return (
        <div className="lounge-view" style={{ backgroundColor: color + '29', }}>
            <div className="lounge-view__wrapper">
                <LoungeIcon fill={color} />
            </div>
            <div style={{ color: color }} className="lounge-view__text">{loungeZone.title}</div>
        </div>
    )
}