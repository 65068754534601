import MathHelper from "../helpers/MathHelper";


//TODO пофиксить баг с цветами
export default class ColorPool {
    private freeColors: string[] = [];
    private colorsSet: Set<string> = new Set();
    private pool: Map<number, string> = new Map();

    public static instance = new ColorPool();

    private constructor() { }

    init() {
        this.freeColors = Array.from(this.colorsSet);
        this.pool.clear();
    }

    getColor(id: number | string): string {
        let stringUniqueHash: number = MathHelper.getHashFromStr(id.toString());
        return `hsl(${stringUniqueHash % 360}, ${stringUniqueHash % 20 + 60}%, ${stringUniqueHash % 30 + 50}%)`;
    }

}