import { Formater } from "./PowerController";


export class TextFormater {

    static combine(formaters: Formater<string>[]): Formater<string> {
        return (v) => {
            let fValue = v;
            for (const formater of formaters) {
                fValue = formater(fValue);
            }
            return fValue ?? '';
        }
    }

    static digitsOnly(): Formater<string> {
        return (v) => {
            const vSymbols = (v ?? '').split('');
            let rValue = '';
            for (const vSymbol of vSymbols) {
                if (!Number.isNaN(Number.parseInt(vSymbol))) rValue += vSymbol;
            }
            return rValue;
        }

    }

    /**
     * 
     * @param mask String format as ###-###, where # - is any symbol
     * 
     * @returns formater function
     */
    static asMask(mask: string): Formater<string> {
        return (v) => {
            if (v == null) return '';
            if (mask.length <= v.length) return v;
            const vSymbols = v.split('');
            const mSymbols = mask.split('');

            let maskedValue = '';
            for (const mSymbol of mSymbols) {
                if (maskedValue.length == mask.length) break;
                if (vSymbols.length == 0) break;
                const vSymbol = vSymbols[0];
                if (mSymbol == '#' || mSymbol == vSymbol) {
                    maskedValue += vSymbol;
                    vSymbols.shift();
                }
                else maskedValue += mSymbol;
            }
            return maskedValue;
        };
    }

    static length(max: number): Formater<string> {
        return (v) => v?.slice(0, max) ?? '';
    }
}