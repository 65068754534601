import { useCallback } from "react";
import { IHookFormBooking } from "../../domain/entities/IHokFormOrder";
import { ConnectOrder } from "../providers/OrderProvider";
import { GameTypeController } from "./GameTypeController";
import { GameTypesDataSourse } from "../../../fillial/data/GameTypesDataSourse";
import { container } from "../../../../di/container";
import { ALL_SELECT } from "../../../../@types/baseCollection";
import { IBooking } from "../../domain/entities/IBooking";
import { Observer, observer } from "mobx-react-lite";

export type SmartGameTypesControllerProps = {
    bookingId: string | number,
    gameTypeDataSoure?: GameTypesDataSourse
}


export const SmartGameTypesController: React.FC<SmartGameTypesControllerProps> = observer(({
    bookingId,
    gameTypeDataSoure = container.get(GameTypesDataSourse)
}) => {

    const getCurrBookingIndex = useCallback(
        (bookings: IHookFormBooking[] | IBooking[]) => bookings.findIndex(booking => booking.id == bookingId),
        [bookingId]
    )

    return (<ConnectOrder>
        {({ setValue, control, order, trigger, resetField }) => <Observer>
            {() => <GameTypeController
                gameTypes={gameTypeDataSoure.where(ALL_SELECT)}
                trigger={trigger}
                isLoaded={gameTypeDataSoure.isLoaded}
                setValue={setValue}
                resetField={resetField}
                control={control}
                index={getCurrBookingIndex(order?.bookings ?? [])}
                booking={order?.bookings.at(getCurrBookingIndex(order.bookings))}
                defaultValue={order?.bookings.find(booking => booking.gameTypeId)?.gameTypeId}
            />}
        </Observer>}
    </ConnectOrder>)
})