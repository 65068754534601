import { DependencyList, useEffect } from "react"

interface UseTitleArgs {
    title?: string,
}

const DEFAULT_PREFIX = 'Deep VR';

export const useTitle = (args: UseTitleArgs) => {
    const {title,} = args;
    useEffect(() => {
        if(title) document.title = `${DEFAULT_PREFIX} | ${title}`;
        else document.title = DEFAULT_PREFIX;
    }, [title])
}