import { action, makeObservable, observable } from "mobx";
import { CellContentStyle, ITimelineSettings,  } from "./ITimelineSettings";
import { MIN_TIMELINE_ROWS_COUNT } from "../utils/constatnts";

export class TimelineSettings implements ITimelineSettings {
    @observable
    cellStyle: CellContentStyle;
    @observable
    isTransposed: boolean = false;
    @observable
    isFixed: boolean = false;
    @observable
    showCanceled: boolean = false;
    @observable
    glassesCount: number;
    @observable
    rowsCount: number;

    constructor(cellStyle: CellContentStyle, isTransposed: boolean, isFixed: boolean,  showCanceled: boolean, rowsCount: number, glassesCount: number) {
        makeObservable(this);
        this.cellStyle = cellStyle;
        this.isFixed = isFixed;
        this.isTransposed = isTransposed;
        this.glassesCount = glassesCount;
        this.showCanceled = showCanceled;
        this.rowsCount = rowsCount;
    }

    static initial() {
        return new TimelineSettings('default', false, false, false, MIN_TIMELINE_ROWS_COUNT, 50);
    }

    @action
    setRowsCount = (v: number): void => {
        this.rowsCount = v; 
    };
    @action
    setGlassesCount = (v: number): void => {
        this.glassesCount = v;
    };
    @action
    setIsFixed = (v: boolean): void => {
        this.isFixed = v;
    };
    @action
    setIsTransposed = (v: boolean): void => {
        this.isTransposed = v;
    };
    @action
    setCellStyle = (v: CellContentStyle): void => {
        this.cellStyle = v;
    };
    @action
    setShowCanceled = (v: boolean): void => {
        this.showCanceled = v;
    };
}