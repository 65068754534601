import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import { RequestStatus } from "../../../../@types/requestStatus";
import api from "../../../../api/MainApi";
import { handleRequest } from "../../../../common/utility-types/helpers/handleRequest";
import { FilialService } from "../../domain/services/FilialService";
import { container } from "../../../../di/container";
import { Credential } from "../../../authentication/domain/entities/Credential";


export class FilialViewModal {
    private _credential: Credential;
    private _filial: FilialService;

    @observable
    citiesStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    errorText: string | null = null;

    constructor(credential: Credential = container.get(Credential),
        filial: FilialService = container.get(FilialService)) {
        this._credential = credential;
        this._filial = filial;
        makeAutoObservable(this);
    }

    @computed
    get filialData() {
        return this._filial;
    }

    @computed
    get credentialData() {
        return this._credential;
    }

    @action
    async availableCities(this: FilialViewModal, userId: number) {
        this.citiesStatus = RequestStatus.LOADING;

        (await handleRequest(async () => api.getInstances(userId)))
            .match({
                onLeft: (l) => {
                    runInAction(() => {
                        this.citiesStatus = RequestStatus.ERROR;
                        this.errorText = l.message;
                    });
                },
                onRight: (r) => {
                    runInAction(() => {
                        this._credential.setAvalibleCities(r.data);
                        this.citiesStatus = RequestStatus.SUCCESSFULL;
                    });
                }
            });
    }
}