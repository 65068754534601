import React, { useEffect, useMemo } from "react"
import { Control, UseFormClearErrors, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { IHookFormOrder } from "../../domain/entities/IHokFormOrder";
import { ClientValue, CreatedClient } from "../../../../api/models/Client";
import { AutoComplete, Button, Col, Row, message } from "antd";
import axios, { AxiosError } from "axios";
import { ErrorResponse } from "../../../../api/MainApi";
import useDebounce from "../../../../common/hooks/useDebounce";
import useHotRegister from "../../../../common/hooks/useHotRegister";
import ClientMapper from "../../../../common/mappers/ClientMapper";
import BookingModalService from "../../../../common/services/BookingModalService";
import { container } from "../../../../di/container";
import { TextFormater } from "../../../../common/hook-form-tools/textFormater";
import DefaultController from "../../../../common/presentation/DefaultController";
import ADD_ICON from '../../../../assets/add.svg';
import { Client } from "../../domain/types";


type WrapperBuilder = (args: {
    phoneField: React.ReactElement,
    hotRegButton: React.ReactElement | null
}) => React.ReactElement;

type PhoneWithHotRegFieldProps = {
    setValue: UseFormSetValue<IHookFormOrder>,
    control: Control<IHookFormOrder>,
    trigger: UseFormTrigger<IHookFormOrder>,
    clearErrors: UseFormClearErrors<IHookFormOrder>,
    client: Client | null,
    wrapper?: WrapperBuilder
}

const defaultWrapper: WrapperBuilder = ({ phoneField, hotRegButton }) => <Row gutter={[0, 20]} align='top'>
    <Col span={12} style={{ marginRight: '20px' }}>
        {phoneField}
    </Col>
    {hotRegButton && <Col span={2}> {hotRegButton} </Col>}
</Row>;

export const PhoneWithHotRegTextField: React.FC<PhoneWithHotRegFieldProps> = ({
    setValue,
    clearErrors,
    trigger,
    client,
    control,
    wrapper = defaultWrapper
}) => {
    const service = useMemo(() => new BookingModalService(), []);
    const { data, isEmpty, debounceFetcher } = useDebounce<Client>({ fetchCallback: (phone) => service.fetchClientsByPhone(phone), timeout: 500 })
    const hotRegister = useHotRegister({
        onError: onHotRegError,
        onSuccessfull: onHotRegSuccessfull,
    });

    const options = useMemo(() => ClientMapper.clientsToSelector(data), [data]);

    useEffect(() => {
        const phone = client?.phone;
        if (Boolean(phone) && phone) {
            service.fetchClientsByPhone(phone).then(client => setPersonalData(client[0]))
        }
    }, [])

    function onHotRegSuccessfull(client: CreatedClient) {
        debounceFetcher(client.phone);
        setPersonalData(client);
        message.success('Клиент успешно зарегистрирован');
    }

    function onHotRegError(error: Error | AxiosError<ErrorResponse>) {
        let errorText = 'Не удалось зарегистрировать клиента';
        if (axios.isAxiosError(error)) {
            errorText = error.response?.data.error_text;
        }
        trigger(['client.phone', 'client.name']);
        message.error(errorText);
    }

    function setPersonalData(client: Client | CreatedClient) {
        setValue('client.phone', client.phone);
        setValue('client.name', client.name);
        setValue(
            'client',
            {
                id: client.id,
                name: client.name,
                phone: client.phone
            }
        );
        trigger(['client.phone', 'client.name']);
        clearErrors('client');

    }

    const onChangePhone = (selectedOption: string, selectedClient: ClientValue | ClientValue[]) => {
        const currSelectedClient = selectedClient as ClientValue;
        setPersonalData(currSelectedClient.client);
    }


    const onAddBtnClick = () => {
        if (!client?.name || !client.phone) return;
        hotRegister([
            client.name,
            client.phone,
            ''
        ]);
    }


    return wrapper({
        phoneField: <DefaultController
            name={'client.phone'}
            rules={{
                required: { value: true, message: 'Это поле обязательно' },
            }}
            formater={TextFormater.combine([
                TextFormater.length('8 (###) ### ##-##'.length),
                TextFormater.digitsOnly(),
                TextFormater.asMask('8 (###) ### ##-##')
            ])}
            control={control}
            render={({ field }) => (
                <AutoComplete
                    {...field}
                    value={field.value as string}
                    style={{ width: '100%', textAlign: 'start' }}
                    showSearch
                    popupClassName={'first-plan-object'}
                    autoClearSearchValue={false}
                    // @ts-ignore
                    onSelect={onChangePhone}
                    filterOption={false}
                    onSearch={(phone) => debounceFetcher(phone)}
                    className="inverted-select default-select"
                    placeholder="Введите номер телефона"
                    options={options}
                />
            )}
        />,
        hotRegButton: isEmpty
            ? <Col span={2}>
                <div style={{
                    height: '44px',
                    width: '44px'
                }} className="gradient-border">
                    <Button
                        onClick={onAddBtnClick}
                        className="add-btn">
                        <img src={ADD_ICON} />
                    </Button>
                </div>
            </Col>
            : null
    });
}