import { Col, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import '../features/authentication/ui/AdminLoginStyles.css';
import { LoginViewModal } from '../features/authentication/ui/loginViewModal';
import { useNavigate } from 'react-router';
import api from '../api/MainApi';
import { ICity } from '../api/models/Login';
import { container } from '../di/container';
import { FilialService } from '../features/fillial/domain/services/FilialService';
import { FilialViewModal } from '../features/fillial/ui/view-models/FilialViewModel';
import { ADMIN_TIMELINE_PATH } from '../common/router/RouterConfiguration';
import Loader from '../common/presentation/Loader';
import { RequestStatus } from '../@types/requestStatus';
import { observer } from 'mobx-react';
import { GameDataSourse } from '../features/fillial/data/GamesDataSourse';
import { GameService } from '../api';


export const CitySelectPage: React.FC = observer(() => {

    const navigate = useNavigate();

    // const [selectedCity, setAvailableCities] = useState<ICity>();
    const [selectedCity, setSelectedCity] = useState<string>();
    const [error, setError] = useState<string | null>(null);

    // const filialVM = new FilialViewModal();
    const filialVM = useMemo(() => new FilialViewModal(), []);

    // const availableCities = auth.credential.availableCities;
    const availableCities = filialVM.credentialData.availableCities;
    const user = filialVM.credentialData.user;

    useEffect(() => {
        if (user) {
            filialVM.availableCities(user.id);
        }
    }, [user]);

    const confirm = () => {
        if (!selectedCity) {
            setError('Выберите город');
            return;
        }

        const newCity = availableCities.filter(c => c.name === selectedCity)[0];
        filialVM.filialData.setCity(newCity);

        localStorage.setItem('filial', JSON.stringify(newCity));
        navigate(ADMIN_TIMELINE_PATH);
    };

    const auth = new LoginViewModal();

    const logout = () => {
        auth.logout();
        navigate(-1);
    };

    const isUnavailable = useMemo(() => availableCities.length === 0 || availableCities.at(0) == null, [availableCities]);

    return (
        <Row className='white-bg' align='middle' justify='center'>
            <Col sm={24} md={20} lg={18} xl={16} className='modalBg'>
                <h2 className="loginTitle">Выберите город</h2>
                <Row justify='center'>
                    {error &&
                        <Col xs={24} sm={20} md={16} lg={14}>
                            <p className='error-message'>{error}</p>
                        </Col>}

                    <Col xs={24} sm={20} md={16} lg={14} className='city-select-list'>
                        {
                            filialVM.citiesStatus === RequestStatus.LOADING ?
                                <Loader color={'#4474e9'} size={32} />
                                :
                                
                                isUnavailable ?
                                    <div className='no-cities-tip'>нет доступных городов</div> :
                                    availableCities.map(city => {
                                        return (
                                            <div
                                                className={`city-selectable${city.name === selectedCity ? ' city-selected' : ''}`}
                                                key={city.code}
                                                onClick={() => setSelectedCity(city.name)}
                                            >
                                                {city.name}
                                            </div>);
                                    })
                        }
                    </Col>
                    <Col xs={24} sm={20} md={16} lg={14}>
                        <button
                            className="btn-block login-button signin"
                            onClick={confirm}
                        >
                            Вход
                        </button>
                    </Col>

                    <Col xs={24} sm={20} md={16} lg={14}>
                        <button
                            className="back-btn"
                            onClick={logout}
                        >
                            Назад
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
});

