import React, { useCallback, useMemo } from "react"
import useTimer from "../../../common/hooks/useTimer"

interface SessionTimerProps {
    onExpire: () => void
}

const SessionTimer: React.FC<SessionTimerProps> = ({onExpire}) => {

    const onExpireMemo = useCallback(onExpire, []);

    const timeLeft = useTimer({
        secondsToExpire: 30,
        onExpire: onExpireMemo
    });


    return (
        <div>{`До logot осталось: ${timeLeft}`}</div>
    )
}

export default SessionTimer;