

export default class MathHelper {

    
    static getUUID() {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    static getRandomValue(max: number, min: number): number {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    static getArrDifference<T>(arr1: Array<T>, arr2: Array<T>) {
        return arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
    }

    static getCurrArrDifference<T>(main: Array<T>, secondary: Array<T>): Array<T> {
        return main.filter(x => !secondary.includes(x));
    }

    static transposeMatrix(matrix: any[][]) {
        return matrix[0]
            .map((col, i) => matrix
                .map(row => row[i]));
    }

    static clamp(value: number, lower: number, upper: number) {
        if (value > upper) return upper;
        if (value < lower) return lower;
        return Math.abs(value);
    }

    static swap(array: any[], from: number, to: number) {
        const copiedArr = [...array];
        const tempValue = copiedArr[from];
        copiedArr[from] = copiedArr[to];
        copiedArr[to] = tempValue;
        return copiedArr;
    }

    static getHashFromStr(value: string): number {
        return[...value.split('')].reduce((acc, char) => {
            return (char.charCodeAt(0)*100 + ((acc << 5) - acc)) | 0;
        }, 0);
    }
}