import { Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Table, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OrdersPresentationProps } from "../../domain/configs/ordersViewConfig";
import { Without } from "../../../../@types/without";
import { ESourceStatus, IOrder } from "../../../booking/domain/entities/IOrder";
import { TabledBooking, TableViewModel, TabledOrder } from "../view-models/tableViewModel";
import { Observer, observer } from "mobx-react";
import ConfirmStatusTag from "./ConfirmStatusTag";
import { ConnectOrder } from "../../../booking/ui/providers/OrderProvider";
import editImg from '../../../../assets/edit.svg';
import editActive from '../../../../assets/editActive.svg';
import { StyledBookingTable, StyledDropdown } from "./RestyleTable";
import { EOrderStatus } from "../../../booking/domain/entities/EOrderStatus";
import DefaultController from "../../../../common/presentation/DefaultController";
import { TextFormater } from "../../../../common/hook-form-tools/textFormater";
import { PhoneWithHotRegTextField } from "../../../booking/ui/components/PhoneTextField";
import { EmployeeCodeInput } from "../../../booking/ui/components/EmployeeCodeInput";
import { SmartGuestCountController } from "../../../booking/ui/components/SmartGuestCountController";
import { SmartGameController } from "../../../booking/ui/components/SmartGameController";
import DefaultSpin from "../../../../common/presentation/DefaultSpin";
import { CommentController } from "../../../booking/ui/components/CommentController";
import moment from "moment";
import { EmployeeNameInput } from "../../../booking/ui/components/EmployeeNameInput";
import { OrderRemoteDataSource } from "../../../../api/services/OrderService";

import arrow from '../../../../assets/arrow-right.svg';
import { SmartPriceCalculator } from "../../../booking/ui/components/SmartPriceCalculator";
import { Order } from "../../../booking/domain/entities/Order";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import { container } from "../../../../di/container";
import { DATE_FORMAT } from "../../../../common/constants";
import styled from "styled-components";
import { OrderService } from "../../../booking/domain/services/OrderService";
import { TimelinePageViewModel } from "../view-models/timelinePageViewModel";
import { ETimelineType } from "../../domain/entities/TimelineType";

type ColType = {
    [k: string]: any,
    dataIndex: (keyof TabledOrder | 'contextMenu')
};

type ColExpandedType = {
    [k: string]: any,
    dataIndex: (keyof TabledBooking | 'remove')
};
const { Option } = Select;

const { confirm } = Modal;

export const OrdersTable: React.FC<OrdersPresentationProps> = observer(({
    edit,
    orders,
    save,
    cancel,
    isSaving,
}) => {

    const tableViewModel = useMemo(() => new TableViewModel(), []);

    const ordersRepository = container.get(OrdersRepository);
    const ordersService = container.get(OrderService);

    const isEditing = (record: TabledOrder) => {
        return ordersService.editableOrder?.id === record.id;
        // return tableViewModel.editingOrder?.id == record.id;
    };

    const onSave = useCallback((record: TabledOrder) => {
        const order = ordersRepository.getOrderById(record.id);
        console.log(order);
        save();
        ordersService.setEditableOrder(null);

        setTimeout(() => {
            if (order?.bookings[0].bookingDate) {
                fetchOrders(order?.bookings[0].bookingDate);
            }
        }, 1000);

    }, [save]);

    const fetchOrders = async (month: moment.Moment) => {
        const firstDayOfMonth = month.startOf('month').format(DATE_FORMAT);
        const lastDayOfMonth = month.endOf('month').format(DATE_FORMAT);

        await ordersRepository.getOrdersByPeriod(firstDayOfMonth, lastDayOfMonth);

    };

    const buildDeleteBooking = (bookingId: string) => () => {
        confirm({
            title: 'Вы действительно хотите удалить бронь?',
            icon: null,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk: () => edit(o => new Order({ ...o, bookings: o.bookings.filter(b => b.id != bookingId) })),
        });
    };
    const buildSaveEdit = (record: TabledOrder) => () => {
        confirm({
            title: 'Вы действительно хотите сохранить внесенные изменения?',
            icon: null,
            okText: 'Да',
            cancelText: 'Нет',
            onOk: () => onSave(record)
        });
    };
    const buildCancelEdit = (cancel: () => void) => () => {
        confirm({
            title: 'Вы действительно хотите отменить внесенные изменения?',
            icon: null,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk: () => cancel()
        });
    };


    const timelinePresenter = container.get(TimelinePageViewModel);

    const goToTimeline = (record: TabledOrder) => {
        const order = ordersRepository.getOrderById(record.id);
        console.log(order?.bookings[0].bookingDate);

        timelinePresenter.loadBookingByDate((order?.bookings[0].bookingDate ?? moment()).format(DATE_FORMAT));
        timelinePresenter.changeSelectedView(ETimelineType.TIMELINE);

        // if (order) {
        //     const mapOrder: Without<IOrder, Function> = {
        //         id: order.id,
        //         title: order.title,
        //         source: order.source,
        //         status: order.status,
        //         bookings: order.bookings,
        //         client: order.client,
        //         priceInfo: order.priceInfo,
        //         paymentInfo: order.paymentInfo,
        //         comment: order.comment,
        //         isSendMessage: order.isSendMessage,
        //         vipRoomBookings: order.vipRoomBookings,
        //         personalCode: order.personalCode,
        //         personal: order.personal
        //     };
        //     timelinePresenter.setEditableOrder(mapOrder as IOrder);
        //     timelinePresenter.setIsOpenSider(true);
        // }
    };


    const expandedRowRender = (orderRecord: TabledOrder, index: number, indent: number, expanded: boolean) => {

        const columns: ColExpandedType[] = [
            {
                title: 'Время',
                dataIndex: 'time',
                width: '33%',
            },
            {
                title: <span style={{ padding: '16px' }}>Игра</span>,
                dataIndex: 'game',
                width: '33%',
                render: (_: any, record: TabledBooking) => isEditing(orderRecord)
                    ? <SmartGameController bookingId={record.id} />
                    : <div>{record.game?.title ?? 'Не указано'}</div>
            },
            {
                title: 'Количество игроков',
                width: '33%',
                dataIndex: 'guestCount',
                render: (_: any, record: TabledBooking) => isEditing(orderRecord)
                    ? <SmartGuestCountController bookingId={record.id} allowIncrease={false} />
                    : <div>{`${record.guestCount} чел.`}</div>
            },

        ];

        if (isEditing(orderRecord)) columns.unshift({
            title: '',
            dataIndex: 'remove',
            width: '10%',
            render: (_: any, record: TabledBooking) => <Button onClick={buildDeleteBooking(record.id)}>Удалить</Button>
        });

        const data = (tableViewModel
            .tBookings.find(tbookings => tbookings.find(tb => tb.orderId == orderRecord.id)) ?? [])
            .map(tB => ({ ...tB, key: tB.id }))
            .sort((a, b) => +a.time.slice(0, 2) - +b.time.slice(0, 2));

        return <Observer>
            {() => <Table
                columns={columns}
                dataSource={data}
                pagination={false}
            />}
        </Observer>;
    };

    const [employeeName, setEmployeeName] = useState<string | null>(null);

    const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (!value) {
            setEmployeeName('0');
            return;
        }

        try {
            const response = await OrderRemoteDataSource.getPersonalByCode(parseInt(value));
            if (response.id) {
                setEmployeeName(response.name ?? null);
            }
        } catch (error) {
            message.error("Сотрудник не найден");
            setEmployeeName('0');
        }
    };

    const columns: ColType[] = [
        {
            title: '',
            dataIndex: 'contextMenu',
            width: '5%',
            render: (_: any, record: TabledOrder) => {
                const editable = isEditing(record);
                return editable
                    ? (
                        <StyledDropdown
                            menu={{
                                items: [
                                    {
                                        key: "4",
                                        label: <Button onClick={buildSaveEdit(record)} /*onClick={() => onSave(record)}*/ disabled={isSaving} className="default-btn" htmlType="submit">
                                            {isSaving
                                                ? <DefaultSpin />
                                                : 'Сохранить'
                                            }
                                        </Button>
                                    },
                                    {
                                        key: "5",
                                        label: <Button onClick={buildCancelEdit(cancel)} /*onClick={cancel}*/ className="default-btn default-btn-danger">
                                            Отменить
                                        </Button>
                                        // label: <Typography.Link onClick={cancel} style={{ marginRight: 8 }}>
                                        //     Отменить
                                        // </Typography.Link>
                                    },
                                ]
                            }}
                        >
                            <img src={isEditing(record) ? editActive : editImg} style={{ cursor: 'pointer', width: '20px' }} alt="edit" /></StyledDropdown>
                    )
                    :
                    (
                        <>
                            <img onClick={tableViewModel.buildOnEdit(record, edit)} title="Редактировать" src={isEditing(record) ? editActive : editImg} style={{ cursor: 'pointer', width: '20px' }} alt="edit" />
                            <Button onClick={() => goToTimeline(record)}>Go</Button>
                        </>
                        // <Typography.Link disabled={disabled} onClick={tableViewModel.buildOnEdit(record, edit)}>
                        //     Редактировать
                        // </Typography.Link>
                    );
            },
        },
        {
            title: 'Время',
            dataIndex: 'time',
            width: '15%',
            editable: true,
            defaultSortOrder: 'ascend',
            // sorter: (prev: TabledOrder, curr: TabledOrder) => moment(prev.time, 'HH:mm').diff(moment(curr.time, 'HH:mm'))
            sorter: (prev: TabledOrder, curr: TabledOrder) => {
                if (prev.status === EOrderStatus.REJECTED && curr.status !== EOrderStatus.REJECTED) {
                    return -1;
                } else if (prev.status !== EOrderStatus.REJECTED && curr.status === EOrderStatus.REJECTED) {
                    return 1;
                } else {
                    return moment(prev.time, 'HH:mm').diff(moment(curr.time, 'HH:mm'));
                }
            },
        },
        {
            title: 'Администратор',
            dataIndex: 'adminName',
            width: '25%',
            editable: true,
            render: (_: any, record: TabledOrder) => isEditing(record)
                ? <div style={{ display: 'flex', alignItems: 'flex-top', gap: '5px', width: '100%' }}>
                    <div style={{ display: 'flex', gap: '10px', width: '105%' }}>
                        <div style={{ width: '100%' }}>
                            <EmployeeCodeInput handleBlur={handleBlur} />
                        </div>
                        <img style={{ height: '40px', borderRadius: "15px", width: '20px' }} src={arrow} alt="arrow" />
                    </div>
                    <ConnectOrder >
                        {(orderApi) => <EmployeeNameInput {...orderApi} value={employeeName ?? '1'} />}
                    </ConnectOrder >
                </div>
                : <div>{record.adminName}</div>
        },
        {
            title: 'Название игры',
            dataIndex: 'games',
            width: '15%',
            editable: false,
            render: (v: any, record: TabledOrder, index: any) => {
                const games = Array.from(new Set(record.games?.map(g => g.title))).join(', ');
                return (
                    <div>
                        {games.length > 0 ? games : 'На месте'}
                    </div>
                );
            }
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phone',
            width: '15%',
            editable: false,
            render: (_: any, record: TabledOrder) => isEditing(record)
                ? <ConnectOrder >
                    {({ setValue, order, clearErrors, control, trigger }) => <PhoneWithHotRegTextField
                        clearErrors={clearErrors}
                        control={control}
                        trigger={trigger}
                        client={order?.client ?? null}
                        setValue={setValue}
                        wrapper={
                            ({ phoneField, hotRegButton }) => (
                                <Row gutter={[10, 0]}>
                                    <Col span={hotRegButton ? 16 : 24}>{phoneField}</Col>
                                    {hotRegButton && <Col span={8}>{hotRegButton}</Col>}
                                </Row>)
                        }
                    />}
                </ConnectOrder>
                : <div>{TextFormater.asMask('8 (###) ### ##-##')(record.phone)}</div>

        },
        {
            title: 'Отметка об СМС',
            dataIndex: 'isMessaged',
            width: '10%',
            render: (_: any, record: TabledOrder) => isEditing(record)
                ? <ConnectOrder>{
                    ({ control }) => <DefaultController
                        control={control}
                        name='isSendMessage'
                        render={
                            ({ field }) => <Checkbox {...field} checked={record.isMessaged} />
                        } />
                }</ConnectOrder>
                : record.isMessaged ? 'Да' : 'Нет',
            editable: false,
        },
        {
            title: 'Кол-во человек',
            dataIndex: 'guestCount',
            width: '10%',
            editable: true,
        },
        {
            title: 'Имя клиента',
            dataIndex: 'clientName',
            width: '15%',
            editable: false,
            render: (_: any, record: TabledOrder) => isEditing(record)
                ? <div style={{ width: '100%' }}>
                    <ConnectOrder>
                        {({ control }) => <DefaultController
                            name={'client.name'}
                            control={control}
                            rules={
                                { required: { value: true, message: 'Это поле обязательно' } }
                            }
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Имя"
                                    bordered={false}
                                    className="default-input" />
                            )}
                        />}
                    </ConnectOrder>
                </div>
                : <div>{record.clientName}</div>
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            width: '15%',
            editable: true,
            render: (_: any, record: TabledOrder) => isEditing(record)
                ? <CommentController />
                : record.comment
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            width: '15%',
            editable: true,
            render: (_: any, record: TabledOrder) => {
                const isUnavailableChangeStatus = tableViewModel.editingOrder?.source == ESourceStatus.ADMIN && tableViewModel.editingOrder.status == EOrderStatus.CREATED;
                if (!isEditing(record) || isUnavailableChangeStatus) return <div style={{ padding: 10 }}><ConfirmStatusTag status={record.status} /></div>;
                return <ConnectOrder>
                    {({ control }) => <DefaultController
                        control={control}
                        name='status'
                        render={({ field }) => <Select
                            {...field}
                            value={field.value}
                            style={{ height: '42px', width: '90%' }}>
                            <Option value={EOrderStatus.CONFIRMED}>Подтвержденный</Option>
                            <Option value={EOrderStatus.REJECTED}>Отмененный</Option>
                            <Option value={EOrderStatus.CREATED}>Создано</Option>
                            <Option value={EOrderStatus.PROCESSING}>Предварительно</Option>
                        </Select>} />}
                </ConnectOrder>;
            }
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            width: '10%',
            editable: false,
            render: (_: any, record: TabledOrder) => {
                return isEditing(record)
                    ? <SmartPriceCalculator />
                    : <div >{record.price ?? 'Не подсчитано'}</div>;
                // return <ConnectOrder>
                //     {({ control }) => <DefaultController
                //         control={control}
                //         name='status'
                //         render={({ field }) => <Select
                //             {...field}
                //             value={field.value}
                //             style={{ height: '42px', width: '90%' }}>
                //             <Option value={EOrderStatus.CONFIRMED}>Подтвержденный</Option>
                //             <Option value={EOrderStatus.REJECTED}>Отмененыый</Option>
                //         </Select>} />}
                // </ConnectOrder>
            }
        },
    ];

    useEffect(() => tableViewModel.updateOrders(orders ?? []), [orders, ordersRepository.ordersPeriod]);

    return (
        <StyledBookingTable
            bordered
            showSorterTooltip={false}
            dataSource={tableViewModel.tOrders.map(tO => ({ ...tO, key: tO.id }))}
            expandable={{
                expandedRowRender,
            }}
            columns={columns}
            tableLayout='fixed'
            pagination={false}
        />
    );
});