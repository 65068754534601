import { memo, useMemo } from 'react';
import { TCellData } from '../types';
import { shallowEqual } from '../utils/shallowEqual';
import { TBodyCellId } from '../utils/constants';

interface _TCellProps<T> {
    tCellData: TCellData<T | T[] | null>,
    builder?: (data: T | T[] | null) => React.ReactNode
}
//TODO fix rerender (because of cellBuilder)
const _TCell = <T extends object>(props: _TCellProps<T>) => {

    const { builder, tCellData } = props

    const tContent = useMemo(() => {
        if (builder) return builder(tCellData?.data ?? null)
        if (tCellData.data != null) return tCellData.data.toString();
        return null;
    }, [tCellData.data]);
    
    return (
        <td key={tCellData?.id ?? ''} id={`${TBodyCellId}/${tCellData?.id ?? ''}`}>
            {tContent}
        </td>
    )
}

export const TCell = memo(_TCell, (p, c) => {
    return shallowEqual(p.tCellData, p.tCellData) && p.builder == c.builder
}) as typeof _TCell;