import { ReactElement, JSXElementConstructor } from "react";
import { ControllerRenderProps, FieldValues, ControllerFieldState, UseFormStateReturn } from "react-hook-form";
import DefaultController from "../../../../common/presentation/DefaultController";
import { ConnectOrder } from "../providers/OrderProvider";
import { Input } from "antd";

export const SmartPriceCalculator: React.FC = () => {
    return (
        <ConnectOrder>
            {({ control }) => <DefaultController
                control={control}
                name={'paymentInfo.priceFormula'}
                render={({field}) => <Input
                    {...field}
                    placeholder="Формула"
                    value={(field.value)?.toString()}
                    bordered={false}
                    className='default-input' />}

            />}
        </ConnectOrder>
    );
}