import moment from "moment";
import { Without } from "../../../@types/without";
import { BookingRequest, FullOrderRequest } from "../../../api";
import { IBooking } from "../domain/entities/IBooking";
import { IOrder } from "../domain/entities/IOrder";
import { EOrderStatus } from "../domain/entities/EOrderStatus";

export const bookingToDto = (booking: IBooking): BookingRequest => ({
    id: booking.id,
    time: booking.bookingDate?.format(moment.defaultFormatUtc) ?? '',
    gameId: booking.game?.id,
    guestCount: booking.guestCount && +booking.guestCount,
    gameTypeId: booking.gameTypeId ?? -1,
    sortIndex: booking.rowCellId,
    
});

export const orderToDto = (order: IOrder): FullOrderRequest => ({
    title: order.title,
    client: {
        id: order.client?.id, 
        phone: order.client?.phone,
        name: order.client?.name,
    },
    status: order.status != EOrderStatus.CREATED ? order.status.toString() : undefined,
    bookings: order.bookings.map(bookingToDto),
    paymentInfo: {
        bonus: null,
        promoCode: null,
        certificates: [],
        formula: order.paymentInfo?.priceFormula ?? ''
    },
    comment: order.comment,
    isMessageSent: order.isSendMessage,
    personalCode: order.personalCode,
    vipRoomBookings: order.vipRoomBookings
});
