import { Modal } from "antd"
import { ModalFunc } from "antd/lib/modal/confirm";
import SessionTimer from "./SessionTimer"


type ModalType = ReturnType<typeof Modal.info>;

interface OpenSessionModalArgs {
    onTimeExpire: () => void,
    onOk: () => void
}


export class SessionModal {
    private static modal: ModalType | null = null;

    static initModal() {
        SessionModal.modal = Modal.info({
            open: false,
            okText: <div>Отменить</div>
        });
    }

    static close() {
        if (!SessionModal.modal) return;
        SessionModal.modal.destroy();
    }

    static open(args: OpenSessionModalArgs) {
        const { onOk, onTimeExpire } = args;
        const onExpire = () => {
            SessionModal.close();
            onTimeExpire();
        }
        if (!SessionModal.modal) SessionModal.initModal();
        SessionModal.modal?.update({
            open: true,
            title: 'Время бездействия превышено',
            content: <SessionTimer onExpire={onExpire} />,
            onOk: onOk
        })
    }
}

// export const callSessionModal = (args: OpenSessionModalArgs) => {
//     const { onOk, onTimeExpire } = args;
//     const onExpire = () => {
//         closeSessionModal();
//         onTimeExpire();
//     }
//     Modal.info({
//         title: 'Время бездействия превышено',
//         content: <SessionTimer onExpire={onExpire} />,
//         onOk: onOk
//     })
// }

// export const closeSessionModal = () => {
//     Modal.info({ open: false });
// }
