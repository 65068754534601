

const IDLE_INTERVAL = 1_800_000;
type OnIdle = () => void;

class SessionService {

    private static intervalId: NodeJS.Timeout | null = null;
    private static onIdle: OnIdle | null = null;

    private static rebuildInterval() {
        const onIdle = SessionService.onIdle;
        if (onIdle == null) throw Error('You should init sessionService before use');
        if (SessionService.intervalId != null) clearInterval(SessionService.intervalId);
        SessionService.intervalId = setInterval(onIdle, IDLE_INTERVAL);
    }

    static init(onIdle: OnIdle) {
        SessionService.onIdle = onIdle;
        SessionService.start();
        SessionService.rebuildInterval();
    }

    static pause() {
        document.removeEventListener('mousemove', SessionService.rebuildInterval, { capture: false });
        if (SessionService.intervalId != null) clearInterval(SessionService.intervalId);
    }

    static start() {
        document.addEventListener('mousemove', SessionService.rebuildInterval, { capture: false });
        if(SessionService.onIdle != null) SessionService.intervalId = setInterval(SessionService.onIdle, IDLE_INTERVAL);
    }

    static dispose() {
        if (SessionService.onIdle) SessionService.onIdle = null;
        SessionService.pause();
    }
}

export default SessionService;