import { Moment } from "moment";
import { IBooking } from "./IBooking";
import { Without } from "../../../../@types/without";
import { action, computed, makeObservable, observable } from "mobx";
import MathHelper from "../../../../common/utility-types/helpers/MathHelper";
import { Booking as BookingDto } from "../../../../api";
import moment from "moment";
import { Game } from "../../../fillial/domain/entities/Game";
import { EBookingStatus } from "./EBookingStatus";
import { ESourceStatus } from "./IOrder";

export class Booking implements IBooking {
    @observable
    id: string;
    @observable
    bookingDate: Moment | null;
    @observable
    roomId?: number;
    @observable
    guestCount?: number;
    @observable
    rowCellId: number;
    @observable
    orderId: string;
    @observable
    status: EBookingStatus;
    @observable
    gameTypeId?: number;
    @observable
    game: Game | null;
    @observable
    isEditing: boolean;
    @observable
    orderSourceStatus: ESourceStatus;

    constructor(booking: Without<IBooking, Function>) {
        this.id = booking.id;
        this.bookingDate = booking.bookingDate;
        this.game = booking.game ?? null;
        this.guestCount = booking.guestCount;
        this.status = booking.status;
        this.gameTypeId = booking.gameTypeId;
        this.orderId = booking.orderId;
        this.isEditing = booking.isEditing;
        this.orderSourceStatus = booking.orderSourceStatus;
        this.rowCellId = booking.rowCellId;
        makeObservable(this);
    }
    createdAt?: string | undefined;
    setIsSelected(): void {
        throw new Error("Method not implemented.");
    }


    static fromDto(booking: BookingDto & { game: Game | null, source: ESourceStatus }) {
        const statusFromDto = (status: string): EBookingStatus => {
            switch (status) {
                case 'Proccesing':
                    return EBookingStatus.PROCESSING;
                case 'Confirmed':
                    return EBookingStatus.CONFIRMED;
                case 'Rejected':
                    return EBookingStatus.REJECTED;
                default:
                    return EBookingStatus.PROCESSING;

            }
        };
        return new Booking({
            id: booking.id ?? '',
            bookingDate: moment(booking.bookingDate, moment.defaultFormatUtc) ?? null,
            gameTypeId: booking.gameTypeId,
            guestCount: booking.guestCount,
            orderId: booking.orderId ?? '',
            game: booking.game,
            status: statusFromDto(booking.status ?? 'Proccesing'),
            orderSourceStatus: booking.source,
            isEditing: false,
            rowCellId: booking.sortIndex
        });
    }

    static initial(args: { time: moment.Moment, orderId: string, id: string, rowIndex: number }) {
        const { time, orderId, id, rowIndex } = args;
        return new Booking({
            id: id ?? '',
            bookingDate: time,
            gameTypeId: undefined,
            guestCount: undefined,
            orderId: orderId,
            game: null,
            status: EBookingStatus.CONFIRMED,
            orderSourceStatus: ESourceStatus.ADMIN,
            isEditing: true,
            rowCellId: rowIndex
        });
    }

    static isValid(booking: Without<IBooking, Function>) {
        return booking.game != null &&
            booking.guestCount != null &&
            booking.guestCount.toString().length > 0 &&
            booking.bookingDate != null &&
            booking.game.id != '';
    }

    static isArrBookingsEqual(fBookings: IBooking[], sBookings: IBooking[]) {
        if (fBookings.length != sBookings.length) return false;
        const sortedFBookings = fBookings.toSorted((prev, next) => prev.bookingDate?.diff(next.bookingDate ?? moment()) ?? 0);
        const sortedSBookings = sBookings.toSorted((prev, next) => prev.bookingDate?.diff(next.bookingDate ?? moment()) ?? 0);
        return sortedFBookings.map((booking, index) => Booking.isBookingEqual(booking, sortedSBookings[index])).reduce((prev, next) => prev && next, true);
    }

    static isBookingEqual(fBooking: Without<IBooking, Function>, sBooking: Without<IBooking, Function>) {
        return fBooking.id == sBooking.id &&
            fBooking.game?.id == sBooking.game?.id &&
            fBooking.guestCount == sBooking.guestCount &&
            fBooking.orderId == sBooking.orderId;
    }

    @action
    setGuestCount = (count: number): void => {
        const selectedGame = this.game;
        this.guestCount = MathHelper.clamp(count, 0, 10);
    };

    @action
    setStatus = (status: EBookingStatus): void => {
        this.status = status;
    };

    @action
    selectGame = (game: Game): void => {
        this.game = game;
        this.gameTypeId = game.gameTypeId;
    };

    @action
    setDate = (date: moment.Moment): void => {
        this.bookingDate = date;
    };

    equals = (other: IBooking | null): boolean => {
        if (other! instanceof Booking) return false;
        return (this.bookingDate?.isSame(other?.bookingDate, 'hours') ?? false) &&
            // this.game?.id == other?.game?.id &&
            // this.guestCount == other?.guestCount &&
            this.id == other?.id &&
            this.game?.id == other.game?.id &&
            this.orderId == other.orderId;
        // this.isEditing == other.isEditing;
    };

}
