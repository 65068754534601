import { action, computed, makeObservable, observable } from "mobx";
import { RequestStatus } from "../../../../@types/requestStatus";
import { container } from "../../../../di/container";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import { Without } from "../../../../@types/without";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import moment from "moment";
import { LoadTimelineUseCase } from "../../domain/use-cases/loadTimelineUseCase";

interface DateOrdersWithStatus {
    getOrdersStatus: RequestStatus;
    orders: Without<IOrder, Function>[]
}

export class CollapsedOrdersTableViewModel {
    @observable
    private _loadOrderStatusesMap: Map<string, DateOrdersWithStatus> = new Map();
    private _loadTimelineUseCase: LoadTimelineUseCase = new LoadTimelineUseCase();

    @observable
    focusedDate: moment.Moment = moment();

    dateFormat: string = 'DD:MM:YYYY';

    constructor(
        private _ordersRepository = container.get(OrdersRepository),
    ) { 
        makeObservable(this);
    }

    @computed
    get ordersDateMap() {
        return this._loadOrderStatusesMap;
    }

    @computed
    get orders() {        
        return this._ordersRepository.orders;
    }

    @action
    updateMap = (date: moment.Moment, info: DateOrdersWithStatus) => {
        
        this._loadOrderStatusesMap = new Map([...Array.from(this._loadOrderStatusesMap), [date.format(this.dateFormat), {...info}]]);
        //  set(
        //     date.format(this.dateFormat),
        //     info
        // );
    }


    @action
    updateFocusedDate = (date: moment.Moment) => {
        this.focusedDate = date;
    }

    @action
    onOpenDate = async (date: moment.Moment) => {
        if (date.isSame(this.focusedDate, 'date')) return;
        const ordersOfDate = this._loadOrderStatusesMap.get(date.format(this.dateFormat))?.orders;
        const savedOrders = this._ordersRepository.orders.filter(o => o.bookings.find(b => b.bookingDate?.isSame(date, 'date')));
        if (ordersOfDate) return;
        if (savedOrders.length > 0) this.updateMap(date, { orders: savedOrders, getOrdersStatus: RequestStatus.SUCCESSFULL });
        else {
            this.updateMap(date, { getOrdersStatus: RequestStatus.LOADING, orders: ordersOfDate ?? []});
            (await this._loadTimelineUseCase.execute(date));
            this.updateMap(date, {
                getOrdersStatus: RequestStatus.SUCCESSFULL,
                orders: this._ordersRepository.orders.filter(o => o.bookings.at(0)?.bookingDate?.isSame(date, 'date'))
            })
        }   
    }
}