import { LabeledValue } from "antd/lib/select";
import ClientMapper from "../mappers/ClientMapper";
import api from "../../api/MainApi";
import { container } from "../../di/container";
import { Credential } from "../../features/authentication/domain/entities/Credential";

//Вынести в запрос
class BookingModalService {

    constructor(
        private credential: Credential = container.get(Credential)
    ) { }

    async fetchClientsByPhone(this: BookingModalService, phone: string) {
        const phineNumeric = phone.replace(/\D+/g, '');
        const phoneWithoutFirst = phineNumeric.replace(/^(8|\+7)/, '');
        if (phoneWithoutFirst.length < 2) return [];
        const response = await api.getClientsByPhone(phoneWithoutFirst, this.credential.authToken ?? '');
        return response.data;
    }
}

export default BookingModalService;