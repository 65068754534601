import moment from "moment";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { BookingCell } from "../entities/BookingCell";
import { IBookingCell } from "../entities/IBookingCell";
import { ITimeline } from "../entities/ITimeline";
import { ITimelineColumn } from "../entities/ITimelineColumn";
import { TimelineColumn } from "../entities/TimelineColumn";
import { ITimelineParams } from "../entities/TimelineParams";
import { ITimelineOrderAdapter } from "./ITimelineOrderAdapter";
import { Timeline } from "../entities/Timeline";
import { MIN_TIMELINE_ROWS_COUNT } from "./constatnts";
import { TimelineInfo } from "../../../../api/models/OrderForDayResponse";
import { Without } from "../../../../@types/without";

export class DashboardOrderAdapter implements ITimelineOrderAdapter {
    // TODO оптимизировать (кэшировать по коллонкам как статик поле)
    fromOrders = (orders: Without<IOrder, Function>[], params: TimelineInfo[]): Timeline => {
        const bookings = orders.map(value => value.bookings).reduce((prev, next) => [...prev, ...next], []);
        const settings = JSON.parse(localStorage.getItem('settings') ?? '{}');
        const defaultRowsCount = settings.rowsCount ?? MIN_TIMELINE_ROWS_COUNT;
        let cellsLength = defaultRowsCount;
        const vipBookings = orders.map(value => value.vipRoomBookings ?? []).reduce((prev, next) => [...prev, ...next], []);

        const cols: Map<string, ITimelineColumn> = new Map(params.map(param => ([
            moment(param.time).format(moment.defaultFormat),
            new TimelineColumn({
                time: moment(param.time).format(moment.defaultFormat),
                cells: Array.from({ length: cellsLength }).map((_, index) => BookingCell.empty({
                    time: moment(param.time).format(moment.defaultFormat),
                    additionalId: index
                })),
                glassesCount: param.info?.deviceCount ?? 0,
                columnState: 'default',
                rowsCount: 0
            })
        ] as [string, ITimelineColumn])));

        // console.log('=======COLS ========', cols);


        for (const booking of bookings) {
            if (!booking.bookingDate) continue;
            const time = booking.bookingDate.format(moment.defaultFormat);
            // console.log(booking.rowCellId);

            let bookingCell = cols.get(time)?.cells.at(booking.rowCellId);

            if (!bookingCell) {
                // TODO fix to handle overindex cell
                cols.forEach(col => col.updateCells([...col.cells, BookingCell.empty({ time: col.time, additionalId: col.cells.length })]));
                const col = cols.get(time);
                bookingCell = col?.cells[col.cells.length - 1];
            }

            // bookingCell?.setBooking(booking);

            // cols.forEach((col) => {
            //     if (col.cells[col.cells.length - 2].booking !== null) {

            //         // console.log("COL ", col.cells[col.cells.length - 2].booking);
            //         // cellsLength += 2;
            //         return col.updateCells([...col.cells, BookingCell.empty({ time: col.time, additionalId: cellsLength })]);
            //     }
            // });

            if (bookingCell) {
                bookingCell.setBooking(booking);


                const vipBooking = vipBookings.filter(vip => {
                    const vipStartTime = moment(vip.startTime);
                    const vipEndTime = moment(vip.endTime);
                    const bookingStartTime = moment(booking.bookingDate);
                    const bookingEndTime = moment(booking.bookingDate).add(1, 'hours');

                    return vip.orderId === booking.orderId && (
                        (bookingStartTime.isSameOrAfter(vipStartTime) && bookingStartTime.isBefore(vipEndTime)) ||
                        (bookingEndTime.isAfter(vipStartTime) && bookingEndTime.isSameOrBefore(vipEndTime))
                    );
                });
                bookingCell.setVipRoomBooking(vipBooking ?? null);
            }
        }

        return Timeline.create({ columnsMap: cols });
    };
}