import { action, makeObservable, observable } from "mobx";
import { Without } from "../../../../@types/without";
import { IPaymentInfo } from "./IPaymentInfo";
import { PaymentInfo  as PI}   from "../../../../api" ;

export class PaymentInfo implements IPaymentInfo {
    @observable
    bonus: number | null;
    @observable
    promocode: string | null;
    @observable
    certificates: string[];
    @observable
    priceFormula: string;

    constructor(loyaltyInfo: Without<IPaymentInfo, Function> ) {
        this.bonus = loyaltyInfo.bonus;
        this.promocode = loyaltyInfo.promocode;
        this.certificates = loyaltyInfo.certificates;
        this.priceFormula = loyaltyInfo.priceFormula;
        makeObservable(this);
    }

    static fromDto(lInfo: PI | null) {
        if(lInfo == null) return new PaymentInfo({
            bonus: null,
            promocode: null,
            certificates: [],
            priceFormula: ''
        });
        return new PaymentInfo({
            bonus: lInfo.bonus ?? null,
            promocode: lInfo.promocode ?? null,
            certificates: lInfo.certificates ?? [],
            priceFormula: lInfo.formula
        });
    }

    @action
    setPromocode = (promocode: string): void => {
        this.promocode = promocode;
        this.bonus = null;
    }

    @action
    setBonus = (bonus: number): void => {
        this.bonus = bonus;
        this.promocode = null;
    }

    private addCertificate = (certificate: string) => {
        const isDuplicate = ~this.certificates.findIndex(c => c == certificate);
        if(!isDuplicate) this.certificates.push(certificate);
    }

    @action
    addCertificates = (certificates: string[]): void =>{
        certificates.forEach(c => this.addCertificate(c));
    }

    @action
    removeCertificates = (certificates: string[]): void => {
        throw new Error("Method not implemented.");
    }
    
}