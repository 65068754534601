import React, { useCallback, useContext, useMemo } from "react";
import { ITimeline } from "../../../timeline/domain/entities/ITimeline"
import { ESourceStatus, IOrder } from "../../domain/entities/IOrder";
import { UseFormReturn } from "react-hook-form";
import { IHookFormOrder } from "../../domain/entities/IHokFormOrder";
import { Observer, observer } from "mobx-react-lite";
import { toHookFormOrder } from "../../adapters/hookFormAdapter";
import { container } from "../../../../di/container";
import { OrderService } from "../../domain/services/OrderService";
import { EOrderStatus } from "../../domain/entities/EOrderStatus";
import { defaultOrder } from "../../domain/entities/Order";




type OrderingProviderContextType = {
    order: IOrder | null,
    edit: (a: (order: IOrder) => Partial<IOrder> | null) => void
    orderFormApi: UseFormReturn<IHookFormOrder>
}

type ConnectableChildFC = React.FC<Pick<UseFormReturn<IHookFormOrder, any>, 'control' | 'setValue' | 'trigger' | 'clearErrors' | 'resetField'> & {
    order: IOrder | null,
    setOrder: (order: IOrder | null) => void
}>
type ConnectOrderProps = {
    children: ConnectableChildFC
}

const OrderingContext = React.createContext<OrderingProviderContextType>({} as OrderingProviderContextType);
export const OrderingProvider = OrderingContext.Provider;
export const useOrdering = () => useContext(OrderingContext);




export const ConnectOrder = observer(({ children }: ConnectOrderProps) => {
    const orderApi = useOrdering();
    const orderService = container.get(OrderService);
    const order = useMemo(() => orderApi?.order, [orderApi?.order])
    const setValue = useCallback(orderApi.orderFormApi.setValue, [orderApi?.orderFormApi.setValue]);
    const resetField = useCallback(orderApi.orderFormApi.resetField, [orderApi?.orderFormApi.resetField]);
    const control = useMemo(() => orderApi.orderFormApi.control, [orderApi?.orderFormApi.control]);
    const trigger = useCallback(orderApi.orderFormApi.trigger, [orderApi.orderFormApi.trigger]);
    const clearErrors = useCallback(orderApi.orderFormApi.clearErrors, [orderApi.orderFormApi.clearErrors]);
    const setOrder = useCallback((order: IOrder | null) => {
        const hookFormOrder = toHookFormOrder(order ?? defaultOrder );
        // orderService.update({id: order?.id, priceInfo: order?.priceInfo, source: order?.source, status: order?.status});
        orderApi.edit((_) => order)
        // console.log(hookFormOrder.client?.id);
        // setValue('bookings', hookFormOrder.bookings);
        // setValue('client.id', hookFormOrder.client?.id ?? -1);
        // setValue('client.name', hookFormOrder.client?.name ?? '');
        // setValue('client.phone', hookFormOrder.client?.phone ?? '');
        // setValue('orderStatus', order?.status ?? EOrderStatus.PROCESSING);
        // setValue('loyaltyInfo', hookFormOrder.loyaltyInfo);    
    }, [orderApi.orderFormApi])

    return <Observer>
        {() => (children({ order, setValue, control, trigger, clearErrors, setOrder, resetField }))}
    </Observer>
})