import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

interface IProps {
    optionName: string | JSX.Element;
    optionFunc: () => void;
}

const useContextMenu = () => {

    const [isShown, setIsShown] = useState(false);

    const [position, setPosition] = useState({ x: 0, y: 0 });

    const optionsRef = useRef<Array<IProps>>();

    const windowInnerWidth = document.documentElement.clientWidth;
    const windowInnerHeight = document.documentElement.clientHeight;

    const sizeRef = useRef<HTMLDivElement>(null);

    const showContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement>, options: Array<IProps>) => {
        event.preventDefault();

        optionsRef.current = options;
        setIsShown(false);
        let newPosition: { x: number, y: number };

        if (windowInnerHeight - position.y < (sizeRef.current?.offsetHeight ?? 130)) {
            newPosition = {
                x: position.x,
                y: position.y - (sizeRef.current?.offsetHeight ?? 130)
            };
        } else {
            newPosition = {
                x: event.pageX,
                y: event.pageY,
            };
        }


        setPosition(newPosition);
        setIsShown(true);
    }, [position]);


    const hideContextMenu = useCallback(() => {
        if (isShown) {
            setIsShown(false);
        }
    }, [isShown]);

    // const closedElements = [...document.querySelectorAll('.cell__container > div'), document.body];

    // closedElements.forEach(element => {
    //     element.addEventListener('click', hideContextMenu);
    // });

    // useEffect(() => {
    //     const tableScroll = document.querySelector('.TableWrapper');
    //     tableScroll?.addEventListener('scroll', hideContextMenu);

    //     return () => {
    //         // scrollRef.current?.removeEventListener('scroll', hideContextMenu);
    //         tableScroll?.removeEventListener('scroll', hideContextMenu);
    //     };
    // }, []);

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollRef.current?.addEventListener('wheel', hideContextMenu);

        if (windowInnerWidth - position.x < (sizeRef.current?.offsetWidth ?? 120)) {

            const newPosition = {
                x: position.x - (sizeRef.current?.offsetWidth ?? 120),
                y: position.y
            };

            setPosition(newPosition);
        }

        if (windowInnerHeight - position.y < (sizeRef.current?.offsetHeight ?? 130)) {

            const newPosition = {
                x: position.x,
                y: position.y - (sizeRef.current?.offsetHeight ?? 130)
            };

            setPosition(newPosition);
        }

        return () => {
            scrollRef.current?.removeEventListener('wheel', hideContextMenu);
        };
    }, [isShown]);

    const onCloseClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isShown) {
            setIsShown(false);

        }
    };

    const onClick = (option: IProps) => {
        option.optionFunc();
        setIsShown(false);
    };

    const menu: JSX.Element = (
        <StyledWrapper
            ref={scrollRef}
            onClick={(e) => onCloseClick(e)}
            onContextMenu={() => console.log("ON WRAPP")}
        >
            <StyledMenu
                style={{ top: position.y, left: position.x }}
                className="custom-context-menu"
                ref={sizeRef}
            >
                {
                    optionsRef.current?.map((option, index) => (
                        <div className="option" key={index} onClick={() => onClick(option)}>
                            {option.optionName}
                        </div>
                    ))
                }
            </StyledMenu >
        </StyledWrapper>
    );

    return { isShown, menu, showContextMenu, hideContextMenu };

};

export default useContextMenu;

export const BookingContext = createContext<ReturnType<typeof useContextMenu>>(
    { isShown: false, menu: <></>, showContextMenu: () => { }, hideContextMenu: () => { } }
);

// export const BookingContext = createContext(useContextMenu);

const StyledWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
`;

const StyledMenu = styled.div`

    position: fixed;
    z-index: 10;
    background: rgba(68, 70, 86, 1);
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px;

    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.002em;

    /* display: flex;
    flex-direction: column; */
  
  .option {
    cursor: pointer;
    padding: 10px 20px;

    /* border: none;
    outline: none;
    background-color: transparent;
    font-weight: 500; */
  }
  
  .option:hover {
    background: rgba(75, 81, 234, 1);
    border-radius: 15px;
  }`;