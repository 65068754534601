import { useCallback } from "react";
import { TimelineConnect } from "../../../timeline/ui/providers/TimelineConnect";
import GuestCountController from "./GuestCountController";
import { IHookFormBooking } from "../../domain/entities/IHokFormOrder";
import { ConnectOrder } from "../providers/OrderProvider";
import { IBooking } from "../../domain/entities/IBooking";
import moment from "moment";

type SmartGuestCountControllerProps = {
    bookingId: string | number,
    allowIncrease?: boolean
};

export const SmartGuestCountController: React.FC<SmartGuestCountControllerProps> = ({ bookingId, allowIncrease }) => {

    const getCurrBookingIndex = useCallback(
        (bookings: IHookFormBooking[] | IBooking[]) => bookings.findIndex(booking => booking.id == bookingId),
        [bookingId]
    );

    const getCurrBooking = useCallback(
        (bookigns: IHookFormBooking[] | IBooking[]) => bookigns.at(getCurrBookingIndex(bookigns)),
        [bookingId]
    );

    const getUsedGlassesCountAtTime = (bookings: IHookFormBooking[] | IBooking[]) => {
        return bookings
            .filter(b => b.id != getCurrBooking(bookings)?.id && moment(b.bookingDate).isSame(moment(getCurrBooking(bookings)?.bookingDate), 'h'))
            .map(booking => booking.guestCount ?? 0)
            .reduce((prev, next) => prev + +next, 0);
    };

    const getRemainingGlassesCount = (
        bookings: IHookFormBooking[] | IBooking[],
        columnsMap: Map<string, number>
    ) => {
        const glassesCount = columnsMap.get(moment(getCurrBooking(bookings)?.bookingDate).format(moment.defaultFormatUtc)) ?? 60;
        const usingGlasses = getUsedGlassesCountAtTime(bookings);
        return glassesCount - usingGlasses;
    };

    return (
        <TimelineConnect>
            {({ columnsMap }) => (
                <ConnectOrder>
                    {({ control, order, setValue }) => <GuestCountController
                        control={control}
                        booking={order?.bookings[getCurrBookingIndex(order?.bookings ?? [])]}
                        setValue={setValue}
                        remainingGlassesCount={getRemainingGlassesCount(order?.bookings ?? [], columnsMap)}
                        bookingIndex={getCurrBookingIndex(order?.bookings ?? [])}
                        defaultValue={order?.bookings.find(v => v.guestCount)?.guestCount}
                        allowIncrease={allowIncrease}
                    />}
                </ConnectOrder>
            )}
        </TimelineConnect>
    );
};