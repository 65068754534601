import React, { useEffect, useMemo } from "react";
import { Navigate } from "react-router";
import api from "../../../api/MainApi";
import { observer } from "mobx-react-lite";
import { container } from "../../../di/container";
import { Credential } from "../../../features/authentication/domain/entities/Credential";
import { AUTH_PATH } from "../RouterConfiguration";

interface GuardProps {
    element: React.ReactElement,
    credential?: Credential
}

const AuthGuard: React.FC<GuardProps> = observer(({ element, credential = container.get(Credential) }) => {

    const isAuthorized = useMemo(() => credential.isAuth, [credential.isAuth]);

    return (
        credential.authToken
            ? element
            : <Navigate to={AUTH_PATH} replace />
    );
});

export default AuthGuard;