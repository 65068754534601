import React, { useMemo } from 'react';
import { EOrderStatus } from '../../../booking/domain/entities/EOrderStatus';
import { EBookingStatus } from '../../../booking/domain/entities/EBookingStatus';



const ConfirmStatusTag: React.FC<{ status: EBookingStatus | EOrderStatus }> = ({ status }) => {


    const statusStyle = useMemo((): React.CSSProperties => {
        switch (status) {
            case EBookingStatus.CONFIRMED | EOrderStatus.CONFIRMED:
                return {
                    backgroundColor: '#EEF9F1',
                    border: '1px solid #65BD79',
                    color: '#65BD79'
                };
            case EBookingStatus.REJECTED | EOrderStatus.REJECTED:
                return {
                    backgroundColor: '#FDF2F9',
                    border: '1px solid #EE6E7E',
                    color: '#EE6E7E'
                };
            case EBookingStatus.CREATED | EOrderStatus.CREATED:
                return {
                    backgroundColor: '#FDF2F9',
                    border: '1px solid #b98029',
                    color: '#b98029'
                };
            case EBookingStatus.PROCESSING | EOrderStatus.PROCESSING:
                return {
                    backgroundColor: '#FDF2F9',
                    border: '1px solid #6e9bee',
                    color: '#6e9bee'
                };
            default: {
                return {
                    backgroundColor: '#F0F8FE',
                    border: '1px solid #2F80ED',
                    color: '#2F80ED'
                };
            }
        }
    }, [status]);

    const statusText = useMemo((): string => {
        switch (status) {
            case EBookingStatus.CONFIRMED:
                return 'Подтверждено';
            case EBookingStatus.REJECTED:
                return 'Отменено';
            case EBookingStatus.PROCESSING:
                return 'Предварительно';
            default:
                return 'Создано';


        }
    }, [status]);

    return (
        <div style={statusStyle} className='booked-item__payment-status' >
            {statusText}
        </div>
    );
};

export default ConfirmStatusTag;