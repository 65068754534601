
import { type } from "os";
import { createContext } from "react";
import { TDraggingTarget } from "./components/TWrapper";

export type TDraggingContextType = {
    isDragging: boolean
    draggingTarget: TDraggingTarget
}

export const TDraggingContext = createContext<TDraggingContextType>({isDragging:  false, draggingTarget: 'table'});