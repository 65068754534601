import React from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import AuthGuard from './guards/AuthGuard'
import { AuthPage } from '../../pages/AuthPage'
import { CitySelectPage } from '../../pages/CitySelectPage'
import CityGuard from './guards/CityGuard'
import TimelinePage from '../../pages/TimelinePage'
import { observer } from 'mobx-react-lite'
import { container } from '../../di/container'
import { Credential } from '../../features/authentication/domain/entities/Credential'

export const ADMIN_TIMELINE_PATH = '/admin-timeline';
export const AUTH_PATH = '/auth';
export const ADMIN_CITY_PATH = '/city';

const RouterConfiguration: React.FC = observer(() => {

    const credential = container.get(Credential);

    return (
        <HashRouter>
            <Routes>

                <Route path='/' element={<AuthGuard credential={credential} element={<Navigate to={ADMIN_TIMELINE_PATH} />} />} />

                <Route path={AUTH_PATH} element={<AuthPage />} />

                <Route path={ADMIN_CITY_PATH} element={<AuthGuard credential={credential} element={<CitySelectPage />} />} />

                <Route path={ADMIN_TIMELINE_PATH} element={<AuthGuard credential={credential} element={<CityGuard element={<TimelinePage />} />} />} />
            </Routes>
        </HashRouter>
    )
})

export default RouterConfiguration