import { useMemo } from "react";
import { StyledBookingTable } from "../../../timeline/ui/components/RestyleTable";
import { Task, TaskProperties } from "../../domain/entities/Task";
import { Button, Checkbox } from "antd";
import Modal from "antd/lib/modal/Modal";

/**
 * @param createTask - Функция создания задачи, в случае успеха возвращает null, иначе текст ошибки
 * @param updateTask - Функция редактирования задачи, в случае успеха возвращает null, иначе текст ошибки
 * @param tasks - список задач
 */
type TasksTableProps = {
    tasks: TaskProperties[],
    onCreateTask: () => void;
    updateTask: (task: Partial<TaskProperties>, id: string) => void;
};

type ColType = {
    [k: string]: any,
    dataIndex: keyof TaskProperties
};

export const TasksTable: React.FC<TasksTableProps> = ({ tasks, onCreateTask, updateTask }) => {

    const buildOnToggleDone = (task: TaskProperties) => () => {
        updateTask({ ...task, isDone: !task.isDone }, task.id);
    };

    const buildRowStyle = (reacord: TaskProperties): React.CSSProperties | undefined => reacord.isDone
        ? { color: '#4B51EA', backgroundColor: '#4b50eaa05' }
        : undefined;

    const columns = useMemo<ColType[]>(() => [
        {
            title: '',
            dataIndex: 'isDone',
            width: '5%',
            render: (_: any, record: TaskProperties, index: number) => <Checkbox
                key={record.id}
                checked={record.isDone}
                onChange={buildOnToggleDone(record)}
            />,
            defaultSortOrder: 'ascend',
            sorter: (prev: TaskProperties, curr: TaskProperties) => +prev.isDone - +curr.isDone
        },
        {
            title: 'Название',
            dataIndex: 'title',
            width: '10%',
            render: (_: any, record: TaskProperties) => <div style={buildRowStyle(record)}>{record.title}</div>
        },
        {
            title: 'Время',
            dataIndex: 'time',
            width: '10%',
            render: (_: any, record: TaskProperties) => <div style={buildRowStyle(record)}>{record.time.format('HH:mm')}</div>

        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            width: '50%',
            render: (_: any, record: TaskProperties) => <div style={buildRowStyle(record)}>{record.comment}</div>
        },

    ], []);

    return <>
        {
            tasks.length > 0
                ?

                <StyledBookingTable
                    bordered
                    showSorterTooltip={false}
                    dataSource={[...tasks]}
                    columns={columns}
                    tableLayout='fixed'
                    pagination={false}
                    footer={() => <Button onClick={onCreateTask} className="default-btn">Создать</Button>}
                />
                :
                <div>
                    <h5>На этот день нет задач</h5>
                    <Button onClick={onCreateTask} className="default-btn">Создать задачу</Button>
                </div>
        }
    </>;
};