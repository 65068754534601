import moment from "moment";
import api from "../../../api/MainApi";
import { IHookFormBooking } from "../../../features/booking/domain/entities/IHokFormOrder";
import { GameValue } from "../../../features/booking/ui/components/GameController";
import { Game } from "../../../features/fillial/domain/entities/Game";

export const INVALID_PHONE_MESSAGE = 'Номер введен неправильно';
export const INVALID_PROMOCODE_DEFAULT_MESSAGE = 'Промокод не активен';
export const CERTIFICATE_DUPLICATE_ERROR = 'Нельзя использовать сертификат дважды';
export class ValidatorHelper {
    static isPhoneValid(value: string | null): boolean {
        const cutRegEx: RegExp = /^8|(\+7)/;
        
        const phone = (value ?? '').toString().trim().replace(cutRegEx, '');
        return phone.length == 10;
    }

    static phoneValidate(value: string): string | boolean {
        if (ValidatorHelper.isPhoneValid(value)) return true;
        return INVALID_PHONE_MESSAGE;
    }

    static buildAvailableGameValidator = (gameTypeId: number) => (game: Game | null) => {
        if(!game) return true;
        return game?.gameTypeId == gameTypeId || 'Игра не относится к данному игровому типу';
    }



    static buildNumberAmbitValidator(max: number, min: number): (value: string) => boolean {
        return (value) => {
            const valueNumber = Number.parseInt(value);
            return !Number.isNaN(valueNumber) && (valueNumber > max || valueNumber < min);
        }
    }

    static buildNumberAmbitValidatorWithMessage(max: number, min: number): (value: string) => string | boolean {
        return (value) => {
            const res = ValidatorHelper.buildNumberAmbitValidator(max, min)(value);
            return res && `Значение ${value} не попадает в интервал ${min} - ${max}`;
        }
    }


    static bookingsTimeValidator(value: IHookFormBooking[] | undefined): boolean {        
        if (value == null || value.length == 0) return false;
        return value
            .toSorted((prev, next) => moment(prev.bookingDate).diff(moment(next.bookingDate), 'hours'))
            .filter((booking, index, bookings) => index < bookings.length - 1
                ? Math.abs(moment(booking.bookingDate).diff(moment(bookings[index + 1].bookingDate), 'hours')) > 1
                : false)
            .length > 0;
    }

    static gameValidatorBuilder(availableGames: GameValue[]): (value: number | string | null) => boolean | string {
        return (value) => {
            // const gameId = Number.parseInt(value?.toString() ?? '0');
            const gameIndex = availableGames.findIndex(game => game.game.id == value);
            return gameIndex != -1 || 'Данная игра недоступна для данной комнаты';
        }
    }

    // static certificateValidate = async (usedCertificates: string[], certificate: string, order: OrderFormState, token: string): Promise<string | boolean> => {
    //     const certificateDuplicate = usedCertificates.findIndex(cert => cert === certificate);
    //     if (~certificateDuplicate) return CERTIFICATE_DUPLICATE_ERROR;
    //     const res = await api.validateOrder(OrderMapper.toDtoFromForm({ ...order, certificates: [...order[CERTIFACATES_LIST_PATH], certificate] }), token);
    //     if (res.data.error != 0) return res.data.error_text;
    //     return true
    // }



}