import { action, computed, makeAutoObservable, observable, runInAction } from "mobx";
import { container } from "../di/container";
import { ALL_SELECT, BaseCollection } from "./baseCollection";
import { WithId, ICollection } from "./iCollection";
import { RequestStatus } from "./requestStatus";
import { CancelablePromise } from "../api";
import { handleRequest } from "../common/utility-types/helpers/handleRequest";

export class BaseApiCollection<T extends WithId, TRespose> extends BaseCollection<T>{

    @observable
    private _requestStatus: RequestStatus = RequestStatus.NEVER;
    private _error?: string;

    @computed
    get error() {
        return this._error;
    }

    @computed
    get isLoaded() {
        return this._requestStatus == RequestStatus.SUCCESSFULL;
    }

    @computed
    get isLoading() {
        return this._requestStatus == RequestStatus.LOADING;
    }

    loadLocally = async (v: T[]) => {
        this.setMany(v);
        this._requestStatus = RequestStatus.SUCCESSFULL;
    }

    @action
    load = async (request: () => Promise<Array<TRespose>> | CancelablePromise<Array<TRespose>>, adapter: (res: TRespose) => Promise<T>): Promise<T[]> => {
        if (this._requestStatus == RequestStatus.NEVER || this.error) {
            this._requestStatus = RequestStatus.LOADING;
            const res = await handleRequest(request);
            if (res.isLeft()) {
                return runInAction(() => {
                    this._requestStatus = RequestStatus.ERROR;
                    this._error = res.getLeft((r) => ({ message: '' })).message;
                    return [];
                })
            }
            return runInAction( async () => {
                console.log('RES - ', res.getRight((_) => []));
                
                const adaptedValues = await Promise.all(res.getRight((_) => []).map( async (res)  => await adapter(res)));
                this.setMany(adaptedValues);
                this._requestStatus = RequestStatus.SUCCESSFULL;
                return this.where(ALL_SELECT);
            });
        }
        return this.where(ALL_SELECT);
    }

}

// const isPromiseTypeOrType = <U, T>(adapter:  (res: U) => Promise<T> | T): value is T => {
//     return value instanceof Promise && typeof value.then === 'function';
// }

