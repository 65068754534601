import { injectable } from "inversify";
import { Either, IEither } from "../../../@types/either";
import { Failure } from "../../../@types/failure";
import { BookingService, ProblemDetails,  } from "../../../api";
import { isProblemDetails } from "../../../common/utility-types/guards/isProblemDetails";
import { handleRequest } from "../../../common/utility-types/helpers/handleRequest";
import { IOrder } from "../domain/entities/IOrder";
import { IOrderRepository } from "./IOrderRepository";
import { OrderRemoteDataSource } from "../../../api/services/OrderService";
import 'reflect-metadata';
import { OrderAdapter } from "../adapters/OrderAdapter";

/**
 * @description Фасад для запросов связанных с  
 */
@injectable()
export class OrderRepository implements IOrderRepository {

    private _orderAdapter = new OrderAdapter();

    async getOrderById(this: OrderRepository, orderId: string): Promise<IEither<Failure, IOrder>> {
        //TODO Кэшировать здесь
        const res = (await handleRequest(() => OrderRemoteDataSource.getApiV3Orders(orderId)));
        if(res.isLeft()) return Either.left<Failure, IOrder>(res.getLeft((_) => ({message: ''}) ))
        const rightRes = res.getRight((f) => ({title: f.message}) as ProblemDetails)
        if(isProblemDetails(rightRes)) return Either.left<Failure, IOrder>({ message: rightRes.title });
        const adaptedOrder = await this._orderAdapter.orderFromDto(rightRes);
        return Either.right<Failure, IOrder>(adaptedOrder);
    }
}