import { Row, Col, Select, Button, Input, Form, AutoComplete, message, Space, Tag } from 'antd';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import ADD_ICON from '../../../../assets/add.svg';
import useDebounce from '../../../../common/hooks/useDebounce';
import ClientMapper from '../../../../common/mappers/ClientMapper';
import { ClientValue, CreatedClient } from '../../../../api/models/Client';
import BookingModalService from '../../../../common/services/BookingModalService';
import useHotRegister from '../../../../common/hooks/useHotRegister';
import { ValidatorHelper } from '../../../../common/utility-types/helpers/ValidatorHelper';
import { Control, UseFormClearErrors, UseFormGetValues, UseFormReturn, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import DefaultController from '../../../../common/presentation/DefaultController';
import axios, { AxiosError } from 'axios';
import { ErrorResponse } from '../../../../api/MainApi';
import { IHookFormOrder } from '../../domain/entities/IHokFormOrder';
import { Client } from '../../domain/types';
import { container } from '../../../../di/container';
import { Credential } from '../../../authentication/domain/entities/Credential';
import { TextFormater } from '../../../../common/hook-form-tools/textFormater';
import { PhoneWithHotRegTextField } from './PhoneTextField';

interface PersonalDataGroupProps {
    setValue: UseFormSetValue<IHookFormOrder>,
    client: Client | null,
    trigger: UseFormTrigger<IHookFormOrder>,
    clearErrors: UseFormClearErrors<IHookFormOrder>,
    control: Control<IHookFormOrder>
}

const PersonalDataGroup: React.FC<PersonalDataGroupProps> = ({ setValue, trigger, client, clearErrors, control }) => {
    
    const onRemoveClient = () => {
        setValue('client', null);
    }

    return (
        <Space size={20} direction='vertical' style={{ width: '100%' }}>
            <PhoneWithHotRegTextField setValue={setValue} clearErrors={clearErrors} control={control} client={client} trigger={trigger} />
            <Row>
                <Col span={12}>
                    <DefaultController
                        name={'client.name'}
                        control={control}
                        rules={
                            { required: { value: true, message: 'Это поле обязательно' } }
                        }
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Имя"
                                bordered={false}
                                className="default-input" />
                        )}
                    />
                </Col>
            </Row>
            <Row>
                <DefaultController
                    name={'client'}
                    control={control}
                    rules={{
                        required: { value: true, message: 'Необходимо прикрепить клиента' }
                    }}
                    render={({ field }) => (
                        field.value?.name && field.value.phone && field.value.id
                            ? <Tag
                                key={field.name}
                                className="certificate-tag"
                                closable
                                onClose={onRemoveClient}
                            >
                                {`${field.value.name} (${field.value.phone})`}
                            </Tag>
                            : <div />
                    )}
                />
            </Row>
        </Space>
    );
}

export default PersonalDataGroup;