import { BookingCell } from "../entities/BookingCell";
import { ITimelineColumn } from "../entities/ITimelineColumn";
import { TimelineColumn } from "../entities/TimelineColumn";
import { MIN_TIMELINE_ROWS_COUNT } from "./constatnts"




export const normalize = (colMap: Map<string, ITimelineColumn>): Map<string, ITimelineColumn> => {
    const settings = JSON.parse(localStorage.getItem('settings') ?? '{}');
    const defaultRowsCount = settings.rowsCount ?? MIN_TIMELINE_ROWS_COUNT;
    const maxColCellsCount = Math.max(...[...Array.from(colMap.values()).map(col => col.cells.length), defaultRowsCount]);

    return new Map(Array
        .from(colMap.entries())
        .map(entry => [
            entry[0],
            TimelineColumn.base({
                ...entry[1],
                cells: [...entry[1].cells, ...Array.from(
                    { length: maxColCellsCount - entry[1].cells.length },
                    (_, index) => BookingCell.empty({ time: entry[0], additionalId: entry[1].cells.length + index })
                )]
            })
        ])
    )
}