import { Input } from "antd"
import DefaultController from "../../../../common/presentation/DefaultController"
import { ConnectOrder } from "../providers/OrderProvider"

import lock from '../../../../assets/lock.svg'

export const EmployeeCodeInput: React.FC<{ handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void }> = ({ handleBlur }) => {
    return <ConnectOrder>
        {({ control, setValue }) => <DefaultController
            control={control}
            name='personalCode'
            rules={
                {
                    required: { value: true, message: 'Это поле обязательно' },
                }
            }
            render={({ field }) =>
                <Input
                    {...field}
                    onBlur={(event) => {
                        handleBlur?.(event);
                        setValue('personalCode', +event.target.value);
                    }}
                    value={field.value ?? ''}
                    className="default-input employee-input"
                    placeholder="Код сотрудника"
                    bordered={false}
                    prefix={<img src={lock} />}
                />
            }
        />}
    </ConnectOrder>
}
