import React from 'react'
import styled from 'styled-components'
import { IOrder } from '../../domain/entities/IOrder'
import { IOrderSocket } from '../../domain/repository/OrdersRepository';

interface Props {
  order?: IOrderSocket;
}

const NotificationBody: React.FC<Props> = ({ order }) => {
  return (
    <>
      <StyledBody>
        <span>{order?.client?.name}</span>
        <span>{(order?.client?.phone?.length === 10) ? `+7 ${order?.client?.phone}` : order?.client?.phone}</span>
      </StyledBody>
      <StyledButtons>
        <button className="notif_btn-primary">Подтвердить</button>
        <button className="notif_btn">Узнать больше</button>
      </StyledButtons>
    </>
  )
}

export default NotificationBody

const StyledBody = styled.div`
  color: #444656;
  font-weight: 400;
  font-size: 16px;

  margin: 10px 0 10px;

  display: flex;
  flex-direction: column;
`

const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  button{
    font-size: 16px;
    border-radius: 15px;
    border: 2px solid #4B51EA;
    background-color: white;
    padding: 8px 12px;
    width: 100%;
    color: #4B51EA;
    transition: all 0.3s ease-in-out;

    &.notif_btn-primary{
      background-color: #4B51EA;
      color: white;

      &:hover{
        background-color: transparent;
        color: #4B51EA;
      }
    }

    &:hover{
      background-color: #4B51EA;
      color: white;
      }
  }
`