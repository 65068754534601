/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullOrder } from '../models/FullOrder';
import type { FullOrderRequest } from '../models/FullOrderRequest';
import type { Order } from '../models/Order';
import type { PrecalculateRequest } from '../models/PrecalculateRequest';
import type { PriceInfo } from '../models/PriceInfo';
import type { ProblemDetails } from '../models/ProblemDetails';

import { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { OrderForDayResponse } from '../models/OrderForDayResponse';
import { IOrderResponse } from '../../features/booking/domain/entities/IOrderResponse';
import { $instanceApi } from '../MainApi';
import { AxiosResponse } from 'axios';

export class OrderRemoteDataSource {

    /**
     * @param id 
     * @param token 
     * @returns FullOrder Success
     * @returns ProblemDetails Error
     * @throws ApiError
     */
    public static getApiV3Orders(
        id: string,
        token?: string,
    ): CancelablePromise<FullOrder | ProblemDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/orders/{id}',
            path: {
                'id': id,
            },
            headers: {
                'token': token,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param token 
     * @returns Order Success
     * @throws ApiError
     */
    public static postApiV3OrdersCreateEmpty(
        token?: string,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/orders/create-empty',
            headers: {
                'token': token,
            },
        });
    }

    /**
     * @param token 
     * @param requestBody 
     * @returns PriceInfo Success
     * @returns ProblemDetails Error
     * @throws ApiError
     */
    public static postApiV3OrdersCalculate(
        token?: string,
        requestBody?: PrecalculateRequest,
    ): CancelablePromise<PriceInfo | ProblemDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/orders/calculate',
            headers: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param token 
     * @param requestBody 
     * @returns PriceInfo Success
     * @returns ProblemDetails Error
     * @throws ApiError
     */
    public static postApiV3OrdersPrecalculate(
        token?: string,
        requestBody?: FullOrderRequest,
    ): CancelablePromise<PriceInfo | ProblemDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v3/orders/precalculate',
            headers: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param date 
     * @param endDate 
     * @param token 
     * @returns Order Success
     * @throws ApiError
     */
    public static getApiV3OrdersGetOrders(
        date?: string,
        endDate?: string,
        token?: string,
    ): CancelablePromise<Array<IOrderResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/orders/get-orders',
            headers: {
                'token': token ?? OpenAPI.TOKEN,
            },
            query: {
                'date': date,
                'endDate': endDate,
            },
        });
    }

    public static deleteOrder(
        orderId: string,
        token?: string
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: `/api/v3/orders/delete-order/${orderId}`,
            headers: {
                'token': token,
            },
        });
    }

    public static getPersonalByCode(
        code: Number,
    ): CancelablePromise<FullOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: `/api/v3/orders/get-personal-by-code/${code}`,
            headers: {
                'token': OpenAPI.TOKEN
            },
            errors: {
                404: `Not Found`,
            },
        })
    }

    public static saveOrder(
        orderId: string,
        order: FullOrderRequest,
        token?: string
    ): CancelablePromise<FullOrder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: `/api/v3/orders/save-order/${orderId}`,
            headers: {
                'token': token
            },
            body: order
        })
    }
    
    public static getClientsOrders(
    ): CancelablePromise<FullOrder[]> {
        return __request(OpenAPI, {
            method: 'GET',
            url: `/api/v3/orders/get-clients-orders`,
            headers: {
                'token': OpenAPI.TOKEN
            }
        })
    }
}

// export class OrderRemoteDataSource {
    
//     /**
//      * Получить заказ по ID
//      * @param id 
//      * @returns Полный заказ или ошибка
//      */
//     static async getApiV3Orders(id: string): Promise<AxiosResponse<FullOrder | ProblemDetails>> {
//         return await $instanceApi.get<FullOrder | ProblemDetails>(`/api/v3/orders/${id}`);
//     }

//     /**
//      * Создать пустой заказ
//      * @returns Заказ
//      */
//     static async postApiV3OrdersCreateEmpty(): Promise<AxiosResponse<Order>> {
//         return await $instanceApi.post<Order>('/api/v3/orders/create-empty');
//     }

//     /**
//      * Рассчитать заказ
//      * @param requestBody Параметры для расчета
//      * @returns Информация о цене или ошибка
//      */
//     static async postApiV3OrdersCalculate(requestBody?: PrecalculateRequest): Promise<AxiosResponse<PriceInfo | ProblemDetails>> {
//         return await $instanceApi.post<PriceInfo | ProblemDetails>('/api/v3/orders/calculate', requestBody);
//     }

//     /**
//      * Предварительный расчет заказа
//      * @param requestBody Параметры заказа
//      * @returns Информация о цене или ошибка
//      */
//     static async postApiV3OrdersPrecalculate(requestBody?: FullOrderRequest): Promise<AxiosResponse<PriceInfo | ProblemDetails>> {
//         return await $instanceApi.post<PriceInfo | ProblemDetails>('/api/v3/orders/precalculate', requestBody);
//     }

//     /**
//      * Получить заказы за указанный период
//      * @param date Дата начала
//      * @param endDate Дата окончания
//      * @returns Массив заказов
//      */
//     static async getApiV3OrdersGetOrders(date?: string, endDate?: string): Promise<AxiosResponse<Array<IOrderResponse>>> {
//         return await $instanceApi.get<Array<IOrderResponse>>('/api/v3/orders/get-orders', {
//             params: {
//                 date,
//                 endDate
//             }
//         });
//     }

//     /**
//      * Удалить заказ
//      * @param orderId Идентификатор заказа
//      * @returns Удаленный заказ
//      */
//     static async deleteOrder(orderId: string): Promise<AxiosResponse<Order>> {
//         return await $instanceApi.delete<Order>(`/api/v3/orders/delete-order/${orderId}`);
//     }

//     /**
//      * Получить персональные данные по коду
//      * @param code Код персонала
//      * @returns Полный заказ
//      */
//     static async getPersonalByCode(code: number): Promise<AxiosResponse<FullOrder>> {
//         return await $instanceApi.get<FullOrder>(`/api/v3/orders/get-personal-by-code/${code}`);
//     }

//     /**
//      * Сохранить заказ
//      * @param orderId Идентификатор заказа
//      * @param order Параметры заказа
//      * @returns Обновленный заказ
//      */
//     static async saveOrder(orderId: string, order: FullOrderRequest): Promise<AxiosResponse<FullOrder>> {
//         return await $instanceApi.post<FullOrder>(`/api/v3/orders/save-order/${orderId}`, order);
//     }

//     /**
//      * Получить заказы клиентов
//      * @returns Массив заказов
//      */
//     static async getClientsOrders(): Promise<AxiosResponse<FullOrder[]>> {
//         return await $instanceApi.get<FullOrder[]>('/api/v3/orders/get-clients-orders');
//     }
// }
