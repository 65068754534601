import { Col, Form, Row, Space, TimePicker } from "antd";
import React from "react";
import { Control, useForm } from "react-hook-form";
import { TaskProperties } from "../../domain/entities/Task";
import DefaultController from "../../../../common/presentation/DefaultController";
import Input from "antd/lib/input/Input";
import TextArea from "antd/lib/input/TextArea";
import { ITaskForm } from "./SmartTasksTable";
import { TextFormater } from "../../../../common/hook-form-tools/textFormater";

type TaskCreateFormProps = {
    control: Control<ITaskForm, any>,
    date: moment.Moment,
    onSubmit: () => void
};

export const TaskCreateForm: React.FC<TaskCreateFormProps> = ({
    control,
    onSubmit,
    date
}) => {

    const buildOnChangeTime = (handler: (time: moment.Moment) => void) => (time: moment.Moment | null) => {
        if (time) handler(date.set('hour', time.get('hours')));
    };

    return <Form
        layout='vertical'
        onSubmitCapture={onSubmit}
    >
        <Space style={{ width: '100%' }} direction='vertical' size={[0, 20]}>
            <Row gutter={[20, 0]}>
                <Col sm={12}>
                    <DefaultController
                        control={control}
                        rules={{
                            required: { value: true, message: 'Это поле обязательно' },
                        }}
                        name='title'
                        render={({ field }) => <Input
                            {...field}
                            placeholder="Название"
                            bordered={false}
                            className="default-input" />}
                    />
                </Col>
                <Col sm={12}>
                    <DefaultController
                        control={control}
                        name="time"
                        rules={{
                            required: { value: true, message: 'Это поле обязательно' }
                        }}
                        formater={TextFormater.combine([
                            TextFormater.length('##:##'.length),
                            TextFormater.asMask('##:##'),
                        ])}
                        render={({ field }) => <Input
                            {...field}
                            placeholder="Время"
                            bordered={false}
                            className="default-input"
                        />}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={24}>
                    <DefaultController
                        control={control}
                        name='comment'
                        render={({ field }) => <TextArea
                            {...field}
                            placeholder="Комментарий"
                            value={field.value ?? ''}
                            showCount
                            maxLength={300}
                            style={{ height: 40 }}
                        />}
                    />
                </Col>
            </Row>
        </Space>
    </Form>;
};