import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { ITimeline } from "../../domain/entities/ITimeline"
import { ITimelineColumn } from "../../domain/entities/ITimelineColumn"
import { Observer, observer } from "mobx-react"
import { IOrder } from "../../../booking/domain/entities/IOrder"
import { TimelineInfo } from "../../../../api/models/OrderForDayResponse"
import { DashboardViewModel } from "../view-models/dashboardViewModel"
import { Without } from "../../../../@types/without"
import timeline from "antd/lib/timeline"
import moment from "moment"
import { EBookingStatus } from "../../../booking/domain/entities/EBookingStatus"

type TimelineContextValue = {
    orders: Without<IOrder, Function>[],
    params: TimelineInfo[] | null
}

type TimelineConnectProps = {
    columnsMap: Map<string, number>
    availableTimes: moment.Moment[]
}

type ConnectableChildFC = React.FC<TimelineConnectProps>
type ConnectTimelineProps = {
    children: ConnectableChildFC
}

const TimelineContext = React.createContext<TimelineContextValue | null>(null);


export const TimelineProvider = TimelineContext.Provider;

export const TimelineConnect: React.FC<ConnectTimelineProps> = ({ children }) => {
    const contextValue = useContext(TimelineContext);


    const bookings = useMemo(
        () => contextValue?.orders
            .map(o => o.bookings)
            .reduce((prev, curr) => [...prev, ...curr], []) ?? [],
        [contextValue?.orders]
    );

    const bookingsWithoutEditing = useMemo(() => bookings.filter(b => !b.isEditing), [bookings])

    const mapWithoutEditing = useMemo(
        () => {
            const params = contextValue?.params ?? [];
            const colMap = new Map(params.map(p => [moment(p.time ?? '').format(moment.defaultFormatUtc), p.info?.deviceCount ?? 0] as [string, number]));
            for (const booking of bookingsWithoutEditing) {
                const bookingDateStr = moment(booking.bookingDate).format(moment.defaultFormatUtc);
                if (colMap.has(bookingDateStr)) {
                    colMap.set(bookingDateStr, (colMap.get(bookingDateStr) ?? 0) - +(booking.status == EBookingStatus.REJECTED
                        ? 0
                        : booking.guestCount ?? 0));
                }
            }
            return colMap;
        },
        [contextValue?.params, contextValue?.orders]
    );
    // const columnsMap = useMemo(() => contextValue?.timeline.columnsMap ?? null, [contextValue?.timeline.columnsMap]);

    const availableTimes = useMemo(
        () => (contextValue?.params ?? [])
            .map(v => moment(v.time))
            .filter(t => t.isAfter(moment.now())),
        [contextValue?.params]
    );

    return (<Observer>
        {() => children({
            columnsMap: mapWithoutEditing,
            availableTimes
        })}
    </Observer>)
};