import React from 'react';
import DefaultButton from '../../../common/presentation/DefaultButton';
import { LoginViewModal } from './loginViewModal';
import { useNavigate } from 'react-router';
import { AUTH_PATH } from '../../../common/router/RouterConfiguration';
import { container } from '../../../di/container';
import { FilialService } from '../../fillial/domain/services/FilialService';


interface LogoutButtonProps {
    onLogout?: () => void
}

const LogoutButton: React.FC<LogoutButtonProps> = ({onLogout}) => {
    const auth = new LoginViewModal();
    const navigate = useNavigate();
    const filial = container.get(FilialService);

    const onClick = () => {
        if(onLogout) onLogout();
        auth.logout();
        filial.setCity(null);
        navigate(AUTH_PATH);
    };

    return (
        <DefaultButton label='Сменить пользователя' onClick = {onClick}/>
    );
};

export default LogoutButton;