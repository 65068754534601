import { useCallback, useEffect, useMemo, useState } from "react";
import { container } from "../../../../di/container";
import { TaskDateViewModel } from "../view-models/taskDateViewModel";
import { inject } from "inversify";
import { Observer, observer } from "mobx-react";
import { TasksTable } from "./TasksTable";
import { TaskProperties } from "../../domain/entities/Task";
import { Button, Modal, message } from "antd";
import { RequestStatus } from "../../../../@types/requestStatus";
import { useForm } from "react-hook-form";
import { TaskCreateForm } from "./TaskCreateForm";
import Loader from "../../../../common/presentation/Loader";
import moment from "moment";

type SmartTasksTableProps = {
    date: moment.Moment,
};


export interface ITaskForm {
    title: string,
    time: string,
    comment?: string
}

export const SmartTasksTable: React.FC<SmartTasksTableProps> = observer(({
    date,
}) => {

    const taskDateViewModel = useMemo(() => new TaskDateViewModel(date), [date]);

    const [isOpen, setOpen] = useState(false);

    const onCreateTask = () => setOpen(true);

    const onCancelCreateTask = () => setOpen(false);

    const { control, handleSubmit, reset } = useForm<ITaskForm>({
        defaultValues: {
            time: undefined,
            title: undefined,
            comment: undefined
        }
    });

    useEffect(
        () => {
            taskDateViewModel.setupOnCreateReaction({
                onError: (e) => message.error(e),
                onSuccessful: () => {
                    reset();
                    setOpen(false);
                }
            });
            return () => taskDateViewModel.dispose();
        },
        []
    );

    const onSubmit = (task: ITaskForm) => {
        const splittedTime = task.time.split(':');
        taskDateViewModel.createTask({
            title: task.title,
            comment: task.comment,
            time: moment(date)
                .set('hour', Number.parseInt(splittedTime[0]))
                .set('minute', Number.parseInt(splittedTime[1]))
        });
    };

    return (
        <>
            <TasksTable
                tasks={taskDateViewModel.tasks}
                onCreateTask={onCreateTask}
                updateTask={taskDateViewModel.updateTask}
            />
            <Modal
                open={isOpen}
                title='Создание задачи'
                onCancel={onCancelCreateTask}
                cancelText={null}
                footer={
                    <Observer>
                        {() => <Button
                            disabled={taskDateViewModel.loadingStatus == RequestStatus.LOADING}
                            target='submit'
                            className="default-btn"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {taskDateViewModel.creatingStatus == RequestStatus.LOADING
                                ? <Loader size={32} color={'#49a7fe'} />
                                : 'Создать'
                            }
                        </Button>}
                    </Observer>
                }
            >
                <TaskCreateForm control={control} onSubmit={handleSubmit(onSubmit)} date={date} />
            </Modal>
        </>

    );
});