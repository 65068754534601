import React from 'react';
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Navigate } from "react-router";
import { FilialService } from '../../../features/fillial/domain/services/FilialService';
import { container } from '../../../di/container';
import { ADMIN_CITY_PATH } from '../RouterConfiguration';
import { FilialViewModal } from '../../../features/fillial/ui/view-models/FilialViewModel';

interface GuardProps {
    element: React.ReactElement,
    filial?: FilialService
}

const CityGuard: React.FC<GuardProps> = observer(({ element, filial = container.get(FilialService) }) => {

    const isSelectedCity = useMemo(() => {
        return filial.getSelectedCity;
        // return localStorage.getItem('filial');
    }, [filial.getSelectedCity]);

    return (
        isSelectedCity 
            ? element
            : <Navigate to={ADMIN_CITY_PATH} replace />
    );
});

export default CityGuard;