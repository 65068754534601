/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Room } from '../models/Room';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { $instanceApi } from '../MainApi';
import { AxiosResponse } from 'axios';

export class RoomService {

    /**
     * @param token 
     * @returns Room Success
     * @throws ApiError
     */
    public static getApiV3RoomsAll(
token?: string,
): CancelablePromise<Array<Room>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/rooms/all',
            headers: {
                'token': token ,
            },
        });
    }

    /**
     * @param time 
     * @param token 
     * @returns Room Success
     * @throws ApiError
     */
    public static getApiV3RoomsFree(
time?: string,
token?: string,
): CancelablePromise<Array<Room>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/rooms/free',
            headers: {
                'token': token,
            },
            query: {
                'time': time,
            },
        });
    }

}

// export class RoomService {

//     /**
//      * Получить все комнаты
//      * @returns Массив комнат
//      */
//     static async getApiV3RoomsAll(): Promise<AxiosResponse<Array<Room>>> {
//         return await $instanceApi.get<Array<Room>>('/api/v3/rooms/all');
//     }

//     /**
//      * Получить свободные комнаты на указанное время
//      * @param time Время
//      * @returns Массив свободных комнат
//      */
//     static async getApiV3RoomsFree(time?: string): Promise<AxiosResponse<Array<Room>>> {
//         return await $instanceApi.get<Array<Room>>('/api/v3/rooms/free', {
//             params: {
//                 time,
//             },
//         });
//     }
// }
