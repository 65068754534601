import { Button, Checkbox, Col, Collapse, CollapseProps, DatePicker, message, Popover, Radio, Row, Select, Space, TimePicker } from "antd";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { UserOutlined } from '@ant-design/icons';
import LogoutButton from "../../../authentication/ui/LogoutButton";
import CitySelectButton from "../../../fillial/ui/components/CitySelectButton";
import moment from "moment";
import { CellContentStyle, ITimelineSettings } from "../../domain/entities/ITimelineSettings";
import { observer } from "mobx-react";
import { TimelineSettingsViewModel } from "../view-models/timelineSettingsViewModel";
import { IOrder } from "../../../booking/domain/entities/IOrder";
import { GameTypeView } from "../../../fillial/ui/components/GameTypeView";
import { ButtonIconGipno } from "../../../../common/presentation/gipno-ui-kit/button-icon";
import { orderViewConfigs } from "../../domain/configs/ordersViewConfig";
import { ETimelineType } from "../../domain/entities/TimelineType";
import styled from "styled-components";
import arrow from "../../../../assets/arrow2.svg";
import Input from "antd/lib/input/Input";
import { DeviceService } from "../../../../api/services/DeviceService";

import { MIN_TIMELINE_ROWS_COUNT } from "../../domain/utils/constatnts";

interface SettingContainerProps {
    timelineSettings: ITimelineSettings,
    date: moment.Moment,
    selectedView: ETimelineType,
    onChangeView: (v: ETimelineType) => void,
    onChangeDate: (date: moment.Moment | null) => void,
    packageSelect: React.ReactElement,
    drawerHandler?: React.ReactElement
}

const { Option } = Select;
const { Panel } = Collapse;

const SettingContainer: React.FC<SettingContainerProps> = observer(({
    timelineSettings,
    date,
    onChangeDate,
    packageSelect: packageSelector,
    drawerHandler,
    onChangeView,
    selectedView
}) => {

    // const { timeStart, timeEnd } = options;

    // const { clear } = useOrderingContext();
    // const token = useAppSelector(state => state.authenticationReducer.authToken);

    // const isFixed = useMemo(() => options.isFixed, [options.isFixed]);
    // const { isShowCanceled } = options;

    const settingsViewModel = useMemo(() => new TimelineSettingsViewModel(timelineSettings), []);

    // const timelineViewOption = useMemo(() => timelineView, [timelineView]);
    // const cellViewOption = useMemo(() => options.cellView, [options.cellView]);
    // const timeRange = useMemo<[moment.Moment, moment.Moment]>(() => [TimeMapper.toMoment(timeStart, date), TimeMapper.toMoment(timeEnd, date)], [date, timeStart, timeEnd]);

    const onHandleTimeRange = (dates: [moment.Moment | null, moment.Moment | null] | null) => { };// dispatch(changeTimeRange({ range: dates }));
    const onClearOrder = () => { }; //clear();

    const settings = JSON.parse(localStorage.getItem('settings') ?? '{}');

    const [transpose, setTranspose] = useState<boolean>(false);
    const [isFixed, setIsFixed] = useState<boolean>(false);
    const [showCanceled, setShowCanceled] = useState<boolean>(false);

    const handleTranspose = () => {
        setTranspose(!transpose);
        settingsViewModel.settings.setIsTransposed(!transpose);
        // console.log(transpose);


        localStorage.setItem('settings', JSON.stringify({ ...settings, transpose: `${!transpose}` }));
    };
    const handleFixed = () => {
        setIsFixed(!isFixed);
        settingsViewModel.settings.setIsFixed(!isFixed);

        localStorage.setItem('settings', JSON.stringify({ ...settings, isFixed: `${!isFixed}` }));
    };//dispatch(toggleFixed());


    const handleShowCanceled = () => {
        setShowCanceled(!showCanceled);

        // settingsViewModel.settings.setShowCanceled(!showCanceled);
        timelineSettings.setShowCanceled(!showCanceled);

        // localStorage.setItem('settings', JSON.stringify({ ...settings, showCanceled: `${!showCanceled}` }));
     }; //dispatch(toggleShowCanceled());
    const onSelectTimelineView = (type: string) => { };// dispatch(changeTimelineView(type as TimelineStateType));
    const onSelectCellView = (type: string) => {
        timelineSettings.setCellStyle(type as CellContentStyle);
        localStorage.setItem('settings', JSON.stringify({ ...settings, cellStyle: `${type}` }));
    };

    const windowInnerWidth = document.documentElement.clientWidth;

    const defaultRowsCount = settings.rowsCount ?? MIN_TIMELINE_ROWS_COUNT;

    const [glassesCount, setGlassesCount] = useState(60);
    const [rowsCount, setRowsCount] = useState(defaultRowsCount);

    useEffect(() => {
        const fetchDeviceCount = async () => {
            try {
                const response = await DeviceService.getApiV3DeviceCountGetCount();
                setGlassesCount(response.count);
            } catch (error) {
                console.error('Error fetching device count:', error);
            }
        };

        fetchDeviceCount();

        if (settings.isFixed === 'true') {
            handleFixed();
        }
        if (settings.transpose === 'true') {

            handleTranspose();
        }
        if (settings.cellStyle?.length > 0) {
            onSelectCellView(settings.cellStyle);
        }

    }, []);

    const handleGlassesChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGlassesCount(Number(event.target.value));
    };
    const handleRowsChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (+event.target.value >= 10 && +event.target.value <= 25) {
            setRowsCount(Number(event.target.value));
            localStorage.setItem('settings', JSON.stringify({ ...settings, rowsCount: `${Number(event.target.value)}` }));
        }
    };

    const handleBlurGlasses = async (event: React.FocusEvent<HTMLInputElement>) => {
        try {
            const response = await DeviceService.getApiV3DeviceCountSetCount(glassesCount);
            settingsViewModel.settings.setGlassesCount(glassesCount);
        } catch (error) {
            console.error('Error setting device count:', error);
        }
    };

    const handleBlurRows = async (event: React.FocusEvent<HTMLInputElement>) => {
        settingsViewModel.settings.setRowsCount(rowsCount);
    };

    return (
        <Row justify='space-between' style={{ margin: 0, gap: '8px', marginBottom: '16px' }}>
            {/* <DateGipno /> */}

            {/* <StyledWrapper> */}


            {drawerHandler}

            <DatePicker value={date} onChange={onChangeDate} style={{ borderRadius: '15px' }} />
            {packageSelector}

            <Radio.Group
                value={selectedView}
                onChange={(e) => onChangeView(e.target.value)}
            // style={{ width: '100%' }}
            >
                <Row
                    gutter={[20, 0]}
                >
                    {orderViewConfigs.map(v => (
                        <Col span={10} key={v.label}>
                            <Radio.Button value={v.value}>{v.label}</Radio.Button>
                        </Col>
                    ))}
                </Row>
            </Radio.Group>

            {/* </StyledWrapper> */}


            {/* {
                    windowInnerWidth > 900
                        ? colapseContent
                        : <Collapse
                            bordered={false}
                            defaultActiveKey={['1']}
                            // expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            expandIcon={({ isActive }) => <StyledButton> <img src={arrow} alt="" /></StyledButton>}
                            style={{
                                background: "transparent",
                            }}

                        >
                            <Panel header={null} key="1">
                                {colapseContent}
                            </Panel>
                        </Collapse>
                } */}


            {settingsViewModel.gameTypes.map(gameType => (<GameTypeView key={gameType.id} gameType={gameType} />))}
            <Space direction='horizontal' size={[20, 0]}>
                <Popover
                    trigger='click'
                    placement="bottomRight"
                    content={
                        <Space size={10} direction='vertical'>
                            {/* <Row align='middle'>
                                <Col span={8}>Тип сетки</Col>
                                <Col span={12}>

                                      {/* <Select
                                            value={timelineViewOption}
                                            onSelect={onSelectTimelineView}
                                            style={{ width: '200px', height: '42px' }}
                                            defaultValue='normal' >
                                            <Option value='normal'>Обычный</Option>
                                            <Option value="separated">Разъединенный</Option>
                                        </Select> 
                                        */}
                            {/*}
                                </Col>
                            </Row> */}
                            {/* <TimePickerRange
                                    value={timeRange}
                                    onChangeValue = {onHandleTimeRange}
                                /> */}
                            < Row align='middle' >
                                <Col span={8}>Тип ячейки</Col>
                                <Col span={12}>
                                    <Select
                                        value={timelineSettings.cellStyle}
                                        onSelect={onSelectCellView}
                                        style={{ width: '200px', height: '42px' }}
                                        defaultValue='default' >
                                        <Option value="default">Обычный</Option>
                                        <Option value='simplified'>Упрощенный</Option>
                                        <Option value="simplified-spaced">Упрощенный с телом</Option>
                                    </Select>
                                </Col>
                            </ Row>
                            <Checkbox
                                checked={transpose}
                                onChange={handleTranspose}>
                                Транспонировать
                            </Checkbox>
                            <Checkbox
                                checked={isFixed}
                                onChange={handleFixed}>
                                Фиксировано
                            </Checkbox>
                            <Checkbox
                                checked={showCanceled}
                                onChange={handleShowCanceled}>
                                Показать отмененные
                            </Checkbox>
                            <div style={{
                                display: 'flex', alignItems: 'center', gap: '8px', width: '100%'
                            }}>
                                <span>Количество столбцов</span>
                                <Input
                                    type="number"
                                    min={10}
                                    max={25}
                                    placeholder="Кол-во"
                                    className="default-input"
                                    style={{ border: 'none', maxWidth: '50%' }}
                                    onBlur={handleBlurRows}
                                    value={rowsCount}
                                    onChange={handleRowsChange}
                                />
                            </div>
                            <div style={{
                                display: 'flex', alignItems: 'center', gap: '8px', width: '100%'
                            }}>
                                <span>Кол-во очков на день</span>
                                <Input
                                    type="number"
                                    min={0}
                                    placeholder="Кол-во"
                                    className="default-input"
                                    style={{ border: 'none', maxWidth: '50%' }}
                                    onBlur={handleBlurGlasses}
                                    value={glassesCount}
                                    onChange={handleGlassesChange}
                                />
                            </div>
                        </Space >
                    }
                >
                    <div style={{
                        height: '46px',
                        width: '46px'
                    }}>
                        <Button className="add-btn">
                            <SettingOutlined style={{ fontSize: '30px', color: '#2F80ED' }} />
                        </Button>
                    </div>
                </Popover >
                <Popover
                    overlayInnerStyle={{ minWidth: 100 }}
                    trigger='click'
                    placement="bottomRight"
                    content={
                        <Space size={10} direction='vertical'>
                            {/* <a className="setting-link" href={`${process.env.REACT_APP_API_GLOBAL_URL}/v2/auth/token?token=${token}`} target='_blank'>Перейти в админку</a> */}
                            <LogoutButton onLogout={onClearOrder} />
                            <CitySelectButton onChangeCity={onClearOrder} />
                        </Space>
                    }
                >
                    <div style={{
                        height: '46px',
                        width: '46px'
                    }}>
                        <Button className="add-btn">
                            <UserOutlined style={{ fontSize: '30px', color: '#2F80ED' }} />
                        </Button>
                    </div>
                </Popover>
            </Space >

        </Row >
    );
});

export default SettingContainer;

const StyledButton = styled.button`
    width: 50px;
    height: 50px;
    padding: 12px 0px 0px 0px;
    gap: 6px;
    border-radius: 15px;
    opacity: 0px;
    background-color: rgba(68, 70, 86, 1);
    border: none;
    outline: none;
    /* position: relative; */

    img{
        /* position: absolute; */
        transform: translate(0px, -5px);
    }
`;

const StyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;