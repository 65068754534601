import { action, makeObservable, observable } from "mobx";
import { Without } from "../../../../@types/without";

export type TaskProperties = Without<Task, Function>;


export class Task {
    id: string;
    @observable
    title: string;
    @observable
    isDone: boolean;
    @observable
    comment?: string;
    @observable
    time: moment.Moment;

    constructor(task: TaskProperties) {
        this.id = task.id;
        this.title = task.title;
        this.isDone = task.isDone;
        this.time = task.time;
        this.comment = task.comment;
        makeObservable(this);
    }

    @action
    toggleDone = () => {
        this.isDone = !this.isDone;
    }

    @action
    update = (task: Partial<Task>) => {
        this.id = task.id ?? this.id;
        this.isDone = task.isDone ?? this.isDone;
        this.comment = task.comment ?? this.comment;
        this.time = task.time ?? this.time;
        this.title = task.title ?? this.title;
    }
}