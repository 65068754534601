import { action, computed, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import { CellSelectPossability, IBookingCell } from "./IBookingCell";
import { ITimelineColumn, ITimelineColumnProperties } from "./ITimelineColumn";
import { EBookingStatus } from "../../../booking/domain/entities/EBookingStatus";
import { IBooking } from "../../../booking/domain/entities/IBooking";

export class TimelineColumn implements ITimelineColumn {
    time: string;
    @observable.deep
    cells: IBookingCell[];
    @observable
    glassesCount: number;
    @observable
    columnState: CellSelectPossability;
    @observable
    rowsCount: number;

    constructor(column: ITimelineColumnProperties) {

        this.cells = column.cells;
        this.glassesCount = column.glassesCount ?? 0;
        this.columnState = column.columnState;
        this.time = column.time;
        this.rowsCount = column.rowsCount;

        makeAutoObservable(this);
    }



    /**
     * 
     * @param this 
     * @param col - col that should be unioned with this
     * @returns new unioned timeline col
     * @description create new col by union theirs cells to a single one
     */
    unioned(this: TimelineColumn, col: ITimelineColumn): ITimelineColumn {
        const cells = [...this.cells];
        let otherColCells = [...col.cells];
        if (otherColCells.length == 0) return new TimelineColumn(this);
        for (const cell of cells) {
            if (cell.booking == null) {
                cell.setBooking(otherColCells.shift()?.booking ?? null);
            }
            if (otherColCells.length == 0) break;
            else {
                const cellIndex = otherColCells.findIndex(oCell => {
                    if (oCell.booking == null && cell.booking == null) return false;
                    return cell.booking?.equals(oCell.booking) ?? false;
                });
                if (~cellIndex && !cell.equals(otherColCells[cellIndex])) {
                    const booking = otherColCells[cellIndex].booking;
                    cell.setBooking(booking);
                    otherColCells = otherColCells.filter((_, index) => index != cellIndex);
                }
            }
        }
        return new TimelineColumn({
            time: this.time,
            glassesCount: this.glassesCount,
            columnState: this.columnState,
            cells: cells,
            rowsCount: this.rowsCount
        });
    }


    static base(baseColumn: Omit<ITimelineColumnProperties, 'columnState' | '_getUsingGlassesByCells'>): ITimelineColumn {
        return new TimelineColumn({ ...baseColumn, columnState: 'default' });
    }

    // Колонны должны быть обязательно отображающие разницу, относительно базовой
    combined(this: TimelineColumn, cols: ITimelineColumn[]): ITimelineColumn {
        const currCols = cols.filter(col => col.time == this.time);

        return new TimelineColumn({
            time: this.time,
            glassesCount: this.glassesCount,
            cells: [

            ],
            columnState: this.columnState,
            rowsCount: this.rowsCount
        });
    }

    getCellByOrderBookingId(orderId: string, bookingId: string): IBookingCell | null {
        return this.cells.find(cell => Array.isArray(cell.booking)
            ? cell.booking.find(b => b.orderId == orderId && b.id == bookingId)
            : cell.booking?.id == bookingId && cell.booking.orderId == orderId
        ) ?? null;
    }



    @computed
    get isHasBooking(): boolean {
        return this.cells
            .map(cell => (Array.isArray(cell.booking) ? cell.booking : [cell.booking])
                .map(booking => booking?.isEditing ?? false).reduce((prev, curr) => prev || curr, false))
            .reduce((prev, curr) => prev || curr, false);
    }

    @computed
    get remainingGlassesWithoutEditing(): number {
        return this.glassesCount - this._getUsingGlassesByCells(this.cells.filter(b => !b.isEditing));
    }

    @computed
    get remainingGlasses(): number {
        const useGlasses = this._getUsingGlassesByCells(this.cells);
        return this.glassesCount - useGlasses;

    }

    private _getUsingGlassesByCells(this: TimelineColumn, cells: IBookingCell[]): number {
        return cells.map(cell => cell.booking == null
            ? 0
            : ((Array.isArray(cell.booking) ? cell.booking : [cell.booking]) as IBooking[])
                .filter(b => b.status != EBookingStatus.REJECTED)
                .map(b => b.guestCount ?? 0)
                .reduce((prev, next) => +prev + +next, 0)
        )
            .reduce((prev, next) => +prev + +next, 0);
    }

    @action
    updateCells(this: TimelineColumn, cells: IBookingCell[]): void {
        this.cells = [...cells];
    }


    @action
    setColumnState(this: TimelineColumn, state: CellSelectPossability): void {
        this.columnState = state;
        for (const cell of this.cells) cell.setPossibleToBooking(state);
    }

    @action
    setGlassesCount(newCount: number): void {
        this.glassesCount = newCount;
    }
    @action
    setRowsCount(newCount: number): void {
        this.rowsCount = newCount;
    }
}