import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import { AuthDto, IAuth } from "./IAuth";
import { ICity, ILoginData, ILoginResponce, IUser } from "../../../../api/models/Login";
import api from "../../../../api/MainApi";
import { Without } from "../../../../@types/without";
import { injectable } from "inversify";
import 'reflect-metadata';
import { OpenAPI } from "../../../../api";
import { AuthService } from "../../../../api/services/MainService";
import { container } from "../../../../di/container";
import { FilialService } from "../../../fillial/domain/services/FilialService";

@injectable()
export class Credential implements IAuth {
    @observable
    authToken: string | null = null;
    @observable
    availableCities: ICity[] = [];
    @observable
    user: IUser | null = null;

    constructor(
        private filialService: FilialService = container.get(FilialService)
    ) {
        makeAutoObservable(this);
    }
    static initial() {
        return new Credential();
    }

    @computed
    get isAuth() {
        return this.authToken != null;
    }

    @action
    setLogin(this: Credential, logiResponce: ILoginResponce): void {
        this.authToken = logiResponce.token;

        this.user = logiResponce.personal ?? null;
        OpenAPI.TOKEN = logiResponce.token;
    }

    @action
    setLogout(this: Credential): void {
        this.authToken = null;
        this.user = null;
        this.filialService.selectedCity = null;
    }

    @action
    setAvalibleCities(this: Credential, citiesResponce: ICity[]): void {
        this.availableCities = citiesResponce;
    }

}

// export const credential = Credential.initial();
