/// Данные компонент обрабатывает ивенты перемещения таблицы,
///Задает ей размеры и добавляет скролл при необходимости

import { memo, useEffect, useRef, useState } from "react";
import { TStyleProps } from "../types";
import { useDrag, useGesture } from "@use-gesture/react";
import { TDraggingContext } from "../TWrapperContext";
import Cursor from "../../cursor/Cursor";
import useContextMenu from "../../../../features/booking/domain/helpers/ContextMenu";

type TWrapperProps = React.PropsWithChildren & Omit<NonNullable<TStyleProps>, 'isFixed'> & { cellDragTimeDelay: number, isFixed: boolean };

export type TDraggingTarget = 'table' | 'cell';


const _wrapperEqualer = (prev: TWrapperProps, curr:TWrapperProps): boolean =>
    prev.width == curr.width &&
    prev.cellDragTimeDelay == curr.cellDragTimeDelay &&
    prev.height == curr.height &&
    prev.cursor == curr.cursor &&
    prev.isFixed == curr.isFixed;

export const TWrapper: React.FC<TWrapperProps> = memo((props) => {
    const { width, height, children, cellDragTimeDelay, isFixed, cursor } = props;
    const timelineWrapperRef = useRef<HTMLDivElement>(null);
    const dragEvent = useRef<PointerEvent | null>(null);

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [draggingTarget, setDraggingTarget] = useState<TDraggingTarget>('table');

    let interval = useRef<ReturnType<typeof setInterval> | null>(null);
    let timeInterval = 0;
    const [timeIntervalCursor, setTimeIntervalCursor] = useState(0);

    const startTimer = () => {
        setDraggingTarget('table');
        interval.current = setInterval(() => {
            timeInterval += 50;
            setTimeIntervalCursor((prev) => prev + 50);

            if (timeInterval > cellDragTimeDelay) {
                setDraggingTarget("cell");
            }
        }, 50);
    };

    const clearTimer = () => {
        clearInterval(interval.current ?? undefined);
        timeInterval = 0;
        setTimeIntervalCursor(0);
    };


    const bind = useDrag(({ delta, dragging, distance: [x, y], event }) => {
        // @ts-ignore
        dragEvent.current = event.nativeEvent;

        if (interval.current === null) {
            startTimer();
        }
        if (dragging && x > 5 && y > 5) {
            setIsDragging(true);
            document.body.style.cursor = 'grabbing';
            document.body.style.userSelect = 'none';
        }
        if (dragging && x > 5 && y > 5 && draggingTarget === 'table') {
            clearTimer();
            const scrollingElement = timelineWrapperRef.current!;
            scrollingElement.scrollBy(-delta[0], -delta[1]);
        }
        if (!dragging) {
            console.log('CLEAR TIMER DRAG OFF');
            clearTimer();
            interval.current = null;
        }
        if (!dragging && isDragging) {
            setTimeout(() => {
                setIsDragging(false);
                // setDraggingTarget('table');
            });
            document.body.style.cursor = 'auto';
            document.body.style.userSelect = 'auto';
        }
    });

    const [displayCursor, setDisplayCursor] = useState(true);

    useEffect(() => {
        document.addEventListener('mouseleave', mouseLeaveEvent);
        document.addEventListener('mouseenter', mouseEnterEvent);
        // document.addEventListener('mousemove', mouseMoveEvent);

        return () => {
            document.removeEventListener('mouseleave', mouseLeaveEvent);
            document.addEventListener('mouseenter', mouseEnterEvent);
            // document.addEventListener('mousemove', mouseMoveEvent);
        };
    }, []);

    const mouseLeaveEvent = () => {
        setDisplayCursor(false);
        timeInterval = 0;
        setTimeIntervalCursor(0);
        setDraggingTarget("table");
    };

    const mouseEnterEvent = () => {
        setDisplayCursor(true);
    };

    ///////////////////////////////

    const [selectedValue, setSelectedValue] = useState<number>(0);
    const doSomething = () => {
        setSelectedValue(selectedValue + 5);
    };

    const doSomething2 = () => {
        setSelectedValue(selectedValue * 2);
    };

    // const { isShown, menu, hideContextMenu, showContextMenu } = useContextMenu([{ optionName: 'Плюс 5', optionFunc: doSomething },
    // { optionName: 'Умножить на 2', optionFunc: doSomething2 }]);


    return (
        <TDraggingContext.Provider value={{ isDragging, draggingTarget }}>
            <div
                ref={timelineWrapperRef}
                {...bind()}
                className={isFixed ? "TableWrapperFixed" : "TableWrapper"}
                style={{
                    height: height == 'expand' ? '100%' : height,
                    width: width == 'expand' ? '100%' : width,
                }}

                // onContextMenu={showContextMenu}
                // onClick={hideContextMenu}
            >
                {
                    timeIntervalCursor > 150 && displayCursor &&
                    < Cursor
                        refWrapper={timelineWrapperRef}
                        timeInterval={timeIntervalCursor}
                        timeDelay={cellDragTimeDelay}
                        startX={(dragEvent.current?.clientX ?? 0) + (timelineWrapperRef.current?.scrollLeft ?? 0) + (window.scrollX ?? 0)}
                        startY={(dragEvent.current?.clientY ?? 0) + (timelineWrapperRef.current?.scrollTop ?? 0) + (window.scrollY ?? 0)}
                        isFixed={isFixed}
                    />

                    // cursor?.cursorNode
                }
                {children}
            </div>
        </TDraggingContext.Provider>
    );
}, _wrapperEqualer);
