import React, { useCallback } from "react";
import { ConnectOrder } from "../providers/OrderProvider";
import GameController from "./GameController";
import { IHookFormBooking } from "../../domain/entities/IHokFormOrder";
import { IBooking } from "../../domain/entities/IBooking";

type SmartGameControllerProps = {
    bookingId: string
}

export const SmartGameController: React.FC<SmartGameControllerProps> = ({bookingId}) => {


    const getCurrBookingIndex = useCallback(
        (bookings: IHookFormBooking[] | IBooking[]) => bookings.findIndex(booking => booking.id == bookingId),
        [bookingId]
    )

    const getCurrBooking = useCallback(
        (bookigns: IBooking[]) => bookigns.at(getCurrBookingIndex(bookigns)),
        [bookingId]
    );

    return <ConnectOrder>
        {({ control, setValue, order }) => <GameController
            setValue={setValue}
            control={control}
            booking={getCurrBooking(order?.bookings ?? [])}
            defaultValue={order?.bookings.find(b => b.game)?.game?.id}
            index={getCurrBookingIndex(order?.bookings ?? [])} />
        }
    </ConnectOrder>
}