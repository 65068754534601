import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import DefaultButton from "../../../../common/presentation/DefaultButton";
import { container } from "../../../../di/container";
import { FilialService } from "../../domain/services/FilialService";
import { FilialViewModal } from "../view-models/FilialViewModel";
import { ADMIN_CITY_PATH } from "../../../../common/router/RouterConfiguration";

interface CitySelectButtonProps {
    onChangeCity?: () => void
}

const CitySelectButton: React.FC<CitySelectButtonProps> = ({onChangeCity}) => {

    const navigate = useNavigate();
    // const filial = container.get(Filial);
    const filial = new FilialViewModal();

    const onClick = () => {
        if(onChangeCity) onChangeCity();
        // filial.setCity(null);
        filial.filialData.setCity(null);
        navigate(ADMIN_CITY_PATH);

        localStorage.removeItem('filial');
    };

    return (
        <DefaultButton label="Сменить локацию" onClick={onClick}/>
    );
};

export default CitySelectButton;