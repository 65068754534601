import { IReactionDisposer, action, computed, makeObservable, observable, onBecomeUnobserved, reaction, runInAction } from "mobx";
import { Either, IEither } from "../../../../@types/either";
import { Failure } from "../../../../@types/failure";
import { ESourceStatus, IOrder } from "../entities/IOrder";
import { IOrderService, OrderProperties } from "./IOrderService";
import { handleRequest } from "../../../../common/utility-types/helpers/handleRequest";
import { OrderRemoteDataSource } from "../../../../api/services/OrderService";
import { BookingRequest, BookingService } from "../../../../api";
import { Order, defaultOrder } from "../entities/Order";
import { injectable } from "inversify";
import { isProblemDetails } from "../../../../common/utility-types/guards/isProblemDetails";
import { isClientValid, isEqualsClients } from "../helpers/clientHelper";
import { Client } from "../types";
import { Booking } from "../entities/Booking";
import { IBooking } from "../entities/IBooking";
import { bookingToDto, orderToDto } from "../../adapters/dtoAdapter";
import { container } from "../../../../di/container";
import { GameDataSourse } from "../../../fillial/data/GamesDataSourse";
import { OrderAdapter } from "../../adapters/OrderAdapter";
import { EOrderStatus } from "../entities/EOrderStatus";
import moment from "moment";
import { OrdersRepository } from "../repository/OrdersRepository";
import { VipRoomBookingsDto } from "../../../../api/models/VipRoomBookings";

// Отслеживает изменения
@injectable()
export class OrderService implements IOrderService {

    @observable
    order: IOrder | null = null;
    @observable
    private _orderValidState: { order: IOrder | null, isRearanged: boolean } = { order: null, isRearanged: false };

    private _disposers: IReactionDisposer[] = [];

    @observable
    private _editableOrder: IOrder | null = null;

    @observable
    private _linkToOrder:  IOrder | null = null;

    constructor(
        private _ordersRepository = container.get(OrdersRepository)
    ) {
        makeObservable(this);
        this._setupUpdateValidReaction();
        this._setupSyncBookingsBackendReaction();
        this._setupPrecalculateReaction();
        onBecomeUnobserved(this, '_orderValidState', () => this._disposers.forEach(d => d()));
    }

    @computed
    get isShouldRearange() {
        return Order.isValid(this._orderValidState.order) && !this._orderValidState.isRearanged;
    }

    @computed
    get editableOrder() {
        return this._editableOrder;
    }

    @computed
    get linkToOrder() {
        return this._linkToOrder;
    }

    @action
    setEditableOrder(order: IOrder | null) {
        this._editableOrder = order;
    }

    @action
    setLinkToOrder(order: IOrder | null) {
        this._linkToOrder = order;
    }

    private _setupUpdateValidReaction(this: OrderService) {
        this._disposers.push(
            reaction(
                () => this.order,
                (curr) => {
                    console.log('------VALID ORDER -------', curr);

                    if (curr == null) return;
                    if (this._orderValidState.order == null) this._orderValidState.order = new Order(curr);
                    if (!Booking.isArrBookingsEqual(this._orderValidState.order.bookings, curr.bookings)) this._orderValidState.isRearanged = false;
                    if (curr.client) this._updateValidClient(curr.client);
                    if (curr.bookings) this._updateValidBookings(curr.bookings);
                    if (curr.status) this.order?.setStatus(curr.status);
                    if (curr.vipRoomBookings) this._updateValidVipRoomBookings(curr.vipRoomBookings);
                    // TODO сделать то же самое с loyalty сделать
                }
            )
        );
    }

    private _setupSyncBookingsBackendReaction() {
        this._disposers.push(
            reaction(
                () => this._orderValidState.order?.bookings,
                (curr, prev) => {
                    if (curr == null) return;
                    if (Booking.isArrBookingsEqual(curr, prev ?? [])) return;
                    BookingService.putApiV3OrdersBookings(this.order?.id ?? '', undefined, curr.map(b => bookingToDto(b)));
                }
            )
        );
    }

    private _setupPrecalculateReaction(this: OrderService) {
        this._disposers.push(
            reaction(
                () => this.order?.paymentInfo?.priceFormula,
                (curr, prev) => {
                    console.log('========PRECALCULATE========');

                    // if (Booking.isArrBookingsEqual(curr ?? [], prev ?? [])) return;
                    // if (curr == null || curr.length == 0) {
                    //     // TODO обнулить paymentInfo
                    //     return;
                    // }
                    // else {
                    this._updatePaymentInfo();
                    // }
                }
            )
        );
    }


    @action
    private _updateValidVipRoomBookings(this: OrderService, vipRoomBookings: VipRoomBookingsDto[]) {
        const validVipRoomBookings = vipRoomBookings.filter(booking => booking != null); // Добавьте свою логику валидации здесь
        const isShouldUpdateValid = validVipRoomBookings.length != (this._orderValidState.order?.vipRoomBookings ?? []).length
            || JSON.stringify(validVipRoomBookings) !== JSON.stringify(this._orderValidState.order?.vipRoomBookings ?? []);
        if (isShouldUpdateValid) {
            console.log(validVipRoomBookings);
            this._orderValidState.order?.setVipRoomBookings(validVipRoomBookings);
        }
    }

    @action
    private _updateValidClient(this: OrderService, client: Client) {
        if (!isClientValid(client)) return;
        if (this._orderValidState.order?.client == null || !isEqualsClients(client, this._orderValidState.order.client))
            this._orderValidState.order?.setClient(client);
    }

    @action
    private _updateValidBookings(this: OrderService, bookings: IBooking[]) {
        const validBookings = bookings.filter(booking => Booking.isValid(booking));
        const isShouldUpdateValid = validBookings.length != (this._orderValidState.order?.bookings ?? []).length
            || !Booking.isArrBookingsEqual(validBookings, this._orderValidState.order?.bookings ?? []);
        if (isShouldUpdateValid) {
            console.log(validBookings);
            this._orderValidState.order?.setBookings(validBookings);
        }
    }

    @action
    private async _updatePaymentInfo() {
        const order = this.order;
        let price = 0;
        if (order?.paymentInfo?.priceFormula) {
            try {
                const calculatedPrice = eval(order.paymentInfo.priceFormula);
                price = calculatedPrice;
            }
            catch (e) { }
        }
        this.order?.setPriceInfo({
            price: price,
            bonus: null,
            promo: null,
            certificates: null,
            total: price,
            discount: 0
        });

        // if (order) (await handleRequest(() => OrderRemoteDataSource.postApiV3OrdersPrecalculate(undefined, orderToDto(order)))).match({
        //     onLeft: (l) => { },
        //     onRight: (r) => {
        //         if (isProblemDetails(r)) return;
        //         this.order?.setPriceInfo({
        //             price: r.price ?? 0,
        //             bonus: r.bonus ?? null,
        //             promo: r.promo ?? null,
        //             certificates: r.certificates ?? null,
        //             total: r.total ?? 0,
        //             discount: r.discount ?? 0
        //         })
        //     }
        // });
    }

    @action
    update(this: OrderService, order: Partial<OrderProperties> | null): void {
        if (order == null) {
            this.order = null;
            this._orderValidState.order = null;
            return;
        }
        else {
            this.order = new Order({
                id: order.id ?? this.order?.id ?? '',
                title: order.title ?? this.order?.title ?? null,
                source: order.source ?? this.order?.source ?? ESourceStatus.ADMIN,
                status: order.status ?? this.order?.status ?? EOrderStatus.CREATED,
                bookings: order.bookings ?? this.order?.bookings ?? [],
                client: order.client ?? this.order?.client ?? null,
                priceInfo: order.priceInfo ?? this.order?.priceInfo ?? null,
                paymentInfo: order.paymentInfo ?? this.order?.paymentInfo ?? null,
                comment: order.comment ?? this.order?.comment ?? null,
                isSendMessage: order.isSendMessage ?? this.order?.isSendMessage ?? false,
                vipRoomBookings: order.vipRoomBookings ?? this.order?.vipRoomBookings ?? [],
                personalCode: order.personalCode ?? this.order?.personalCode ?? null,
                personal: order.personal ?? this.order?.personal ?? {}
            });
        }
    }

    @action
    async create(this: OrderService): Promise<string | null> {
        this.order = new Order({ ...defaultOrder });
        const res = await handleRequest(() => OrderRemoteDataSource.postApiV3OrdersCreateEmpty());
        if (res.isLeft()) return res.getLeft((_) => ({ message: 'Невозможная ошибка' })).message;
        const orderDto = res.getRight((_) => ({ id: null }));
        const adapter = new OrderAdapter();
        const adaptedOrder = new Order({
            ...await adapter.orderFromDto({
                ...orderDto,
                status: 'Processing',
                title: null,
                comment: null,
                isMessageSent: false,
                personalCode: null,
                personal: {}
            }),
            bookings: this.order?.bookings.map(b => new Booking({ ...b, orderId: orderDto.id ?? '' })) ?? []
        });
        runInAction(() => {
            this.order = adaptedOrder;
            this._orderValidState.order = new Order(this.order);
        });
        return null;
    }

    @action
    async save(this: OrderService): Promise<IEither<Failure, IOrder>> {
        const order = this.order;
        // const adapter = new OrderAdapter();
        if (!order) return Either.left<Failure, IOrder>({ message: 'Невозможно сохранить' });
        return (await this._ordersRepository.save(order)).map((o) => {
            runInAction(() => {
                return this.order = null;
            });
            return o;
        });
        // const res = (await handleRequest(() => OrderRemoteDataSource
        //     .saveOrder(order.id, orderToDto(order))))
        //     .map(orderDto => adapter.syncOrderFromDto(orderDto))
        // if (res.isRight()) runInAction(() => this.order = null);
        // return res;
    }

    @action
    async delete(this: OrderService): Promise<string | null> {
        const order = this._editableOrder;




        // if (order?.status != EOrderStatus.PROCESSING) return null;

        if (order && order?.status != EOrderStatus.CREATED) {
            const orderRequest: BookingRequest[] = order.bookings.map(b => {
                return {
                    id: b.id,
                    gameId: b.game?.id,
                    time: `${b.bookingDate?.utc().local().format()}`,
                    guestCount: b.guestCount ?? -1,
                    gameTypeId: b.gameTypeId ?? -1,
                    sortIndex: b.rowCellId ?? 0
                };
            });
            return (await handleRequest(
                () => BookingService.putApiV3OrdersBookings(order.id, undefined, orderRequest))).match({
                    onLeft: (l) => l.message,
                    onRight: (r) => {
                        runInAction(() => this._editableOrder = null);
                        return null;
                    }
                });
        }
        else if (order) return (await handleRequest(() => OrderRemoteDataSource.deleteOrder(order.id))).match({
            onLeft: (l) => l.message,
            onRight: (r) => {
                runInAction(() => this.order = null);
                return null;
            }
        });
        return null;
    }

}