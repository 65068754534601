import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Control, UseFormReturn, UseFormSetValue, useWatch } from 'react-hook-form';
import DefaultController from '../../../../common/presentation/DefaultController';
import { IHookFormBooking, IHookFormOrder } from '../../domain/entities/IHokFormOrder';
import { observer } from 'mobx-react';
import { Game } from '../../../fillial/domain/entities/Game';
import { LabeledValue } from 'antd/lib/select';
import { container } from '../../../../di/container';
import { GameDataSourse } from '../../../fillial/data/GamesDataSourse';
import { RequestStatus } from '../../../../@types/requestStatus';
import { ALL_SELECT } from '../../../../@types/baseCollection';
import { IBooking } from '../../domain/entities/IBooking';
import { ValidatorHelper } from '../../../../common/utility-types/helpers/ValidatorHelper';


interface GameControllerProps {
    // formApi: UseFormReturn<OrderFormState, any>,
    index: number,
    control: Control<IHookFormOrder>,
    setValue: UseFormSetValue<IHookFormOrder>,
    defaultValue?: string,
    booking?: IBooking,
    gameCollection?: GameDataSourse,
}

export type GameValue = LabeledValue & { game: Game };

const GameController: React.FC<GameControllerProps> = observer(({
    index,
    control,
    setValue,
    booking,
    defaultValue,
    gameCollection = container.get(GameDataSourse),
}) => {


    useEffect(
        () => {
            if ((booking?.game?.id == null || booking?.game?.id == '') && defaultValue)
                setValue(`bookings.${index}.game`, gamesOptions.find(g => g.game.id == defaultValue)?.game ?? null);
        },
        []
    );

    //Получить все доступные комнаты 
    const onChangeGameBuilder = (onChangeCallback: (value: number, option: GameValue | GameValue[]) => void) => (value: number, option: GameValue | GameValue[]) => {
        // onChangeCallback(value, option);
        const selectedOption = option as GameValue;
        setValue(`bookings.${index}.game`, selectedOption.game);
        setValue(`bookings.${index}.gameTypeId`, selectedOption.game.gameTypeId);
    };
    
    
    const allGamesOptions: GameValue[] = useMemo<GameValue[]>(
        () => gameCollection.isLoaded
        ? gameCollection.where(ALL_SELECT).map(v => ({ key: v.id.toString(), value: v.id.toString(), label: v.title, game: v }))
        : [],
        [gameCollection.isLoaded]
    );

    const gamesOptions: GameValue[] = useMemo<GameValue[]>(
        () => allGamesOptions.filter(v => booking?.gameTypeId ? v.game.gameTypeId == booking.gameTypeId : true),
        [gameCollection.isLoaded, booking?.gameTypeId]
    );

    const getLabeledValueById = (id?: string) => {
        return allGamesOptions.find(gameOption => gameOption.game.id == id);
    };

    return <DefaultController
        name={`bookings.${index}.game`}
        control={control}
        rules={
            {
                // required: { value: true, message: 'Это поля обязательно' },
                validate: ValidatorHelper.buildAvailableGameValidator(booking?.gameTypeId ?? -1)
            }
        }
        render={({ field }) => (
            <Select
                className='default-select'
                disabled={!gameCollection.isLoaded}
                labelInValue
                // @ts-ignore
                value={getLabeledValueById(field.value?.id)}
                loading={gameCollection.isLoading}
                onChange={onChangeGameBuilder(field.onChange)}
                options={gamesOptions}
                style={{ height: '40px', width: '100%' }}
                placeholder='Игра'
                popupClassName={'first-plan-object'}
            />
        )}
    />;
});

export default GameController;