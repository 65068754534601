import axios from "axios";
import { ClientResponse, CreatedClient } from "./models/Client";
import { ICity, ILoginData, ILoginResponce, IUser } from "./models/Login";
import { refreshAccessToken } from "./services/MainService";


export interface ErrorResponse {
    error: number,
    error_text: string
}


let instanceUrl = process.env.REACT_APP_API_URL;
const globalUrl = process.env.REACT_APP_API_GLOBAL_URL;
// const adminUrl = process.env.REACT_APP_ADMIN_URL;

export const $instanceApi = axios.create({
    baseURL: instanceUrl,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
});

$instanceApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('refToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Перехватчик для ответов
$instanceApi.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;

    // Если ошибка 401 или 403, и это не повторная попытка
    if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._isRetry) {
        originalRequest._isRetry = true;

        try {
            // Обновляем токен
            const newToken = await refreshAccessToken();

            // Устанавливаем новый токен в заголовок
            originalRequest.headers.Authorization = `Bearer ${newToken}`;

            // Повторно отправляем оригинальный запрос
            return $instanceApi.request(originalRequest);
        } catch (refreshError) {
            console.log('Ошибка авторизации, пользователь не авторизован');
            // Здесь можно перенаправить пользователя на страницу логина или выполнить другие действия
            return Promise.reject(refreshError);
        }
    }

    // Если не 401 или 403, возвращаем ошибку дальше
    return Promise.reject(error);
});

const api = {
    setInstanceUrl(url: string) {
        instanceUrl = url;
    },

    // async login(data: ILoginData) {
    //     // @ts-ignore
    //     if (data.remember === '1') data.remember = true;
    //     else data.remember = false;

    //     return await axios
    //         .post<ILoginResponce>(
    //             `${globalUrl}/admin/auth/login`, {
    //             ...data,
    //             source: "adminpanel"
    //         });
    // },

    // logout: async (token: string) => {
    //     return axios.post(
    //         `${globalUrl}/v2/auth/logout`,
    //         { token: token }
    //     );
    // },

    getInstances: async (userId: number) => {
        return axios.get(
            `${globalUrl}/instances`, //avalible cities
            {
                params: {
                    userId: userId,
                },
                headers: {
                    // Token: `${OpenAPI.TOKEN}`
                    Token: 'admin_token'
                }
            },

        );
    },

    // async getTokenByCookie() {
    //     const token = getTokenCookie();
    //     return await axios.post<IUser>(
    //         `${globalUrl}/v2/auth/loginByRememberedToken`,
    //         { token }
    //     );
    // },

    async getAllCities() {
        return await axios.get(`${globalUrl}/v2/instances/list`);
    },

    async getCitiesOfUser(user_id: number) {
        return await axios.get<ICity[]>(`${globalUrl}/v2/instances/ofUser?user_id=${user_id}`);
    },

    getClientsByPhone: async (phoneNumber: string, token: string) => {
        return await axios.get<ClientResponse>(
            `${process.env.REACT_APP_API_GLOBAL_URL}/clients/get-by-phone/${phoneNumber}`,
            {
                // params: {
                //     phone: phoneNumber
                // },
                headers: {
                    'Token': token
                }
            }
        );
    },

    hotRegister: async (name: string, phone: string, token: string) => {
        return await axios.post<CreatedClient>(
            `${process.env.REACT_APP_API_GLOBAL_URL}/clients/hot-reg`,
            {
                name: name,
                phone: phone
            },
            {
                headers: {
                    'Token': token
                }
            }
        );
    },
};

export default api;