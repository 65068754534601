import { DeepPartial } from "react-hook-form";
import { Without } from "../../../@types/without"
import { ESourceStatus, IOrder } from "../domain/entities/IOrder"
// import { Order } from "../domain/entities/Order";
import { Booking } from "../domain/entities/Booking";
import moment from "moment";
import { IBooking } from "../domain/entities/IBooking";
import { IHookFormBooking, IHookFormOrder } from "../domain/entities/IHokFormOrder";
import { filterNullable } from "../../../common/utility-types/helpers/filterNullable";
// import { EConfirmStatus } from "../../../entities/PaymentInfo";
import { EBookingStatus } from "../domain/entities/EBookingStatus";
import MathHelper from "../../../common/utility-types/helpers/MathHelper";


type IOrderProperties = Without<IOrder, Function>;
type IBookingProperties = Without<IBooking, Function>;

export const toBookingFromHookForm = (value: DeepPartial<IHookFormBooking> | undefined, orderId: string): IBooking | null => {
    if (value == null) return null;
    if (value.bookingDate == null) return null;
    return new Booking({
        id: value.id ?? MathHelper.getUUID(),
        game: value.game
            ? {
                id: value.game?.id ?? '',
                title: value.game?.title ?? '',
                gameTypeId: value.game?.gameTypeId ?? -1,
                guestCountMax: value.game?.guestCountMax ?? 1,
                guestCountMin: value.game?.guestCountMin ?? 1,
                price: 0
            }
            : null,
        orderSourceStatus: ESourceStatus.ADMIN,
        orderId: orderId,
        rowCellId: value.rowCellId ?? 0,
        gameTypeId: value.gameTypeId,
        status: value.status ?? EBookingStatus.PROCESSING,
        guestCount: value.guestCount,
        bookingDate: moment(value.bookingDate),
        isEditing: true
    })
}

export const toHookFormBooking = (value: IBookingProperties): IHookFormBooking => {
    return ({
        ...value,
        bookingDate: (value.bookingDate ?? moment()).format(moment.defaultFormat),
        orderId: value.orderId
    })
}
// TODO integrate formula field
export const toHookFormOrder = (value: IOrderProperties): IHookFormOrder => {
    return ({
        // id: value.id,
        title: value.title,
        bookings: value.bookings.map(b => toHookFormBooking(b)),
        // priceInfo: value.priceInfo,
        paymentInfo: value.paymentInfo,
        client: value.client,
        status: value.status,
        comment: value.comment,
        isSendMessage: value.isSendMessage,
        // employeeCode: '',
        employeeName: '',
        isDefault: false,
        personalCode: value.personalCode,
        vipRoomBookings: value.vipRoomBookings,
        // personal: value.personal
    })
}