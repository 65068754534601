import { Col, Drawer, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Notification from './Notification'
import styled from 'styled-components'
import icon8 from '../../../../common/presentation/gipno-ui-kit/button-icon/svg/icon8.svg'
import icon9 from '../../../../common/presentation/gipno-ui-kit/button-icon/svg/icon9.svg'
import { IOrder } from '../../domain/entities/IOrder'
import { container } from '../../../../di/container'
import { IOrderSocket, OrdersRepository } from '../../domain/repository/OrdersRepository'
import { ALL_SELECT } from '../../../../@types/baseCollection'
import useContextMenu from '../../domain/helpers/ContextMenu'

export enum filters {
    NOTCONFIRM = 'Не подтвержедены',
    CANCELED = 'Отменены'
}

interface Props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    isOpened: boolean,
    order?: IOrder,
    ordersUnreaded: IOrderSocket[]
}

const DrawerComp: React.FC<Props> = ({ setOpen, isOpened, order, ordersUnreaded }) => {

    const onClose = () => {
        setOpen(false);
    };

    return (
        <StyledDrawer
            title={
                <Radio.Group
                    style={{ width: '100%' }}
                >
                    <Row gutter={[20, 0]}>
                        <Col span={12}>
                            <Radio.Button value={filters.NOTCONFIRM}>Не подтвержедены</Radio.Button>
                        </Col>
                        <Col span={12}>
                            <Radio.Button value={filters.CANCELED}>Отменены</Radio.Button>
                        </Col>
                    </Row>
                </Radio.Group>
            }
            placement={'left'}
            closable={false}
            onClose={onClose}
            open={isOpened}
            key={'left'}
        >
            {
                ordersUnreaded.length > 0
                    ?
                    ordersUnreaded.map(order =>

                        <Notification order={order} key={order.id} />
                    )
                    :
                    <StyledText>Нет новых заказов</StyledText>
            }


        </StyledDrawer>
    )
}

export default DrawerComp

export const OpenDrawerButton: React.FC = (props) => {
    return (
        <StyledButton></StyledButton>
    )
}

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body{
        overflow-y: scroll !important;
        overflow-x: hidden !important;
        touch-action: none;

        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`

const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    width: 47px;
    height: 47px;
    
    background: #4B51EA;
    border-radius: 15px;
    
    color: white;
    border: none;
    
    background-image: url(${icon8});
    background-color: #EBECF0;
    background-position: center;
    background-repeat: no-repeat;
            
    &:hover {
    background-image: url(${icon9});
    background-color: #4B51EA;
    background-position: center;
    background-repeat: no-repeat;
    }

`
const StyledText = styled.h3`
    font-size: 16px;
    font-weight: 600;
    color: #8A929E;
    text-align: center;
`