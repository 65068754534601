import { IReactionDisposer, action, autorun, computed, getDependencyTree, makeObservable, observable, onBecomeUnobserved, reaction } from "mobx";
import { TimelineBlock } from "../../../../api";
// import { RootStore } from "../../../../app/mStore";
import { ITimeline } from "./ITimeline";
import moment from "moment";
import { normalize } from "../utils/TimelineHelper";
import { BookingCell } from "./BookingCell";
import { CellSelectPossability, IBookingCell } from "./IBookingCell";
import { Without } from "../../../../@types/without";
import { IBooking } from "../../../booking/domain/entities/IBooking";
import { Timer } from "../../../../common/utility-types/utils/timer";
import { Booking } from "../../../booking/domain/entities/Booking";
import { filterNullable } from "../../../../common/utility-types/helpers/filterNullable";
import { ESourceStatus, IOrder } from "../../../booking/domain/entities/IOrder";
import { ITimelineColumn } from "./ITimelineColumn";
import { TimelineColumn } from "./TimelineColumn";
import { BookingDto } from "../../../../api/models/Booking";
import { Game } from "../../../fillial/domain/entities/Game";
import { inject, injectable, multiInject } from "inversify";
import { MIN_TIMELINE_ROWS_COUNT } from "../utils/constatnts";

// Имеет логику взаимодейтсвия между ячейками 

//НЕобходимо реализовать возможность, менять напрямую ячейку(и)
// Модель, зависящая непосредственно от IOrder/IBooking

type ModifiedTimelineBlocks = Omit<TimelineBlock, 'bookings'> & { bookings: (BookingDto & { game: Game | null, source: ESourceStatus })[] };
export class Timeline implements ITimeline {
    @observable
    private _mainColumnsMap: Map<string, ITimelineColumn>;
    // normalizer: ITimelineNormalizer = new TimelineNormalizer();
    @observable
    _timer: Timer | null = null;
    private _disposers: IReactionDisposer[] = [];

    @observable
    private _rowsCount: number = MIN_TIMELINE_ROWS_COUNT;

    private constructor(timeline: Map<string, ITimelineColumn>) {
        makeObservable(this);
        this._mainColumnsMap = normalize(timeline);
        const fWorkingTime = Array.from(this._mainColumnsMap.keys()).at(0) ?? null;
        if (fWorkingTime != null) {
            const startTimerAt = moment(fWorkingTime);
            this._timer = new Timer(startTimerAt, 3600000);
        }
        this._setupExpireReaction();
        this._setupColStateReaction();
        onBecomeUnobserved(this, 'columnsMap', () => this._disposers.forEach(d => d()));
    }

    @computed
    get columnsMap() {
        return this._mainColumnsMap;
    }

    @computed
    get columnsEditing(): [string, boolean][] {
        return Array
            .from(this._mainColumnsMap.entries())
            .map(entry => [entry[0], entry[1].isHasBooking] as [string, boolean]);
    }

    private _setupColStateReaction(this: Timeline) {
        this._disposers.push(
            reaction(() => this.columnsEditing, (curr, prev) => {
                // Пройти по колоннам
                // Можно отфильтровать на те, что должны быть editable и defaul

                for (let i = 0; i < curr.length; i++) {
                    const colEntry = curr[i];
                    const col = this._mainColumnsMap.get(colEntry[0]);
                    if (col == null || col.columnState == 'unavailable') continue;
                    // Посмотерть не является ли текущий
                    if (colEntry[1]) col.setColumnState('selectable');
                    else if (i != 0 && curr[i - 1][1]) col.setColumnState('selectable');
                    else if (i != curr.length - 1 && curr[i + 1][1]) col.setColumnState('selectable');
                    else col.setColumnState('default');
                    // Посмотерть предыдущтй
                    // Посмотерть 
                }
            })
        );
    }

    private _setupExpireReaction(this: Timeline) {
        this._disposers.push(
            reaction(() => this._timer?.currentTime, (curr) => {
                const expiredCols = Array
                    .from(this._mainColumnsMap
                        .values())
                    .filter(col => moment(col.time)
                        .isBefore(curr));
                expiredCols.forEach(col => {
                    col.setColumnState('unavailable');
                });
            }, { fireImmediately: true })
        );
    }

    @computed
    get rows(): { rowId: string | number; data: IBookingCell[]; }[] {
        const settings = JSON.parse(localStorage.getItem('settings') ?? '{}');
        const maxCount = Math.max(this._rowsCount, MIN_TIMELINE_ROWS_COUNT, settings.rowsCount ?? 0);


        return Array.from({
            length: Math.max(...[...Array
                .from(this._mainColumnsMap.values() ?? [])
                .map(a => a.cells.length), maxCount]
            ),
        },
            (_, index) => ({
                rowId: index.toString(),
                data: filterNullable(Array.from(
                    { length: Array.from(this._mainColumnsMap.values() ?? []).length },
                    (_, rIndex) => Array.from(this._mainColumnsMap.values() ?? []).at(rIndex)?.cells.at(index) ?? null

                ))
            }));
    }

    @computed
    get columns(): ITimelineColumn[] {
        return Array.from(this._mainColumnsMap.values());
    }

    @computed
    get availableTimes(): moment.Moment[] {
        return Array
            .from(this._mainColumnsMap.entries())
            .filter(entry => entry[1].columnState != 'unavailable')
            .map(entry => moment(entry[0]));
    }


    // Factory to create working timeline version, with all it's logic
    static create(timeline: Pick<ITimeline, 'columnsMap'>): Timeline {
        return new Timeline(
            new Map(
                Array.from(timeline.columnsMap.entries()).map(entry => [
                    moment(entry[0]).format(moment.defaultFormat),
                    TimelineColumn.base({
                        ...entry[1],
                        cells: entry[1].cells.map(c => BookingCell.fromBookingCell(c))
                    })

                ])
            )
        );
    }

    //Factory that create timeline from dto
    static fromDto(dto: { timileneBlocks: ModifiedTimelineBlocks[] }): Timeline {
        const timileneBlocks = dto.timileneBlocks;
        return Timeline.create({
            columnsMap: new Map<string, ITimelineColumn>(
                timileneBlocks.map<[string, ITimelineColumn]>(tBlock => [
                    moment(tBlock.time ?? '').format(moment.defaultFormat),
                    TimelineColumn.base({
                        cells: tBlock.bookings?.map((booking, index) => BookingCell.fromBooking(
                            { booking: Booking.fromDto({ ...booking, source: booking.source }), time: tBlock.time ?? '', additionalId: index })) ?? [],
                        time: tBlock.time ?? '',
                        glassesCount: tBlock.info?.deviceCount ?? 60,
                        rowsCount: 0
                    })
                ] as [string, ITimelineColumn]),

            )
        });
    }

    // Create loading timeline without any working functions
    static loading() {
        return new Timeline(new Map(
            ['10:00', '11:00', '12:00', '13:00', '14:00'].map<[string, ITimelineColumn]>(time => [
                time,
                TimelineColumn.base({
                    time: time,
                    glassesCount: 60,
                    cells: Array.from({ length: MIN_TIMELINE_ROWS_COUNT }, (_, index) => BookingCell.loading(time, index.toString())),
                    rowsCount: 0
                })
            ])
        ));
    }

    @action
    updateCol(col: ITimelineColumn): void {
        this._mainColumnsMap.set(col.time, col);
    }

    // CellId is complex of time and additional index, that separate by '/'
    getColByCellId = (cellId: string): ITimelineColumn | null => {
        const time = cellId.split('/')[0];
        return this._mainColumnsMap.get(time) ?? null;
    };

    getCellById = (cellId: string): IBookingCell | null => {
        const col = this.getColByCellId(cellId);
        return col?.cells.find(c => c.id == cellId) ?? null;
    };

    getColsByOrderId = (orderId: string): ITimelineColumn[] => {
        return Array
            .from(this._mainColumnsMap.values())
            .filter(col => col.cells
                .find(cell => Array.isArray(cell.booking)
                    ? cell.booking.find(b => b.orderId == orderId)
                    : cell.booking?.orderId == orderId
                ));
    };

    @action
    setColumnBookingPossibality = (time: string, possability: CellSelectPossability): void => {
        const column = this._mainColumnsMap.get(time);
        if (column == undefined) return;
        const tHoursAmount = Number.parseInt(time.split(':')[0]);
        const mTime = moment().hours(tHoursAmount);
        if (mTime.isAfter(moment(), 'hours')) {
            column.cells.forEach(cell => cell.setPossibleToBooking(possability));
        }
    };

    unioned(this: Timeline, timeline: ITimeline): ITimeline {
        const currTimeline = Timeline.create(this);
        for (const timelineEntry of Array.from(timeline.columnsMap.entries())) {
            const currTimelineColEntry = currTimeline._mainColumnsMap.get(timelineEntry[0]);
            if (currTimelineColEntry) currTimeline._mainColumnsMap.set(timelineEntry[0], currTimelineColEntry.unioned(timelineEntry[1]));
        }
        return currTimeline;
    }

    @action
    updateGlassesCount(newCount: number): void {
        for (const column of this.columns) {
            column.setGlassesCount(newCount);
        }
    }
    @action
    updateRowsCount(newCount: number): void {
        for (const column of this.columns) {
            column.setRowsCount(newCount);
        }
    }
}