import { Col, Select } from 'antd';
import React from 'react';

import vipImg from '../../../../assets/vip.svg';
import clock from '../../../../assets/clock-dark.svg';
import styled from 'styled-components';
import moment from 'moment';
import { Control } from 'react-hook-form';
import { IHookFormOrder } from '../../domain/entities/IHokFormOrder';
import DefaultController from '../../../../common/presentation/DefaultController';

const { Option } = Select;

interface VipInfoProps {
    num: number;
    timeFrom: string | null;
    timeTo: string | null;
    availableTimes: string[];
    onTimeChange: (num: number, timeFrom: string | null, timeTo: string | null) => void;
    initialTime: { start: number; end: number; },
    control: Control<IHookFormOrder>,
    index: number
}

const VipInfo: React.FC<VipInfoProps> = ({ num, onTimeChange, timeFrom, timeTo, availableTimes, initialTime, control, index }) => {

    // console.log(timeFrom + ' - ' + timeTo);
    const initialFrom = initialTime.start;
    const initialTo = initialTime.end;

    // console.log(timeFrom);
    // console.log(timeTo);


    console.log(num + ": " + index);


    const hoursFrom = Array.from({ length: 11 }, (_, i) => 10 + i).map(hour => ({
        value: hour.toString(),
        label: `${hour}:00`
    }));

    const hoursTo = Array.from({ length: 11 }, (_, i) => 11 + i).map(hour => ({
        value: hour.toString(),
        label: `${hour}:00`
    }));

    const handleTimeFromChange = (value: string) => {
        onTimeChange(num, value, timeTo);
    };

    const handleTimeToChange = (value: string) => {
        onTimeChange(num, timeFrom, value);
    };

    // const isTimeTaken = (hour: string) => {
    //     return availableTimes.some(time => moment(time).hour() === parseInt(hour, 10));
    // };
    const isTimeTaken = (hour: string) => {
        const hourNum = parseInt(hour, 10);

        // Если редактируемая випка включает текущее время, считаем его свободным
        // if (initialFrom && initialTo && hourNum >= initialFrom && hourNum <= initialTo) {
        if (initialFrom && initialTo && hourNum >= initialFrom && hourNum < initialTo) {
            return true;
        }
        return availableTimes.some(time => moment(time).hour() === hourNum);
    };

    // console.log(!isTimeTaken('19'));


    const getFirstOccupiedHourAfterFrom = () => {
        if (!timeFrom) return null;

        const fromHour = parseInt(timeFrom, 10);
        const occupiedHours = hoursFrom
            .map(hour => { return parseInt(hour.value, 10); })
            .filter(hour => {
                // // console.log(fromHour);
                // // console.log(hour);
                // // console.log(!isTimeTaken(hour.toString()));
                return hour > fromHour && !isTimeTaken(hour.toString());
            });
        // console.log(fromHour)
        // console.log(hoursFrom.map(hour => parseInt(hour.value, 10)).filter(hour => hour > fromHour && !isTimeTaken(hour.toString())));

        return Math.min(...occupiedHours, Infinity);
    };

    const firstOccupiedHourAfterFrom = getFirstOccupiedHourAfterFrom();

    const filteredHoursFrom = timeTo
        ? hoursFrom.filter(hour => parseInt(hour.value, 10) < parseInt(timeTo, 10))
        : hoursFrom;

    // const filteredHoursTo = timeFrom
    //     ? hours.filter(hour => {
    //         const hourValue = parseInt(hour.value, 10);
    //         if (firstOccupiedHourAfterFrom === null || firstOccupiedHourAfterFrom === Infinity) {
    //             return hourValue > parseInt(timeFrom, 10);
    //         }
    //         return hourValue > parseInt(timeFrom, 10) && hourValue <= firstOccupiedHourAfterFrom;
    //     })
    //     : hours;

    // // console.log(firstOccupiedHourAfterFrom);

    // после выбора времени "от" в селекте "до" будет отображаться до первого занятого часа
    const filteredHoursTo = timeFrom
        ? hoursTo.filter(hour => {
            const hourValue = parseInt(hour.value, 10);
            if (firstOccupiedHourAfterFrom === null || firstOccupiedHourAfterFrom === Infinity) {
                return hourValue > parseInt(timeFrom, 10) && hourValue >= 11 && hourValue <= 21;
            }
            return hourValue > parseInt(timeFrom, 10) && hourValue <= firstOccupiedHourAfterFrom && hourValue >= 11 && hourValue <= 21;
        })
        : hoursTo.filter(hour => parseInt(hour.value, 10) >= 11 && parseInt(hour.value, 10) <= 21);

    // console.log(control._formValues.vipRoomBookings);
    // console.log(index);
    // console.log(control._formValues.bookings);

    return (
        <Col style={{ marginTop: '20px' }}>
            <span style={{ color: '#8A929E', fontWeight: '500', display: 'block', marginBottom: '10px', fontSize: '16px' }}>Время випки {num}</span>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
                <VipCard>
                    <img src={vipImg} alt="vipImg" />
                    <span>{num}</span>
                </VipCard>

                <DefaultController
                    key={`vipRoomBookings.${index}.startTime`}
                    name={`vipRoomBookings.${index}.startTime`}
                    control={control}
                    rules={{
                        required: { value: true, message: 'Это поле обязательно' },
                    }}
                    render={({ field, fieldState }) => (
                        <>
                            <StyledSelect
                                {...field}
                                value={timeFrom || null}
                                onChange={(value: string) => {
                                    field.onChange(value);
                                    handleTimeFromChange(value);
                                }}
                                placeholder="От"
                                allowClear={true}
                            >
                                {filteredHoursFrom.map(hour => (
                                    <Option
                                        key={hour.value}
                                        value={hour.value}
                                        disabled={!isTimeTaken(hour.value)}
                                        className={!isTimeTaken(hour.value) ? 'disabled-option' : ''}
                                    >
                                        {hour.label}
                                    </Option>
                                ))}
                            </StyledSelect>
                            {/* {fieldState.error && <span style={{ color: 'red' }}>{fieldState.error.message}</span>} */}
                        </>
                    )}
                />

                <DefaultController
                    key={`vipRoomBookings.${index}.endTime`}
                    name={`vipRoomBookings.${index}.endTime`}
                    control={control}
                    rules={{
                        required: { value: true, message: 'Это поле обязательно' },
                    }}
                    render={({ field, fieldState }) => (
                        <>
                            <StyledSelect
                                {...field}
                                value={timeTo || null}
                                onChange={(value: string) => {
                                    field.onChange(value);
                                    handleTimeToChange(value);
                                }}
                                placeholder="До"
                                allowClear={true}
                            >
                                {filteredHoursTo.map(hour => (
                                    <Option
                                        key={hour.value}
                                        value={hour.value}
                                    >
                                        {hour.label}
                                    </Option>
                                ))}
                            </StyledSelect>
                            {/* {fieldState.error && <span style={{ color: 'red' }}>{fieldState.error.message}</span>} */}
                        </>
                    )}
                />
            </div>
        </Col>
    );

    // return (
    //     <Col style={{ marginTop: '20px' }}>
    //         <span style={{ color: '#8A929E', fontWeight: '500', display: 'block', marginBottom: '10px', fontSize: '16px' }}>Время випки {num}</span>
    //         <div style={{ display: 'flex', gap: '12px', alignItems: 'flex-start' }}>
    //             <VipCard>
    //                 <img src={vipImg} alt="vipImg" />
    //                 <span>{num}</span>
    //             </VipCard>


    //             <DefaultController
    //                 key={`vipRoomBookings.${index}.startTime`}
    //                 name={`vipRoomBookings.${index}.startTime`}
    //                 control={control}
    //                 rules={{
    //                     // required: "Это поле обязательно" 
    //                     required: { value: true, message: 'Это поле обязательно' },
    //                 }}
    //                 render={({ field, fieldState }) => {
    //                     return (
    //                         <>
    //                             <StyledSelect
    //                                 {...field}
    //                                 value={timeFrom || null}
    //                                 // onChange={handleTimeFromChange}
    //                                 onChange={(value: string) => {
    //                                     field.onChange(value);
    //                                     handleTimeFromChange(value);
    //                                 }}
    //                                 placeholder="От"
    //                                 allowClear={true}
    //                             >
    //                                 {filteredHoursFrom.map(hour => (
    //                                     <Option
    //                                         key={hour.value}
    //                                         value={hour.value}
    //                                         disabled={!isTimeTaken(hour.value)}
    //                                         className={!isTimeTaken(hour.value) ? 'disabled-option' : ''}
    //                                     >
    //                                         {hour.label}
    //                                     </Option>
    //                                 ))}
    //                             </StyledSelect>
    //                             {fieldState.error && <span style={{ color: 'red' }}>{fieldState.error.message}</span>}
    //                         </>
    //                     )
    //                 }}
    //             />

    //             <DefaultController
    //                 key={`vipRoomBookings.${index}.endTime`}
    //                 name={`vipRoomBookings.${index}.endTime`}
    //                 control={control}
    //                 rules={{
    //                     // required: "Это поле обязательно"
    //                     required: { value: true, message: 'Это поле обязательно' },
    //                 }}
    //                 render={({ field, fieldState }) => {
    //                     return (
    //                         <>
    //                             <StyledSelect
    //                                 {...field}
    //                                 value={timeTo || null}
    //                                 // onChange={handleTimeToChange}
    //                                 onChange={(value: string) => {
    //                                     field.onChange(value);
    //                                     handleTimeToChange(value);
    //                                 }}
    //                                 placeholder="До"
    //                                 allowClear={true}
    //                             >
    //                                 {filteredHoursTo.map(hour => (
    //                                     <Option
    //                                         key={hour.value}
    //                                         value={hour.value}
    //                                     >
    //                                         {hour.label}
    //                                     </Option>
    //                                 ))}
    //                             </StyledSelect>
    //                             {fieldState.error && <span style={{ color: 'red' }}>{fieldState.error.message}</span>}
    //                         </>
    //                     )
    //                 }}
    //             />
    //         </div >
    //     </Col>
    // );
};

export default VipInfo;

const VipCard = styled.div`
        background-color: #4B51EA;
        padding: 5px 15px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        gap: 0px;
        justify-content: center;
        align-items: center;

        img {
            max-height: 50px;
        }

        span {
            color: white;
        }
    `;

const StyledSelect = styled(Select)`
    background-color: #EBECF0;
    border-radius: 15px;
    padding: 5px 15px 5px 44px;
    width: 250px !important;

    position: relative;

    .ant-select-selector {
        background: transparent !important;
    }

    .default-select:focus, .ant-select-arrow, .ant-select-open {
        display: none;
    }

    &::after {
        content: '';
        background-image: url(${clock});
        background-size: 20px 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 15px;
        left: 20px;
    }

    .ant-select-clear {
        background: none;
        top: 42%;
        width: 16px;
        height: 16px;
        font-size: 16px;
    }
`;