import { Button } from "antd";
import React, { useMemo } from "react";
import { Exception } from "sass";

interface DefaultButtonProps {
    label?: string,
    icon?: React.FC,
    onClick: () => void
}

const DefaultButton: React.FC<DefaultButtonProps> = ({ icon, label, onClick }) => {

    if (!label && !icon) throw new Error('Imposible use component without icon or label');
    if (label && icon) throw new Error('Can not use both label and icon, you should use only one');

    return (
        <Button className="add-btn" onClick={onClick}>
            {icon
                ? icon({ style: { fontSize: '30px', color: '#2F80ED' } })
                : <div style={{padding: 10}}>{label}</div>
            }
        </Button>
    )
}

export default DefaultButton;