import moment from "moment";
import { OrdersPresentationProps } from "../../domain/configs/ordersViewConfig";
import { Collapse, CollapseProps, Space } from "antd";
import { text } from "stream/consumers";
import { OrdersTable } from "./OrdersTable";
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CollapsedOrdersTableViewModel } from "../view-models/collapsedOrdersTableViewModel";
import { RequestStatus } from "../../../../@types/requestStatus";
import { Observer, observer } from "mobx-react-lite";
import { TasksTable } from "../../../tasks/ui/components/TasksTable";
import { SmartTasksTable } from "../../../tasks/ui/components/SmartTasksTable";
import { container } from "../../../../di/container";
import { OrdersRepository } from "../../../booking/domain/repository/OrdersRepository";
import styled from "styled-components";
import { DATE_FORMAT } from "../../../../common/constants";
import useDebounce from "../../../../common/hooks/useDebounce";
import { Order } from "../../../../api";
import { IOrderResponse } from "../../../booking/domain/entities/IOrderResponse";
import { OrderService } from "../../../booking/domain/services/OrderService";

const { Panel } = Collapse;

interface OrderSums {
    [key: string]: number;
}

export const CollapsedOrdersView: React.FC<OrdersPresentationProps> = observer((props) => {

    const viewModel = useMemo(() => new CollapsedOrdersTableViewModel(), []);

    const orderRepo = container.get(OrdersRepository);
    const orderService = container.get(OrderService);

    const basePanelStyle: CSSProperties = {
        borderRadius: 16,
        margin: '16px 0',
        backgroundColor: '#EBECF0'
    };

    useEffect(() => {
        viewModel.updateFocusedDate(props.date);
    }, [props.date]);

    useEffect(() => {
        viewModel.updateMap(props.date, {
            getOrdersStatus: props.isLoading ? RequestStatus.LOADING : RequestStatus.SUCCESSFULL,
            orders: props.orders ?? []
        });
    }, [props.orders]);

    const onOpenDateCollapse = (dates: string[] | string) => {
        console.log(dates);

        if (Array.isArray(dates)) viewModel.onOpenDate(moment(dates[0]));
        else viewModel.onOpenDate(moment(dates));
    };

    const buildPanelColor = (date: moment.Moment): CSSProperties | undefined => {
        switch (date.isoWeekday()) {
            case 6:
                return {
                    backgroundColor: '#9747FF0D',
                    border: '1px solid #9747FF'
                };
            case 7:
                return {
                    backgroundColor: '#EE6E7E0D',
                    border: '1px solid #EE6E7E'
                };
        }
    };



    const [orderSums, setOrderSums] = useState<OrderSums>({});
    const [canceledSums, setCanceledSums] = useState<OrderSums>({});
    const [actualMonth, setActualMonth] = useState<string>();


    const calculateOrderSums = (orders: IOrderResponse[]) => {
        const sums: OrderSums = {};
        const canceledSums: OrderSums = {};

        moment.months()
            .map((monthStr) => moment().month(monthStr).format('MM-YYYY'))
            .filter((date) => moment().diff(moment(date, 'MM-YYYY'), 'month') < 2)
            .forEach((month) => {
                Array.from({ length: moment(month, 'MM-YYYY').daysInMonth() }, (_, i) =>
                    moment(month, 'MM-YYYY').startOf('month').add(i, 'days')
                ).forEach((date) => {

                    const ordersPriceSum = orders.reduce((sum, order) => {
                        if (order.status == 'Rejected') return sum;

                        const bookingDate = order.bookings[0]?.bookingDate;
                        const bookDay = moment(moment(bookingDate).format());
                        const dateList = moment(moment(date).format());

                        if (bookingDate && dateList.isSame(bookDay, 'day')) {

                            return sum + (eval(order.paymentInfo.formula) ?? 0);
                        }
                        return sum;
                    }, 0);

                    const canceledOrdersSum = orders.reduce((sum, order) => {
                        if (order.status != 'Rejected') return sum;
                        const bookingDate = order.bookings[0]?.bookingDate;
                        const bookDay = moment(bookingDate);
                        const dateList = moment(date);

                        if (bookingDate && dateList.isSame(bookDay, 'day')) {
                            return sum + (eval(order.paymentInfo.formula) ?? 0);
                        }
                        return sum;
                    }, 0);

                    sums[date.format('DD-MM-YYYY')] = ordersPriceSum;
                    canceledSums[date.format('DD-MM-YYYY')] = canceledOrdersSum;
                });
            });
        setOrderSums(sums);
        setCanceledSums(canceledSums);
    };

    const fetchOrders = async (month: string) => {
        const firstDayOfMonth = moment(month, 'MM-YYYY').startOf('month').format(DATE_FORMAT);
        const lastDayOfMonth = moment(month, 'MM-YYYY').endOf('month').format(DATE_FORMAT);

        await orderRepo.getOrdersByPeriod(firstDayOfMonth, lastDayOfMonth).then(() => {

            calculateOrderSums(orderRepo.ordersPeriod);
        });

    };

    const onOpenMonth = (value: string | string[]) => {


        if (value[value.length - 1]) {
            console.log(value);
            fetchOrders(value[value.length - 1]);
            setActualMonth(value[value.length - 1]);
        }
    };

    const prevOrdersPeriod = useRef(orderRepo.ordersPeriod);
    const ordersPeriod = orderRepo.ordersPeriod;

    const arraysEqual = useCallback((arr1: IOrderResponse[], arr2: IOrderResponse[]) => {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].id !== arr2[i].id) return false;
            if (arr1[i].id === arr2[i].id && arr1[i].paymentInfo.formula !== arr2[i].paymentInfo.formula) return false;
        }
        return true;
    }, []);

    useEffect(() => {
        if (!arraysEqual(ordersPeriod, prevOrdersPeriod.current)) {
            prevOrdersPeriod.current = ordersPeriod;
            if (actualMonth) {
                fetchOrders(actualMonth);
            }
        }
    }, [orderRepo.ordersPeriod]);

    useEffect(() => {
        const month = moment(orderService.linkToOrder?.bookings[0].bookingDate).format('MM-YYYY');
        onOpenMonth([`${month}`]);
    }, [orderService.linkToOrder]);

    return <>{

        <Collapse
            bordered={false}
            onChange={e => onOpenMonth(e)}
            defaultActiveKey={orderService.linkToOrder?.bookings[0].bookingDate?.format('MM-YYYY') ?? undefined}
        >
            {
                moment.months()
                    .map((monthStr) => moment().month(monthStr).format('MM-YYYY'))
                    .filter((date) => moment().diff(moment(date, 'MM-YYYY'), 'month') < 2)
                    .map((month) => {

                        return (
                            <Panel
                                key={month}
                                header={`${moment(month, 'MM-YYYY').format('MMMM (YYYY)')}`}
                                style={{ ...basePanelStyle, backgroundColor: '#EBECF070' }}
                            >
                                {Array.from(
                                    { length: moment(month, 'MM-YYYY').daysInMonth() },
                                    (_, i) => moment(month, 'MM-YYYY').startOf('month').add(i, 'days')
                                ).map((date) => {
                                    // fetchOrders(month);

                                    return (<Observer key={date.toString()}>
                                        {() => {

                                            return (
                                                <Collapse
                                                    onChange={onOpenDateCollapse}
                                                    bordered={false}
                                                    style={{ borderRadius: 16 }}
                                                >
                                                    <Panel
                                                        key={date.format(moment.defaultFormat)}
                                                        header={
                                                            <StyledHeaderAccord>
                                                                <div>{date.format('DD-MM dddd')}</div>
                                                                <div>
                                                                    {canceledSums[date.format('DD-MM-YYYY')] > 0 && (
                                                                        <span style={{ color: '#df3c4f', marginRight: 10 }}>
                                                                            {canceledSums[date.format('DD-MM-YYYY')]} руб
                                                                        </span>
                                                                    )}
                                                                    {orderSums[date.format('DD-MM-YYYY')] > 0 &&
                                                                        ` ${orderSums[date.format('DD-MM-YYYY')]} руб`}
                                                                </div>
                                                            </StyledHeaderAccord>
                                                        }
                                                        style={{
                                                            ...basePanelStyle,
                                                            ...buildPanelColor(date),
                                                        }}
                                                    >
                                                        <Space size={20} direction="vertical">
                                                            <SmartTasksTable date={date} />
                                                            <OrdersTable
                                                                {...props}
                                                                key={date.format(moment.defaultFormat)}
                                                                date={date}
                                                                isLoading={
                                                                    viewModel.ordersDateMap.get(date.format(viewModel.dateFormat))
                                                                        ?.getOrdersStatus === RequestStatus.LOADING
                                                                }
                                                                orders={
                                                                    viewModel.ordersDateMap.get(date.format(viewModel.dateFormat))
                                                                        ?.orders ?? []
                                                                }
                                                            />
                                                        </Space>
                                                    </Panel>
                                                </Collapse>
                                            );
                                        }}
                                    </Observer>
                                    );
                                })}
                            </Panel>
                        );
                    })}
        </Collapse>

    }</>;
});

const StyledHeaderAccord = styled.div`
    display: flex;
    justify-content: space-between;
`;