import { action, computed, makeObservable, observable } from "mobx";
import { Without } from "../../../../@types/without";
import { Client, PriceInfo } from "../types";
import { IBooking } from "./IBooking";
import { IPaymentInfo } from "./IPaymentInfo";
import { ESourceStatus, IOrder } from "./IOrder";
import { FullOrder } from "../../../../api";
import { Booking } from "./Booking";
import { PaymentInfo } from "./PaymentInfo";
import { BookingDto } from "../../../../api/models/Booking";
import { Game } from "../../../fillial/domain/entities/Game";
import { EOrderStatus } from "./EOrderStatus";
import { Personal } from "../../../../api/models/Personal";
import { VipRoomBookingsDto } from "../../../../api/models/VipRoomBookings";


export const BASE_ORDER_ID = 'new_order_id';

export class Order implements IOrder {
    @observable
    id: string;
    @observable
    client: Client | null;
    @observable
    bookings: IBooking[];
    @observable
    paymentInfo: IPaymentInfo | null;
    @observable
    priceInfo: PriceInfo | null;
    @observable
    status: EOrderStatus;
    @observable
    source: ESourceStatus;
    @observable
    comment: string | null = null;
    @observable
    isSendMessage: boolean;
    @observable
    title: string | null = null;
    @observable
    vipRoomBookings: VipRoomBookingsDto[];
    @observable
    personalCode: number | null = null;
    @observable
    personal: Personal;

    constructor(order: Without<IOrder, Function>) {
        this.id = order.id;
        this.client = order.client;
        this.bookings = [...order.bookings];
        this.paymentInfo = order.paymentInfo;
        this.priceInfo = order.priceInfo;
        this.source = order.source;
        this.status = order.status;
        this.comment = order.comment;
        this.isSendMessage = order.isSendMessage;
        this.title = order.title;
        this.personalCode = order.personalCode;
        this.personal = order.personal;
        this.vipRoomBookings = [...order.vipRoomBookings];
        makeObservable(this);
    }

    static isValid(order: Without<IOrder, Function> | null) {
        if (order == null) return false;
        if (order.bookings.length == 0) return false;
        return order.bookings
            .map((booking) => Booking.isValid(booking))
            .reduce((prev, curr) => prev && curr);
    }

    equals(this: Order, other: IOrder): boolean {
        if (other! instanceof Order) return false;
        const currBookings = this.bookings.toSorted((prev, next) => prev.id.localeCompare(next.id));
        const otherBookings = other.bookings.toSorted((prev, next) => prev.id.localeCompare(next.id));
        return other.id == this.id &&
            other.client?.id == this.client?.id &&
            other.paymentInfo?.bonus == this.paymentInfo?.bonus &&
            other.paymentInfo?.certificates == this.paymentInfo?.certificates &&
            (currBookings.length == otherBookings.length && currBookings
                .map((booking, index) => booking.equals(otherBookings[index]))
                .reduce((prev, next) => prev && next, true));
    }

    @action
    setId = (id: string): void => {
        this.id = id;
    };
    @action
    setClient = (client: Client | null): void => {
        this.client = client;
    };
    @action
    setBookings = (bookings: IBooking[]): void => {
        this.bookings = bookings;
    };
    @action
    setPriceInfo = (priceInfo: PriceInfo | null): void => {
        this.priceInfo = priceInfo;
    };
    @action
    setPaymentInfo = (loyaltyInfo: IPaymentInfo | null): void => {
        this.paymentInfo = loyaltyInfo;
    };

    @action
    setStatus = (status: EOrderStatus): void => {
        this.status = status;
    };
    @action
    setSource = (source: ESourceStatus): void => {
        this.source = source;
    };
    @action
    setPersonalCode = (personalCode: number | null): void => {
        this.personalCode = personalCode;
    };
    @action
    setPersonal = (personal: Personal): void => {
        this.personal = personal;
    };
    @action
    setVipRoomBookings = (vipRoomBookings: VipRoomBookingsDto[]): void => {
        this.vipRoomBookings = vipRoomBookings;
    };

}

export const defaultOrder = new Order({
    id: BASE_ORDER_ID,
    bookings: [],
    priceInfo: null,
    paymentInfo: null,
    source: ESourceStatus.ADMIN,
    status: EOrderStatus.CREATED,
    isSendMessage: false,
    client: null,
    comment: null,
    title: null,
    personalCode: null,
    personal: {},
    vipRoomBookings: []
});
