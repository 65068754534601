import { action, computed, makeObservable, observable } from "mobx";
import { CellContentType, CellSelectPossability, IBookingCell } from "./IBookingCell";
import { IBooking } from "../../../booking/domain/entities/IBooking";
import { Without } from "../../../../@types/without";
import { VipRoomBookingsDto } from "../../../../api/models/VipRoomBookings";
import { Booking } from "../../../booking/domain/entities/Booking";


export class BookingCell implements IBookingCell {
    @observable
    time: string;
    @observable
    additionalId: string | number;
    @observable
    booking: IBooking | null;
    @observable
    bookingPossability: CellSelectPossability;
    @observable
    isLoading: boolean;
    @observable
    isDraggingTarget: boolean = false;
    @observable
    vipRoomBooking: VipRoomBookingsDto[] | null;

    constructor(bookingCell: Omit<Without<IBookingCell, Function>, 'id'>) {
        makeObservable(this);
        this.booking = bookingCell.booking;
        this.time = bookingCell.time;
        this.additionalId = bookingCell.additionalId
        this.isLoading = bookingCell.isLoading;
        this.bookingPossability = bookingCell.bookingPossability
        this.isDraggingTarget = bookingCell.isDraggingTarget;
        this.vipRoomBooking = bookingCell.vipRoomBooking
    }

    @action
    setIsDraggingTarget = (v: boolean): void => {
        this.isDraggingTarget = v;
    }

    static fromBooking({ booking, time, additionalId, vipRoomBooking }: {
        booking: Booking | null,
        time: string,
        additionalId: number | string,
        vipRoomBooking?: VipRoomBookingsDto[] | null
    }): BookingCell {
        return new BookingCell({
            booking: booking,
            time: time,
            additionalId: additionalId,
            isEditing: false,
            isLoading: false,
            bookingPossability: 'default',
            isDraggingTarget: false,
            vipRoomBooking: vipRoomBooking ?? null
        })
    }

    static empty({ time, additionalId }: {
        time: string,
        additionalId: number | string,
    }): BookingCell {
        return new BookingCell({
            booking: null,
            time: time,
            additionalId: additionalId,
            isEditing: false,
            isLoading: false,
            bookingPossability: 'default',
            isDraggingTarget: false,
            vipRoomBooking: null
        })
    }

    static loading(time: string, id: string): BookingCell {
        return new BookingCell({
            booking: null,
            time: time,
            additionalId: id,
            isEditing: false,
            isLoading: true,
            bookingPossability: 'default',
            isDraggingTarget: false,
            vipRoomBooking: null
        }
        )
    }

    static fromBookingCell(cell: Without<IBookingCell, Function>) {

        const getBooking = (booking: IBooking | null): IBooking | null => {
            if (!booking) return booking;
            return ({ ...booking, game: booking.game ? { ...booking.game } : null });
        }
        return new BookingCell({
            ...cell,
            booking: getBooking(cell.booking)
        }
        );
    }

    @computed
    get id(): string {
        return `${this.time}/${this.additionalId}`;
    }

    @computed
    get isEditing(): boolean {
        return Array.isArray(this.booking)
            ? !!this.booking.find(b => b.isEditing)
            : this.booking?.isEditing ?? false;
    }

    @action
    setVipRoomBooking(vipRoomBooking: VipRoomBookingsDto[] | null): void {
        this.vipRoomBooking = vipRoomBooking
    }

    @action
    setBooking(this: BookingCell, booking: IBooking | null): void {
        this.booking = booking;
    }

    @action
    setPossibleToBooking = (v: CellSelectPossability): void => {
        //Валидация либо здесь, либо в таймлане
        this.bookingPossability = v;
    }

    @action
    setIsLoading(v: boolean): void {
        this.isLoading = v;
    }

    swap(this: BookingCell, cell: IBookingCell): void {
        const currCellContent = this.booking;
        this.setBooking(cell.booking);
        cell.setBooking(currCellContent);
    }

    equals(this: BookingCell, cell: IBookingCell): boolean {
        if (cell! instanceof BookingCell) return false;
        const isBookingsSame = cell.booking == null && this.booking == null || cell.booking?.equals(this.booking);
        return cell.time == this.time && (isBookingsSame ?? false) && this.isEditing == cell.isEditing;
    }
}