import { LabeledValue } from "antd/lib/select";
import { Client } from "../../features/booking/domain/types";
import { TextFormater } from "../hook-form-tools/textFormater";

export type ClientValue = LabeledValue & { client: Client };
class ClientMapper {
    static clientToSelector(client: Client): ClientValue {
        return ({
            key: client.id.toString(),
            label: <div>{`${client.name} (${TextFormater.asMask('8 (###) ### ##-##')(client.phone)})`}</div>,
            value: client.phone,
            client: client
            
        })
    }

    static clientsToSelector(clients: Client[]){
        if(clients.length == 0) return [];
        return clients.map(client => this.clientToSelector(client));
    }
}

export default ClientMapper;