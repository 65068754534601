import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { CursorProps } from '../enhasted-table/types';
import { table } from 'console';


const Cursor: React.FC<CursorProps> = ({ timeInterval, timeDelay, startX, startY, refWrapper, isFixed }) => {
    const delay = 2;

    const dotOutline = useRef<any | null>(null);

    const cursorVisible = useRef(true);
    const cursorEnlarged = useRef(false);

    // const xOffset = -40;
    // const yOffset = -60; 
    const xOffset = isFixed ? -25 : -40;
    const yOffset = isFixed ? 15 : -60; 
    // const xOffset = isFixed ? -30 : -40;
    // const yOffset = isFixed ? 25 : -160; 

    const endX = useRef(startX + xOffset);
    const endY = useRef(startY + yOffset);
    const _x = useRef(startX + xOffset);
    const _y = useRef(startY + yOffset);

    const requestRef = useRef<number | null>(null);

    let progress = (timeInterval / timeDelay) * 100;

    useEffect(() => {
        document.addEventListener('mousedown', mouseOverEvent);
        document.addEventListener('mouseup', mouseOutEvent);
        document.addEventListener('mousemove', mouseMoveEvent);
        document.addEventListener('mouseenter', mouseEnterEvent);
        document.addEventListener('mouseleave', mouseLeaveEvent);

        animateDotOutline();

        return () => {
            document.removeEventListener('mousedown', mouseOverEvent);
            document.removeEventListener('mouseup', mouseOutEvent);
            document.removeEventListener('mousemove', mouseMoveEvent);
            document.removeEventListener('mouseenter', mouseEnterEvent);
            document.removeEventListener('mouseleave', mouseLeaveEvent);

            cancelAnimationFrame(requestRef.current!);
        };
    }, []);

    const toggleCursorVisibility = () => {
        if (cursorVisible.current) {
            dotOutline!.current!.style!.opacity = 1;
        } else {
            dotOutline.current!.style!.opacity = 0;
        }
    };

    const toggleCursorSize = () => {
        if (cursorEnlarged.current) {
            dotOutline.current!.style.transform = 'translate(-50%, -50%) scale(1.5)';
        } else {
            dotOutline.current!.style.transform = 'translate(-50%, -50%) scale(1)';
        }
    };

    const mouseOverEvent = () => {
        cursorEnlarged.current = true;
        toggleCursorSize();
    };

    const mouseOutEvent = () => {
        cursorEnlarged.current = false;
        toggleCursorSize();
    };

    const mouseEnterEvent = () => {
        cursorVisible.current = true;
        toggleCursorVisibility();
    };

    const mouseLeaveEvent = () => {
        cursorVisible.current = false;
        toggleCursorVisibility();
    };

    const mouseMoveEvent = (e: MouseEvent) => {
        cursorVisible.current = true;
        toggleCursorVisibility();

        endX.current = e.clientX + (refWrapper.current?.scrollLeft ?? 0) + (window.scrollX ?? 0) + xOffset;
        endY.current = e.clientY + (refWrapper.current?.scrollTop ?? 0) + (window.scrollY ?? 0) + yOffset;

        
    };

    const animateDotOutline = () => {
        _x.current += (endX.current - _x.current) / delay;
        _y.current += (endY.current - _y.current) / delay;

        dotOutline.current.style!.left = _x.current + 'px';
        dotOutline.current!.style!.top = _y.current + 'px';

        requestRef.current = requestAnimationFrame(animateDotOutline);
    };


    return (
        <div ref={dotOutline} className='cursor-dot-outline'>
            {
                (progress > 150 || progress < 10)
                    ?
                    <svg width={60} height={60} style={{
                        opacity: 0,
                        visibility: 'hidden',
                        transition: 'opacity 0.3s, visibility 0s linear 0.3s'
                    }}>
                        <g transform={`rotate(-90 ${"100 100"})`}>
                            <Circle colour="lightgrey" />
                            {
                                progress < 100
                                    ?
                                    <Circle colour={'blue'} pct={progress} />
                                    :
                                    <Circle colour={'green'} pct={100} />
                            }
                        </g>
                    </svg>
                    :
                    <svg width={60} height={60} style={{
                        opacity: 1,
                        visibility: 'visible'
                    }}>
                        <g transform={`rotate(-90 ${"100 100"})`}>
                            <Circle colour="lightgrey" />
                            {
                                progress < 100
                                    ?
                                    <Circle colour={'blue'} pct={progress} />
                                    :
                                    <Circle colour={'green'} pct={100} />
                            }
                        </g>
                    </svg>
            }
        </div >
    );
};

export default Cursor;


const Circle: React.FC<{ colour: string, pct?: number }> = ({ colour, pct }) => {
    const r = 10;
    const circ = 2 * Math.PI * r;
    let strokePct;
    if (pct) {
        strokePct = ((100 - pct) * circ) / 100;
    }
    return (
        <circle
            r={r}
            cx={170}
            cy={25}
            fill="transparent"
            stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
            strokeWidth={"3px"}
            strokeDasharray={circ}
            strokeDashoffset={pct ? strokePct : 0}
            strokeLinecap="round"
        ></circle>
    );
};