import moment from "moment";
import { FullOrder } from "../../../api";
import { BookingDto } from "../../../api/models/Booking";
import { container } from "../../../di/container";
import { GameDataSourse } from "../../fillial/data/GamesDataSourse";
import { Game } from "../../fillial/domain/entities/Game";
import { FilialService } from "../../fillial/domain/services/FilialService";
import { ESourceStatus, IOrder } from "../domain/entities/IOrder";
import { PaymentInfo } from "../domain/entities/PaymentInfo";
import { Booking } from "../domain/entities/Booking";
import { EOrderStatus } from "../domain/entities/EOrderStatus";
import { SocketOrder } from "../../../api/models/SocketOrder";
import { Order } from "../domain/entities/Order";

export class OrderAdapter {
    constructor(
        private filialService = container.get(FilialService)
    ) { }


    private _orderStatusFromString(orderStatus: string): EOrderStatus {
        switch (orderStatus) {
            case 'Created':
                return EOrderStatus.CREATED;
            case 'Confirmed':
                return EOrderStatus.CONFIRMED;
            case 'Rejected':
                return EOrderStatus.REJECTED;
            default:
                return EOrderStatus.PROCESSING;
        }
    }

    sourceFromString(source: string): ESourceStatus {
        if (source == 'Personal') return ESourceStatus.ADMIN;
        return ESourceStatus.CLIENT;
    }

    orderFromSocket = async (order: SocketOrder): Promise<IOrder> => {
        const games = await this.filialService.gamesPromise;
        // TODO fix data (sortIndex, title, formula, et.c)
        const mBookings = (order.Bookings ?? [])
            .map<(BookingDto & { game: Game | null })>(booking => ({
                ...booking,
                bookingDate: booking.BookingDate,
                sortIndex: 0,
                game: games.find(game => game.id == booking.GameId) ?? null
            }))
            .map((dto) => Booking.fromDto({
                ...dto,
                source: this.sourceFromString(order.Source ?? 'Personal'),
                status: order.Status,
                bookingDate: dto.bookingDate
            }));

        return new Order({
            id: order.Id ?? '',
            title: '',
            comment: '',
            isSendMessage: false,
            source: this.sourceFromString(order.Source ?? 'Personal'),
            status: this._orderStatusFromString(order.Status),
            bookings: mBookings,
            client: {
                id: order.Client.id ?? -1,
                name: order.Client.name ?? 'Client',
                phone: order.Client.phone ?? '+7 999 888 77 66'
            },
            paymentInfo: null,
            priceInfo: null,
            personalCode: null,
            personal: {},
            vipRoomBookings: []
        });
    };

    orderFromDto = async (order: FullOrder): Promise<IOrder> => {
        const games = await this.filialService.gamesPromise;
        const mBookings = (order.bookings ?? [])
            .map<(BookingDto & { game: Game | null })>(booking => ({
                ...booking,
                game: games.find(game => game.id == booking.gameId) ?? null
            }))
            .map((dto) => Booking.fromDto({
                ...dto,
                source: this.sourceFromString(order.source ?? 'Personal'),
                status: order.status
            }));

        return new Order({
            id: order.id ?? '',
            title: order.title,
            isSendMessage: order.isMessageSent,
            comment: order.comment,
            source: this.sourceFromString(order.source ?? 'Personal'),
            status: this._orderStatusFromString(order.status),
            bookings: mBookings,
            paymentInfo: PaymentInfo.fromDto(order.paymentInfo ?? null),
            priceInfo: {
                bonus: order.priceInfo?.bonus ?? null,
                price: eval(order.paymentInfo?.formula ?? '0'),
                promo: order.priceInfo?.promo ?? null,
                certificates: order.priceInfo?.certificates ?? 0,
                discount: order.priceInfo?.discount ?? 0,
                total: eval(order.paymentInfo?.formula ?? '0'),
                // paymentInfo: LoyaltyInfo.fromDto(order.paymentInfo ?? null)
            },
            client: {
                id: order.client?.id ?? -1,
                name: order.client?.name ?? '',
                phone: order.client?.phone ?? ''
            },
            personalCode: order.personalCode,
            personal: order.personal,
            vipRoomBookings: order.vipRoomBookings ?? []
        });
    };

    /**
     * 
     * @param order 
     * @description careful, get games from filial should be call only you sure games are loaded
     * @returns IOrder
     */
    syncOrderFromDto = (order: FullOrder): IOrder => {
        const games = this.filialService.games;
        const mBookings = (order.bookings ?? [])
            .map<(BookingDto & { game: Game | null })>(booking => ({
                ...booking,
                bookingDate: booking.bookingDate,
                game: games.find(game => game.id == booking.gameId) ?? null
            }))
            .map((dto) => Booking.fromDto({
                ...dto,
                source: this.sourceFromString(order.source ?? 'Personal'),
                status: order.status
            }));
        return new Order({
            id: order.id ?? '',
            title: order.title,
            source: this.sourceFromString(order.source ?? 'Personal'),
            status: this._orderStatusFromString(order.status),
            isSendMessage: order.isMessageSent,
            comment: order.comment,
            bookings: mBookings,
            paymentInfo: PaymentInfo.fromDto(order.paymentInfo ?? null),
            priceInfo: {
                bonus: order.priceInfo?.bonus ?? null,
                price: eval(order.paymentInfo?.formula ?? '0'),
                promo: order.priceInfo?.promo ?? null,
                certificates: order.priceInfo?.certificates ?? 0,
                discount: order.priceInfo?.discount ?? 0,
                total: eval(order.paymentInfo?.formula ?? '0'),
                // paymentInfo: LoyaltyInfo.fromDto(order.paymentInfo ?? null)
            },
            client: {
                id: order.client?.id ?? -1,
                name: order.client?.name ?? '',
                phone: order.client?.phone ?? ''
            },
            personalCode: order.personalCode,
            personal: order.personal,
            vipRoomBookings: order.vipRoomBookings ?? []
        });
    };

}