import React from 'react';

import { getColorForPercentage } from './get-color-for-percentage';
import { Label } from './label';
import { LoaderProps, Labelposition } from './loader-components';

import styled from 'styled-components';

type Props = Required<Omit<LoaderProps, "value" | "maxValue" | "innerText" | "type"> & {percent: number; innerBlock: null | React.ReactElement;}>;

const CircleLoaderContainer = styled.div<{width: number | string}>`
  position: relative;
  width: ${({width}) => (width === 'expand' ? '100%' : width + 'px')};
  height: ${({width}) => (width === 'expand' ? '100%' : width + 'px')};
`;

const Circle = styled.circle<{height: number}>`
  fill: transparent;
  stroke: #f1f1f1;
  stroke-width: ${props => props.height}px;
`;

const ProgressCircle = styled(Circle)<{percent: number; colors: {color: string, percent: number}[]}>`
  stroke: ${props => getColorForPercentage(props.percent, props.colors)};
  stroke-dasharray: 283;
  stroke-dashoffset: ${props => (100 - props.percent) * 2.83};
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.3s ease-in-out;
`;

const CircleContainer = styled.div<{labelposition: Labelposition}>`
  display: flex;
  flex-direction: ${({ labelposition }) => ((labelposition === "top" || labelposition === "bottom") ? "column" : "row")};
  align-items: ${({ labelposition }) => ((labelposition === "top" || labelposition === "bottom") ? "left" : "center")};
`;

const InnerDiv = styled.div<{width: number | string}>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export function CircleLoaderGipno (props: Props) {

    let {width, height, percent, colorStops, innerBlock, label, fontSize, labelposition: labelposition} = props;
    if(!labelposition) labelposition = "left";
    
    return (
        <CircleContainer labelposition={labelposition}>
            {(labelposition === 'top' || labelposition === 'left') && <Label width={width} labelposition={labelposition} fontSize={fontSize}>{label}</Label>}
            <CircleLoaderContainer width={width}>
                <svg viewBox="0 0 100 100">
                <Circle height={height} cx="50" cy="50" r="45" />
                <ProgressCircle height={height} percent={percent} colors={colorStops} cx="50" cy="50" r="45" />
                </svg>
                <InnerDiv width={width}>
                  {innerBlock}
                </InnerDiv>
            </CircleLoaderContainer>
            {(labelposition === 'bottom' || labelposition === 'right') && <Label width={width} labelposition={labelposition} fontSize={fontSize}>{label}</Label>}
        </CircleContainer>
    )
}
