import ColorPool from "../../../../common/utility-types/utils/ColorPool";
import { GameType } from "../../domain/entities/GameType";

type GameTypeViewProps = { gameType: GameType }

export const GameTypeView: React.FC<GameTypeViewProps> = ({ gameType }) => {
    return (
        <div key={gameType.id} className="game-type-container gane-type">
            <label
                className="game-type__color"
                style={{ backgroundColor: ColorPool.instance.getColor(gameType.id) }} />
            {gameType.label}
        </div>
    );
}