/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Timeline } from '../models/Timeline';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { OrderForDayResponse } from '../models/OrderForDayResponse';
import { $instanceApi } from '../MainApi';
import { AxiosResponse } from 'axios';

export class TimelineService {

    /**
     * @param date 
     * @param token 
     * @returns Timeline Success
     * @throws ApiError
     */
    public static getApiV3Timeline(
        date: string,
        token?: string,
    ): CancelablePromise<Timeline> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/timeline/{date}',
            path: {
                'date': date,
            },
            headers: {
                'token': token,
            },
        });
    }

    public static getOrdersForDayWithParams(date: string): CancelablePromise<OrderForDayResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/timeline/{date}/orders-for-day',
            path: {
                'date': date,
            },
        });
    }

    /**
     * @param start 
     * @param end 
     * @param token 
     * @returns Timeline Success
     * @throws ApiError
     */
    public static getApiV3TimelineGet(
        start?: string,
        end?: string,
        token?: string,
    ): CancelablePromise<Timeline> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v3/timeline/get',
            headers: {
                'token': token,
            },
            query: {
                'start': start,
                'end': end,
            },
        });
    }

}

// export class TimelineService {

//     /**
//      * Получение данных Timeline по дате
//      * @param date Дата в строковом формате
//      * @param token Токен (опционально)
//      * @returns Timeline Success
//      */
//     static async getApiV3Timeline(date: string, token?: string): Promise<AxiosResponse<Timeline>> {
//         return await $instanceApi.get<Timeline>(
//             `/api/v3/timeline/${date}`,
//             {
//                 headers: {
//                     'token': token
//                 }
//             }
//         );
//     }

//     /**
//      * Получение заказов за день по дате
//      * @param date Дата в строковом формате
//      * @returns Orders For Day Success
//      */
//     static async getOrdersForDayWithParams(date: string): Promise<AxiosResponse<OrderForDayResponse>> {
//         return await $instanceApi.get<OrderForDayResponse>(
//             `/api/v3/timeline/${date}/orders-for-day`
//         );
//     }

//     /**
//      * Получение данных Timeline в определенный временной диапазон
//      * @param start Начало диапазона
//      * @param end Конец диапазона
//      * @param token Токен (опционально)
//      * @returns Timeline Success
//      */
//     static async getApiV3TimelineGet(start?: string, end?: string, token?: string): Promise<AxiosResponse<Timeline>> {
//         return await $instanceApi.get<Timeline>(
//             '/api/v3/timeline/get',
//             {
//                 headers: {
//                     'token': token
//                 },
//                 params: {
//                     start,
//                     end
//                 }
//             }
//         );
//     }
// }
