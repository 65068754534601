import { ApiError, CancelablePromise, ProblemDetails } from "../../../api";
import { DEFAULT_500_ERROR_MESSAGE } from "../../constants";
import { IEither, Either } from "../../../@types/either";
import { Failure } from "../../../@types/failure";

export async function handleRequest<T>(request: () => Promise<T>): Promise<IEither<Failure, T>> {
    try {
        const res = await request();
        return Either.right<Failure, T>(res)
    }
    catch (e) {
        if (e instanceof ApiError) {
            if (e.status == 500) return Either.left<Failure, T>({ message: DEFAULT_500_ERROR_MESSAGE });
            console.log('IS API ERROR');

            return Either.left<Failure, T>({ message: e.message });
            // return Either.left<Failure, T>({ message: (JSON.parse(e.body) as ProblemDetails).title });
        }
        return Either.left<Failure, T>({ message: DEFAULT_500_ERROR_MESSAGE });
    }
}