/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExternalGameData } from '../models/ExternalGameData';
import type { ProblemDetails } from '../models/ProblemDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import { GamesOpenApi } from '../core/GamesOpenApi';
import { GameDto } from '../models/Game';
import { GameTypeDto } from '../models/GameType';
import { $instanceApi } from '../MainApi';
import { AxiosResponse } from 'axios';

export class GameService {

    /**
     * @param gameServerFilialId - Id филилала относительно сервера игр 
     * @returns ExternalGameData Success
     * @throws ApiError
     */
    public static getGames(
        gameServerFilialId: number,
    ): CancelablePromise<Array<GameDto>> {
        return __request(GamesOpenApi, {
            method: 'GET',
            url: `/user-games?id=${gameServerFilialId}`,
        });
    }
    /**
     * @returns GameTypes
     * @description Api для получения игровых типов с сервера игр
     */
    public static getGameTypes( ): CancelablePromise<Array<GameTypeDto>> {
        return __request(GamesOpenApi, {
            method: 'GET',
            url: '/game-types/all'
        })
    }

}


// export class GameService {

//     /**
//      * Получение списка игр для указанного филиала
//      * @param gameServerFilialId Id филиала относительно сервера игр
//      * @returns Список игр для филиала
//      */
//     static async getGames(gameServerFilialId: number): Promise<AxiosResponse<Array<GameDto>>> {
//         return await $instanceApi.get<Array<GameDto>>(
//             `/user-games`,
//             {
//                 params: {
//                     id: gameServerFilialId
//                 }
//             }
//         );
//     }

//     /**
//      * Получение типов игр с сервера
//      * @returns Список игровых типов
//      */
//     static async getGameTypes(): Promise<AxiosResponse<Array<GameTypeDto>>> {
//         return await $instanceApi.get<Array<GameTypeDto>>(
//             '/game-types/all'
//         );
//     }
// }
