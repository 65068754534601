import { injectable } from "inversify";
import { ICollection } from "../../../@types/iCollection";
import { Room } from "../domain/entities/Room";
import { ALL_SELECT, BaseCollection } from "../../../@types/baseCollection";
import { computed, makeAutoObservable, observable } from "mobx";
import { container } from "../../../di/container";
import { handleRequest } from "../../../common/utility-types/helpers/handleRequest";
import { RoomService } from "../../../api";
import { filialLocalDataSource } from "./db";
import { fromDto } from "../adapters/gameFromDto";
import { BaseApiCollection } from "../../../@types/baseApiCollection";

@injectable()
export class RoomsDataSourse implements ICollection<Room>{

    private _baseCollection: BaseCollection<Room>;

    // private _baseCollection: BaseApiCollection<Room, Room> = new BaseApiCollection();
    
    constructor(
        baseCollection: BaseCollection<Room> = container.get(BaseCollection<Room>)
    ) {
        makeAutoObservable(this);
        this._baseCollection = baseCollection;
    }

    @computed
    get isLoaded() {
        return Array.from(this._baseCollection.storage.values()).length != 0;
    }

    load = async () => {

        if ((await filialLocalDataSource.getRooms()).length != 0) {
            this._baseCollection.setMany(await filialLocalDataSource.getRooms());

        } else {
            // await this._baseCollection.load(() => RoomService.getApiV3RoomsAll(), fromDto);

            (await handleRequest(async () => RoomService.getApiV3RoomsAll()))
            .match({
                onLeft: (l) => {
                },
                onRight: (r) => {
                    this._baseCollection.setMany(r.map(v => (
                        {
                            id: v.id ?? 0,
                            title: v.title ?? '',
                            guestMax: v.guestMax ?? 0,
                            description: v.description ?? ''
                        }
                    )));
                }
            });

            await filialLocalDataSource.addRooms(this._baseCollection.where(ALL_SELECT));
        }
    }

    getById(this: RoomsDataSourse, id: number): Room | null {
        return this._baseCollection.getById(id);
    }

    where(this: RoomsDataSourse, predicate: (v: Room) => boolean): Room[] {
        return this._baseCollection.where(predicate);
    }

    setMany(this: RoomsDataSourse, values: Room[]): void {
        this._baseCollection.setMany(values);
    }

    addMany(this: RoomsDataSourse, values: Room[]): void {
        this._baseCollection.addMany(values);
    }

    deleteMany(this: RoomsDataSourse, values: Pick<Room, "id">[]): void {
        this._baseCollection.deleteMany(values);
    }

}