import { action, computed, makeObservable, observable } from "mobx";
import { RequestStatus } from "../../../../@types/requestStatus";
import { IOrder } from "../../domain/entities/IOrder";
import { IOrderRepository } from "../../data/IOrderRepository";
import { container } from "../../../../di/container";
import { OrderRepository } from "../../data/OrderRepository";


export class OrderDetailViewModel {
    @observable
    private _order: IOrder | null = null;
    @observable
    private _getOrderStatus: RequestStatus = RequestStatus.NEVER;
    @observable
    private _error?: string;



    constructor(
        private _orderId: string,
        private _orderRepository: IOrderRepository = container.get(OrderRepository)
    ) {
        makeObservable(this);
    }

    @computed
    get isLoaded() {
        return this._getOrderStatus == RequestStatus.SUCCESSFULL; 
    }

    @computed
    get error() {
        return this._error;
    }

    @computed
    get order() {
        return this._order;
    }
 
    @action
    loadOrderDetail = async () => {
        this._getOrderStatus = RequestStatus.LOADING;
        (await this._orderRepository.getOrderById(this._orderId)).match({
            onLeft: (l) => {
                this._error = l.message;
                this._getOrderStatus = RequestStatus.ERROR;
            },
            onRight: (r) => {       
                this._order = r;
                this._getOrderStatus = RequestStatus.SUCCESSFULL;
            }
        });
    };

}